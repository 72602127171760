import { Checkbox, FormControlLabel, styled, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

const DefaultCheckbox = observer((props) => {
  return (
    <FormControlLabel
      label={<DefaultLabelTypo variant="label">{props.label}</DefaultLabelTypo>}
      control={<DefaultCheckboxStyled {...props} />}
      style={{ paddingTop: 24, margin: 0 }}
    />
  );
});

const DefaultCheckboxStyled = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  paddingRight: 12,
  ".MuiSvgIcon-root": {
    width: "30px",
    height: "30px",
    borderRadius: 6,
    path: {
      fill: "#99BCDF",
      border: "1px solid rgba(153, 188, 223, 0.25)",
    },
  },
}));

const DefaultLabelTypo = styled(Typography)(({ theme }) => ({
  display: "flex",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontSize: theme?.typography?.label?.fontSize || 14,
  color: theme?.typography?.label?.color || "#153862",
  fontWeight: theme?.typography?.label?.fontWeight || 400,
}));

export default DefaultCheckbox;
