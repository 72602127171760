import axios from 'axios'
import { Buffer } from 'buffer'
import ImageTool from 'Utils/ImageTool'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { ServerEnv } from 'Configs/ServerEnv'
import HttpHelper from 'Services/HttpHelper'
// GET METHOD
const getMenuWithLogin = (customerId, country3Alpha, customerToken, byPassCache = false) => {
    let baId = customerId
    const country_code3 = country3Alpha || StoreCountry.Country3()

    let queryStrings = [`ushopCountryCode=${country_code3.toLowerCase()}`, `baId=${baId}`]

    if (byPassCache) {
        queryStrings.push(`byPassCache=1`)
    }

    if (ServerEnv.ENV === 'DEV') {
        queryStrings.push(`isQa=1`)
    }

    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/etlV2/menu${queryStrings.length ? '?' + queryStrings.join('&') : ''}`

    return axios.get(HttpHelper.Proxy({ originalUrl: url }), {
        headers: {
            'Content-Type': 'application/json',
            'authorization-hydra': `Bearer ${customerToken || StoreAuth.GetToken()}`
        }
    })
}

// POST METHOD

const resetPassword = (data, { language, country }) => {
    const url = `${ServerEnv.Hydra()}/passwordresettokens`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json',
            'x-origin': 'https://fg.unicity.com',
            'Accept-Language': `${language}-${country}`,
            'X-APPLICATION': 'asia.ble.th'
        }
    })
}

const newPassword = data => {
    const url = `${ServerEnv.Hydra()}/customers/me/password`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data.payload, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${data.token}`,
            'X-APPLICATION': 'asia.ble.th'
        }
    })
}

const login = (username, password, timeout = 0) => {
    let data = {
        type: 'base64',
        value: Buffer.from(`${username}:${password}`).toString('base64'),
        namespace: `${ServerEnv.Hydra().replace('.com', '.net')}/customers`
    }

    const isDummy = localStorage.getItem('isDummy')
    let queryStrings = [`ushopCountryCode=${StoreCountry.Country3()}`, `platform=feelgreatreferral`, ...(isDummy ? [`isDummy=1`] : [])]

    if (timeout > 0) {
        queryStrings.push(`timeout=${timeout}`)
    }

    if (ServerEnv.ENV === 'DEV') {
        queryStrings.push(`isQa=1`)
    }

    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/etlV2/loginTokens?${queryStrings.join('&')}`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const changePassword = (customerToken, newPassword) => {
    let data = {
        value: newPassword
    }

    const url = `${ServerEnv.Hydra()}/customers/me/password`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${customerToken || StoreAuth.GetToken()}`,
            'X-APPLICATION': 'asia.ble.th'
            // 'authorization-hydra': `Bearer ${StoreAuth.GetToken()}`
        }
    })
}

const updateUserDetails = (customerToken, { emailAddress, mobilePhone }) => {
    let data = { email: emailAddress, mobilePhone: mobilePhone }

    const url = `${ServerEnv.Hydra()}/customers/me`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${customerToken || StoreAuth.GetToken()}`,
            'X-APPLICATION': 'asia.ble.th'
        }
    })
}

const updateUserProfilePicture = (customerToken, { baId, media }) => {
    try {
        const imageTools = new ImageTool()
        let imageCvt = imageTools.convertToImage(media, baId + '.jpg')

        let data = new FormData()
        data.append('media', imageCvt)

        var config = {
            method: 'post',
            url: HttpHelper.Proxy({ originalUrl: `${ServerEnv.Hydra()}/customers/me/profilePicture` }),
            headers: {
                authorization: `Bearer ${customerToken || StoreAuth.GetToken()}`,
                'Content-Type': 'multipart/form-data',
                'X-APPLICATION': 'asia.ble.th'
            },
            data: data
        }

        return axios(config)
    } catch (error) {
        console.log('updateUserProfilePicture', error)
    }
}

const removeUserProfilePicture = customerToken => {
    try {
        const config = {
            method: 'delete',
            url: HttpHelper.Proxy({ originalUrl: `${ServerEnv.Hydra()}/customers/me/profilePicture` }),
            headers: {
                authorization: `Bearer ${customerToken || StoreAuth.GetToken()}`
            }
        }

        return axios(config)
    } catch (error) {
        console.log('removeUserProfilePicture ', error)
    }
}

const requestOTP = (phone, country, systemName, expire) => {
    const date = new Date()
    const newDate = new Date(date.valueOf() + 60000 * (date.getTimezoneOffset() + 240))

    const dd = newDate.getDate().toString().padStart(2, '0')
    const mm = (newDate.getMonth() + 1).toString().padStart(2, '0')
    const yy = newDate.getFullYear()

    const randomPrefix = Math.floor(1000000000 + Math.random() * 9000000000)
    const randomSuffix = Math.floor(1000000000 + Math.random() * 9000000000)
    const randomNumberbeetweenDate = Math.floor(Math.random() * 10)

    const str = dd.toString() + mm.toString() + yy.toString()
    const process = [...str].join(randomNumberbeetweenDate)

    const secretKey = `${randomPrefix}${process}${randomSuffix}`

    const data = {
        secret_key: secretKey,
        phone: phone,
        country_code: country || StoreCountry.Country3(),
        system_name: systemName || 'ushop',
        expire: expire || '5'
    }

    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/otp/request/general`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const confirmOTP = (otp, ref_id) => {
    const data = {
        otp: otp,
        ref_id: ref_id
    }

    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/otp/confirm/general`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const requestDeleteAccount = ({ baId, countryCode3, hydraToken }) => {
    const data = {
        ba_id: baId,
        country_code: countryCode3
    }
    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': hydraToken
    }

    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/account-manager/delete-account`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: headers
    })
}

const getCustomerSite = customerToken => {
    const url = `${ServerEnv.Hydra()}/customers/me/customersite`

    return axios.get(HttpHelper.Proxy({ originalUrl: url }), {
        headers: {
            authorization: `Bearer ${customerToken || StoreAuth.GetToken()}`
        }
    })
}

const updateCustomerSite = (customerToken, data) => {
    const url = `${ServerEnv.Hydra()}/customers/me/customersite`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${customerToken || StoreAuth.GetToken()}`
        }
    })
}

const clearEtlV2Cache = (baId) => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/etlV2/cache/clearAll?baId=${baId}`

    return axios.delete(HttpHelper.Proxy({ originalUrl: url }))
}

const userAPI = { 
    login, 
    getMenuWithLogin, 
    changePassword, 
    updateUserDetails, 
    updateUserProfilePicture, 
    resetPassword, 
    newPassword, 
    requestOTP, 
    confirmOTP,
    requestDeleteAccount,
    removeUserProfilePicture,
    getCustomerSite,
    updateCustomerSite,
    clearEtlV2Cache
}

export default userAPI
