import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import StorePeriods from 'GlobalStores/StorePeriods'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { isSomething } from 'Utils/Utils'
import { useEffect } from 'react'

export function usePeriods() {
    const GetPeriods = async () => {
        try {
            const response = await FeelGreatAPI.GetPeriod(storeCountry.Country2())
            StorePeriods.periods = response.data
        } catch (e) {}
    }

    useEffect(() => {
        if (!isSomething(StorePeriods.periods)) {
            GetPeriods()
        }
    }, [])
}
