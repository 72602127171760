import { createTheme } from '@mui/material'

export const customTheme = createTheme({
    breakpoints: {
        values: {
            xs: 320, // phone
            sm: 830, // tablets
            md: 900 // small laptop
        }
    },
    palette: {
        primary: {
            main: '#1976d2'
        },
        secondary: {
            main: '#FFF'
        },
        button: {
            disabled: '#CDDDED'
        }
    },
    typography: {
        fontFamily: 'Poppins, NotoSansThai',
        fontStyle: 'normal',
        h3: {
            color: '#0B3B61',
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '24px',
            '@media (min-width:768px)': {
                fontSize: '32px'
            }
        },
        label: {
            color: '#94A2B4',
            fontSize: 12,
            fontWeight: 500
        },
        link: {
            cursor: 'pointer, NotoSansThai',
            textAlign: 'center',
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            color: '#153862',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
            textDecoration: 'underline'
        },
        caption: {
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '17px',
            color: '#003764'
        },
        action: {
            color: '#153862',
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '20px',
            textDecoration: 'underline',
            cursor: 'pointer, NotoSansThai'
        },
        desc: {
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal', 
            fontWeight: 300,
            fontSize: '18px',   
            textAlign: 'center',
            color: '#003B6F'
        }
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: '50px 30px',
                    borderRadius: '12px'
                }
            }
        }
    }
})
