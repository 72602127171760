import { ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { DefaultInput } from 'Components/Elements/Forms'
import { storeElement } from 'GlobalStores/StoreElement'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

const MobileInput = observer(props => {
    const [values, setValues] = useState({
        formattedValue: ''
    })

    const FormatMobile = inputValue => {
        if (inputValue && inputValue.length > 0) {
            inputValue = inputValue.match(/^(\d{0,3})(\d{0,3})(\d{0,4})/)
            inputValue = !inputValue[2] ? inputValue[1] : inputValue[1] + '-' + inputValue[2] + (inputValue[3] ? '-' + inputValue[3] : '')
        }

        return inputValue
    }

    useEffect(() => {
        setValues({
            ...values,
            formattedValue: FormatMobile(props.currentValue)
        })
    }, [props.currentValue])

    return (
        <ThemeProvider theme={storeElement.theme}>
            <DefaultInputStyled
                {...props}
                label={
                    <Typography variant="label" fontSize={'16px'}>
                        {props.label}
                    </Typography>
                }
                value={values.formattedValue}
                type="tel"
                onChange={event => {
                    let inputValue = FormatMobile(event.target.value.replace(/\D/g, '') || '')

                    setValues({
                        ...values,
                        formattedValue: inputValue
                    })

                    if (props.onChange) {
                        props.onChange(inputValue.replace(/-/g, ''))
                    }
                }}
            />
        </ThemeProvider>
    )
})

const DefaultInputStyled = styled(DefaultInput)`
    .MuiInputBase-input {
        border: 1px solid #ccdeef;
        font-size: 16px;
        font-family: 'Poppins', NotoSansThai !important;
        color: #000000;
    }

    &.MuiTypography-label {
        font-size: 16px;
        line-height: 18px;
        font-family: 'Poppins', NotoSansThai !important;
        font-weight: 400 !important;
    }
`

export default MobileInput