import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useCountries } from 'Services/Cores/Countries/useCountries'
import { Initialize } from 'Services/Cores/Initialize'
import StoreCountry from './GlobalStores/Cores/County/StoreCountry'
import AppRouter from './Routers/AppRouter'

import mobiscroll from '@mobiscroll/react'
import BypassRedirectPath from './Data/BypassRedirectPath.json'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import LoadingBar from 'Components/Elements/LoadingBar/LoadingBar'
import { isMaintenanceMode } from 'Helpers/GlobalHelpers'
import Maintenance from 'Components/Pages/Maintenance'
import TrackingEvents from 'Services/Tracker/TrackingEvents'
import ReactFbq from 'react-fbq'
import { get, lowerCase } from 'lodash'
import { Paths } from 'Routers/Paths'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { useState } from 'react'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

import storeReCaptcha from 'GlobalStores/StoreReCaptcha'
import { autorun, reaction } from 'mobx'
import { useMemo } from 'react'
import { PathHelper } from 'GlobalStores/Cores/Helpers/PathHelper'
import { storeElement } from 'GlobalStores/StoreElement'
import { DefaultReferralTheme } from 'Components/Pages/Referral/Theme/DefaultReferralTheme'
import feelGreateAPI from 'GlobalStores/Cores/County/APIs'
import SessionAuth from 'GlobalStores/User/SessionAuth'
import InAppSpy from 'inapp-spy'

mobiscroll.settings = {
    theme: 'ios',
    themeVariant: 'light'
}

const App = observer(() => {
    const { isInApp } = InAppSpy()
    const country = useCountries()

    const qs = useMemo(() => new URLSearchParams(window.location.search), [])

    const [autoLoginLoader, setAutoLoginLoader] = useState(false)

    isInApp && openInBrowser()

    function openInBrowser() {
        const url = window.location.href

        // Detect if the user is on Android
        const isAndroid = /Android/i.test(navigator.userAgent)

        // Get the current URL (to avoid infinite redirects)
        const currentUrl = window.location.href

        // Check if we are already on localhost or if it is the intent URL
        const isLocalhost = currentUrl.startsWith('http://localhost')
        const isIntentUrl = currentUrl.startsWith('intent://')

        // If not localhost or already on an intent URL, proceed with redirection
        if (isAndroid && !isLocalhost && !isIntentUrl) {
            // Use the intent URL scheme for Android
            const intentUrl = 'intent://' + url.replace(/^https?:\/\//, '') + '#Intent;scheme=https;end'
            window.location.href = intentUrl
        }
    }

    Initialize()
    const GetSiteVersion = useCallback(() => {
        if (country) {
            feelGreateAPI.getSiteVersion({ country: country.country.toLowerCase() }).then(res => {
                let currentVersion = localStorage.getItem('siteVersion')
                if (!currentVersion) {
                    localStorage.setItem('siteVersion', res.data.version)
                } else if (currentVersion !== res.data.version) {
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.reload()
                }
            })
        }
    }, [country])

    useEffect(() => {
        if (country) {
            GetSiteVersion()
            storeElement.theme = DefaultReferralTheme

            const currentLanguageStorage = localStorage.getItem('currentLanguage')

            let countryLowerCase = country.country.replace(/ /g, '').toLowerCase()

            if (currentLanguageStorage && currentLanguageStorage !== '' && hasLanguage(country, currentLanguageStorage)) {
                if (country.config.languageSettings.default !== currentLanguageStorage) {
                    localStorage.setItem('currentLanguage', country.config.languageSettings.default)
                    StoreTranslate.SetCurrentLanguage(country.config.languageSettings.default.replace('/ /g', ''))
                    storeReCaptcha.setCurrentLanguage(countryLowerCase, country.config.languageSettings.default.replace('/ /g', ''))
                } else {
                    StoreTranslate.SetCurrentLanguage(currentLanguageStorage)
                    storeReCaptcha.setCurrentLanguage(countryLowerCase, currentLanguageStorage)
                }
            } else {
                StoreTranslate.SetCurrentLanguage(country.config.languageSettings.default.replace('/ /g', ''))
                localStorage.setItem('currentLanguage', country.config.languageSettings.default.replace('/ /g', ''))
            }

            // set current country to local storage
            localStorage.setItem('currentCountry', countryLowerCase)
            checkToken()
            try {
                TrackingEvents.Commit(lowerCase(country.country))
                ReactFbq.initialize({
                    id: StoreCountry.FBPixelCode.all
                })
                ReactFbq.pageView()
            } catch (e) {
                console.error('FACEBOOK PIXEL ERROR', e)
            }
            if (PathHelper.Segments().length > 2 && isNaN(PathHelper.Segment(2)) && !isBypass()) {
                if (Paths.includes(PathHelper.Segment(2))) {
                    window.location.replace(PathHelper.BuildBasename(countryLowerCase))
                }
            } else if (window.location.pathname === '/' || PathHelper.Segment(1) !== countryLowerCase) {
                window.location.replace(PathHelper.BuildBasename(countryLowerCase))
            }
        }
    }, [country, GetSiteVersion])

    /*
     * for redirecting reset password page
     */
    useEffect(() => {
        const paths = window.location.pathname.split('/')
        if (paths.length > 1) {
            let newPath = ''
            if (/^\/feature/.test(window.location.pathname) || /^\/epic/.test(window.location.pathname)) {
                const paths = window.location.pathname.split('/')
                paths.map((path, index) => {
                    if (index >= 4) {
                        newPath += `/${path}`
                    }
                })
            }

            if (/^\/feature/.test(window.location.pathname) || /^\/epic/.test(window.location.pathname)) {
                const paths = window.location.pathname.split('/')
                if (paths[1] === 'feature' && !paths[4]) {
                    newPath = `/thailand`
                } else if (paths[1] === 'epic' && !paths[6]) {
                    newPath = `/thailand`
                } else {
                    if (paths[1] === 'feature') {
                        newPath = `/thailand/${paths[5] ? paths[5] : ''}`
                    } else if (paths[1] === 'epic') {
                        newPath = `/thailand/${paths[6] ? paths[6] : ''}`
                    } else {
                        if (paths[1] !== 'thailand') {
                            newPath = `/thailand${window.location.pathname.replace(`/${paths[1]}`, '')}`
                        } else {
                            newPath = window.location.pathname
                        }
                    }
                }
            } else {
                const paths = window.location.pathname.split('/')
                newPath = !/^\/thailand/.test(window.location.pathname) ? `/thailand${window.location.pathname.replace(`/${paths[1]}`, '')}` : window.location.pathname
            }

            window.location.href = `https://fg.unicity.com${newPath}${window.location.search}`
        } else {
            window.location.href = `https://fg.unicity.com/thailand`
        }

        autorun(diposer => {
            if (StoreCountry.countryList) {
                if (qs.get('market') && /new-password/.test(window.location.pathname) && !!country === false) {
                    const marketCountry = StoreCountry.CountryList().find(item => item.shorter.toLowerCase() === qs.get('market').toLowerCase())
                    const isNewPasswordPath = PathHelper.Segment(1) === 'new-password'
                    if (marketCountry && isNewPasswordPath) {
                        const pathnames = window.location.pathname.split('/')
                        let newUrl = ''
                        if (/^\/feature/.test(window.location.pathname)) {
                            newUrl = `${window.location.protocol}//${window.location.host}/${pathnames[1]}/${pathnames[2]}/${
                                pathnames[3]
                            }/${marketCountry.country.toLowerCase()}/new-password/${pathnames[5]}/${window.location.search}`
                        } else if (/^\/epic/.test(window.location.pathname)) {
                            newUrl = `${window.location.protocol}//${window.location.host}/${pathnames[1]}/${pathnames[2]}/${pathnames[3]}/${pathnames[4]}/${
                                pathnames[5]
                            }/${marketCountry.country.toLowerCase()}/new-password/${pathnames[7]}/${window.location.search}`
                        } else {
                            newUrl = `${window.location.protocol}//${window.location.host}/${marketCountry.country.toLowerCase()}/new-password/${
                                pathnames[2]
                            }/${window.location.search}`
                        }
                        diposer.dispose()
                        window.location.href = newUrl
                    }
                } else {
                    const isRedirect = checkFirstPathname()
                    const country = PathHelper.CountryFromPathname()
                    if (isRedirect !== undefined) {
                        if (isRedirect) {
                            console.log('redirect to thailand')
                            return
                        } else {
                            console.log('force redirect to thailand')

                            const removeShorterFromPath = window.location.pathname.replace(`/${country}`, '')
                            let newUrl = `${window.location.protocol}//${window.location.host}/thailand${removeShorterFromPath}${window.location.search}`
                            window.location.replace(newUrl)
                        }
                    } else {
                        if (country.length === 2 || country.length === 3) {
                            if (StoreCountry.countryList.length > 0) {
                                const countryList = StoreCountry.countryList
                                const index = countryList.findIndex(country => {
                                    const countryName = country.country.toLowerCase()
                                    return countryName === country.toLowerCase()
                                })
                                if (index === -1) {
                                    console.log('force redirect to thailand')
                                    const removeShorterFromPath = window.location.pathname.replace(`/${country}`, '')
                                    let newUrl = `${window.location.protocol}//${window.location.host}/thailand${removeShorterFromPath}${window.location.search}`
                                    window.location.replace(newUrl)
                                }
                            } else {
                                const removeShorterFromPath = window.location.pathname.replace(`/${country}`, '')
                                let newUrl = `${window.location.protocol}//${window.location.host}/thailand${removeShorterFromPath}${window.location.search}`
                                window.location.replace(newUrl)
                            }
                        }
                    }

                    diposer.dispose()
                }
            }
        })

        if (/\/c\//.test(window.location.pathname)) {
            window.location.replace(window.location.pathname.replace('/c', ''))
        }

        storeElement.theme = DefaultReferralTheme

        reaction(
            () => StoreCountry.country.country,
            (n, p, d) => {
                if (n) {
                    StoreTranslate.Instance().fetchOnce = true
                    d.dispose()
                }
            }
        )

        if (localStorage.getItem('autoLogin')) {
            GetAutoLogin(localStorage.getItem('autoLogin'))
        }
    }, [])

    useEffect(() => {
        if (qs.get('autoLogin')) {
            localStorage.setItem('autoLogin', qs.get('autoLogin'))
            window.location.href = `${window.location.pathname}`
        }
    }, [qs])

    const GetAutoLogin = async token => {
        try {
            setAutoLoginLoader(true)
            await storeAuth.AutoLogin(token)
            localStorage.removeItem('autoLogin')

            setAutoLoginLoader(false)
        } catch (e) {
            setAutoLoginLoader(false)
        }
    }

    const checkFirstPathname = () => {
        if (StoreCountry.CountryList().length > 0) {
            const countryList = StoreCountry.CountryList()

            const index = countryList.findIndex(country => {
                const countryName = country.country.toLowerCase()
                return PathHelper.Segment(1) === countryName || BypassRedirectPath.includes(PathHelper.Segment(1))
            })

            return index > -1 ? true : false
        }
    }

    const checkToken = async () => {
        if (SessionAuth.GetLoginExtend('token')) {
            try {
                const response = await SessionAuth.CheckToken(
                    SessionAuth.GetLoginExtend('token'),
                    SessionAuth.GetLoginExtend('country'),
                    SessionAuth.GetLoginExtend('id')
                )
                if (response.data.token) {
                    SessionAuth.SetLoginExtend('token', response.data.token)
                }
                StoreLoader.SetApiLoaded('refreshToken', true)
            } catch (e) {
                SessionAuth.RemoveSession(true)
                StoreLoader.SetApiLoaded('refreshToken', true)
            }
        } else {
            StoreLoader.SetApiLoaded('refreshToken', true)
        }
    }

    return (
        <div className="App">
            <LoadingBar />
            {/* {isMaintenanceMode() ? (
                <Maintenance />
            ) : (
                <>
                    {StoreLoader.IsWebReady() && autoLoginLoader === false ? (
                        <>
                            <LoadingBar />
                            <AppRouter />
                        </>
                    ) : (
                        <LoadingBar />
                    )}
                </>
            )} */}
        </div>
    )
})

const isBypass = () => {
    const bypassPath = BypassRedirectPath

    return bypassPath.some(path => window.location.pathname.includes(path))
}

const hasLanguage = (country, currentLanguageStorage = '') => {
    const countryLanguageList = get(country, 'config.language.list', [])
    return countryLanguageList.some(lang => (lang.code || '').toLowerCase() === currentLanguageStorage.toLowerCase())
}

export default App
