import React, { useEffect, useState } from 'react'
import { ReactComponent as SuccessIcon } from './Assets/success.svg'
import { observer } from 'mobx-react-lite'
import { Stack, Typography, Box, Link } from '@mui/material'
import { DefaultButton } from '../Forms'
import { useNavigate } from 'react-router-dom'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { runInAction } from 'mobx'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'

const CancelSuccess = observer(props => {
    const [loadingButton, setLoadingButton] = useState(false)

    const translate = useTranslate()
    const navigate = useNavigate()

    useEffect(() => {
        runInAction(() => {
            storeSettingsElement.settingCard.padding = '50px 40px 20px 40px'
            storeSettingsElement.cardTitle.titleStep1 = <T>subscriptions</T>
            storeSettingsElement.cardTitle.titleStep2 = <T>header_cancel_subscription</T>
            storeSettingsElement.cardTitle.titleStep3 = <T>canceled</T>
            storeSettingsElement.settingCard.titleAlign = 'center'
        })

        return () => {
            runInAction(() => {
                storeSettingsElement.settingCard.padding = ''
                storeSettingsElement.cardTitle.titleStep1 = ''
                storeSettingsElement.cardTitle.titleStep2 = ''
                storeSettingsElement.cardTitle.titleStep3 = ''
                storeSettingsElement.settingCard.titleAlign = ''
            })
        }
    }, [])

    // const handleFeelGreatHome = () => {
    //     setLoadingButton(true)
    //     setTimeout(() => {
    //         navigate('/')
    //         setLoadingButton(false)
    //     }, 1500)
    // }

    return (
        <>
            <Stack alignItems={'center'} justifyContent={'center'} textAlign="center" mt="150px">
                <SuccessIcon />

                <Box padding="0px 60px" mt="40px" mb="40px">
                    <Box mb="20px">
                        <Typography variant="title" fontSize="24px">
                            <T>subscription_success_title</T>
                        </Typography>
                    </Box>

                    <Typography variant="caption5" textAlign={'center'} color="#023764">
                        <T>subscription_success_centent</T>{' '}
                        <Box component="span" sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            <Link href={`mailto:${translate('feelgreat_email')}`} color="#023764">
                                <T>feelgreat_email</T>.
                            </Link>
                        </Box>
                    </Typography>
                </Box>

                {/* <DefaultButton
                    type="submit"
                    fullWidth={true}
                    titleText={translate('feel_great_home')}
                    width="344px"
                    onClick={() => handleFeelGreatHome()}
                    isloading={loadingButton.toString()}
                    fontSize="20px"
                    fontWeight="400"
                /> */}
            </Stack>
        </>
    )
})

export default CancelSuccess
