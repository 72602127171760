import mobiscroll from '@mobiscroll/react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import '@mobiscroll/react/dist/css/mobiscroll.react.scss'
import './ProvinceSelect.css'
import { ThemeProvider } from '@emotion/react'
import { reaction } from 'mobx'
import { ReactComponent as DropdownIcon } from '../Assets/dropdown.svg'
import { storeElement } from 'GlobalStores/StoreElement'
import { Stack } from '@mui/material'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { lowerCase } from 'lodash'
import storeReferral from '../../../Stores/StoreReferral'
import storeReferralCheckout from '../../../Stores/StoreReferralCheckout'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'

const ProvinceSelect = observer(props => {
    const [provinceLoading, setProvinceLoading] = useState(false)

    const localObserver = useLocalObservable(() => ({
        listForRender: [],
        value: ''
    }))

    const renderProvinces = async () => {
        try {
            localObserver.listForRender = []
            setProvinceLoading(true)
            let list = []
            const keyEnglish = 'city_roman'
            const keyNative = 'city_native'

            storeReferral.provinces.map(province => {
                const englishTitle = province[keyEnglish] ? province[keyEnglish].trim() : ''
                const nativeTitle = province[keyNative] ? province[keyNative].trim() : ''
                const title = StoreTranslate.CurrentLanguage() === 'EN' ? englishTitle : nativeTitle

                let multiLangTitle = StoreTranslate.CurrentLanguage() === 'EN' ? `${englishTitle} (${nativeTitle})` : `${nativeTitle} (${englishTitle})`

                list.push({
                    value: title,
                    text: multiLangTitle ? multiLangTitle : title,
                    html:
                        StoreTranslate.CurrentLanguage() === 'EN'
                            ? `<span>${englishTitle}</span><span style="color:#9DA9B9;padding-left:6px;">(${nativeTitle})</span>`
                            : `<span>${nativeTitle}</span><span style="color:#9DA9B9;padding-left:6px;">(${englishTitle})</span>`
                })

                return null
            })

            const bangkokIndex = list.findIndex(l => l.value === 'Bangkok' || l.value === 'กรุงเทพมหานคร')

            // Move Bangkok to the top if found
            if (bangkokIndex !== -1) {
                const bangkokLocation = list.splice(bangkokIndex, 1)[0]
                list.unshift(bangkokLocation)
            }
            localObserver.listForRender = list
            setProvinceLoading(false)
            setPropValueToState(localObserver.value)
        } catch (err) {
            setProvinceLoading(false)
            console.log(err)
        }
    }

    const setPropValueToState = propValue => {
        if (propValue) {
            let val = lowerCase(propValue)
            localObserver.listForRender.map(l => {
                if (lowerCase(l.text).includes(val)) {
                    localObserver.value = l.value
                }
                return l
            })
        }
    }

    const GetProvinces = useCallback(async () => {
        try {
            const data = await FeelGreatAPI.GetCityList()
            storeReferral.provinces = data.data.data
        } catch (error) {}
    }, [])

    useEffect(() => {
        const disposer = reaction(
            () => StoreTranslate.CurrentLanguage(),
            (n, p) => {
                if (n !== p) {
                    renderProvinces()
                }
            }
        )

        reaction(
            () => storeReferral.provinces,
            (n, p, d) => {
                if (n !== p) {
                    renderProvinces()
                    d.dispose()
                }
            }
        )

        if (storeReferral.provinces.length === 0) {
            GetProvinces()
        }
        return () => {
            disposer()
        }
    }, [])

    useEffect(() => {
        if (props.value) {
            if (localObserver.listForRender.length > 0) {
                setPropValueToState(props.value)
            } else if (localObserver.listForRender.length === 0) {
                renderProvinces()
                setPropValueToState(props.value)
            }
        } else {
            if (localObserver.listForRender.length === 0) {
                renderProvinces()
            }
        }
    }, [props.value, localObserver.listForRender.length])

    const onSelected = (event, inst, key) => {
        switch (key) {
            case 'tax_invoice_province':
                storeReferralCheckout.SetInvoiceValue('province', inst.getVal())
                break
            default:
                storeCheckout.SetCheckoutFormValue('province', inst.getVal())
                break
        }
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Stack className="mobi-custom-input">
                <label style={{ fontWeight: 300 }}>{props.label}</label>
                <mobiscroll.Select
                    className={props.provinceKey || 'province'}
                    key={props.provinceKey || 'province'}
                    display="center"
                    touchUi={false}
                    filter={true}
                    height={40}
                    maxWidth={400}
                    data={localObserver.listForRender}
                    onSet={(event, inst) => onSelected(event, inst, props.provinceKey)}
                    value={localObserver.value}
                    filterPlaceholderText=""
                    disabled={provinceLoading}
                    group={{
                        groupWheel: false,
                        header: false
                    }}>
                    <mobiscroll.Input>
                        <Stack position={'absolute'} right={'15px'} top={'45%'}>
                            <DropdownIcon style={{ cursor: 'pointer' }} />
                        </Stack>
                    </mobiscroll.Input>
                </mobiscroll.Select>
            </Stack>
        </ThemeProvider>
    )
})

export default ProvinceSelect
