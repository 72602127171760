import * as K from "Configs/Keywords";
import {
  makeAutoObservable,
  get as mget,
  set as mset,
  has as mhas,
  isObservable,
  reaction,
} from "mobx";
import { excludeOf, isSomething, isString } from "Utils/Utils";
import { get, set, has, findKey } from "lodash";
import { devTools } from "../MainStore";
// import { isProduction, throatArray } from 'Configs/ConfigsHeader';
import { findString, lowerCase } from "Helpers/StringHelpers";
import { startsWith } from "lodash";

import StoreCountry from "GlobalStores/Cores/County/StoreCountry";

function isProduction() {
  return true;
}
// import { StorageExtend } from '@Utils/StorageManager';

class StoreLanguage {
  // properties
  isInitialized = false;
  primary = null;
  current = null;
  native = null;
  previous = null;
  footer = "english"; // english or native
  footerAPI = "roman"; // roman or native
  dictionary = {};
  dictionaries = {};
  notFound = {};

  currentSwitchAttempt = 0;
  currentRequestAttempt = 0;
  maxRequestAttempt = 5;

  Declaration = {
    [K.English]: {
      code: "EN",
      name: {
        english: "English",
        native: "English",
      },
    },
    [K.Thai]: {
      code: "TH",
      name: {
        english: "Thai",
        native: "ภาษาไทย",
      },
    },
    [K.Japanese]: {
      code: "JP",
      name: {
        english: "Japanese",
        native: "日本語",
      },
    },
    [K.Bahasa]: {
      code: "ID",
      name: {
        english: "Bahasa",
        native: "Bahasa",
      },
    },
    [K.Vietnamese]: {
      code: "VN",
      name: {
        english: "Vietnamese",
        native: "Tiếng Việt",
      },
    },
    // Hong Kong
    [K.Cantonese]: {
      code: "HK",
      name: {
        english: "Chinese",
        native: "Chinese",
      },
    },
    // Taiwan
    [K.Mandarin]: {
      code: "TW",
      name: {
        english: "Chinese",
        native: "Chinese",
      },
    },
    // Simplified Chinese
    [K.Chinese]: {
      code: "ZH",
      name: {
        english: "Chinese",
        native: "Chinese",
      },
    },
    // NOTE: Admin support Malay, but we currently use EN on Malaysia.
    [K.Malay]: {
      code: "MY",
      name: {
        english: "Malay",
        native: "Malay",
      },
    },
    // non-live languages
    [K.Burmese]: {
      code: "MM",
      name: {
        english: "Burmese",
        native: "မြန်မာဘာသာ",
      },
    },
    [K.Khmer]: {
      code: "KH",
      name: {
        english: "Khmer",
        native: "ភាសាខ្មែរ",
      },
    },
    [K.Lao]: {
      code: "LA",
      name: {
        english: "Lao",
        native: "ພາສາລາວ",
      },
    },
    [K.Korean]: {
      code: "KR",
      name: {
        english: "Korea",
        native: "Korea",
      },
    },
    [K.French]: {
      code: "FR",
      name: {
        english: "French",
        native: "Français",
      },
    },
    [K.Arabic]: {
      code: "AR",
      name: {
        english: "Arabic",
        native: "Arabic",
      },
    },
  };

  Usage = {
    [K.Singapore]: [this.getCode(K.English)],
    [K.Thailand]: [this.getCode(K.Thai), this.getCode(K.English)],
    [K.Japan]: [this.getCode(K.Japanese), this.getCode(K.English)],
    [K.Philippines]: [this.getCode(K.English)],
    [K.Vietnam]: [this.getCode(K.Vietnamese), this.getCode(K.English)],
    [K.Indonesia]: [this.getCode(K.Bahasa), this.getCode(K.English)],
    [K.Hongkong]: [this.getCode(K.Cantonese), this.getCode(K.English)],
    [K.Taiwan]: [this.getCode(K.Mandarin), this.getCode(K.English)],
    [K.Malaysia]: [this.getCode(K.English)],
    [K.Myanmar]: [this.getCode(K.English)],
    [K.Cambodia]: [this.getCode(K.Khmer), this.getCode(K.English)],
    [K.Laos]: [this.getCode(K.Lao), this.getCode(K.English)],
    [K.Australia]: [this.getCode(K.English)],
    [K.Newzealand]: [this.getCode(K.English)],
    [K.Morocco]: [this.getCode(K.English), this.getCode(K.French)],
    [K.SaudiArabia]: [this.getCode(K.English)],
  };

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.dictionary,
      (dict, _, rDict) => {
        if (isObservable(dict) && isSomething(dict)) {
          this.isInitialized = true;
          rDict.dispose();
        }
      },
      { fireImmediately: true }
    );
  }

  now() {
    return this.current;
  }

  getCode(languageName = "") {
    if (has(this.Declaration, lowerCase(languageName))) {
      return get(this.Declaration, `${languageName}.code`);
    } else {
      return undefined;
    }
  }

  getName(autoLocalized = true) {
    const key = findKey(this.Declaration, { code: this.current });
    if (has(this.Declaration, lowerCase(key))) {
      if (autoLocalized) {
        return this.listenObject(get(this.Declaration, `${key}.name`, {}));
      } else {
        return get(this.Declaration, `${key}.name.english`, undefined);
      }
    } else {
      return undefined;
    }
  }

  // getUsageList() {
  //     if (has(this.Usage, StoreCountry.path)) {
  //         return throatArray(this.Usage)
  //     } else {
  //         return []
  //     }
  // }

  // getMemoizedLanguage() {
  //     return StorageExtend.getFromCountry('language', '')
  // }

  // setMemoizedLanguage(languageCode) {
  //     StorageExtend.setToCountry(languageCode, 'language')
  // }

  /** Similarly with regular `listen` method but `listenObject` always focus on object which contained schema of language keys.
   * - `english` and `native`
   * - `roman` and `native`
   * - `EN` combined with other 2-chars language code (`XX`)
   * @param {*} object
   * @param {string} prefix
   * @return {string}
   */
  listenObject(object, prefix = "") {
    if (has(object, `${prefix}english`) && has(object, `${prefix}native`)) {
      // english/native object?
      const footer = this.isEnglish() ? "english" : "native";
      return get(object, `${prefix}${footer}`);
    } else if (
      has(object, `${prefix}roman`) &&
      has(object, `${prefix}native`)
    ) {
      // roman/native object?
      const footerAPI = this.isEnglish() ? "roman" : "native";
      return get(object, `${prefix}${footerAPI}`);
    } else if (has(object, `${prefix}EN`) || has(object, `${prefix}en`)) {
      // EN/?? object?
      return get(
        object,
        `${prefix}${this.current}`,
        get(object, `${prefix}${lowerCase(this.current)}`)
      );
    } else {
      // return when no idea what it is...
      if (isString(object)) {
        return object;
      } else {
        return "";
      }
    }
  }

  /** Subscribe automatic translate from dictionary with specified logic.
   * If dictionary key does not existed, development error logs may display.
   * Support an object with schema of language keys.
   * - `english` and `native`
   * - `roman` and `native`
   * - `EN` combined with other 2-chars language code (`XX`)
   * @param {string|object} key could be **String** dictionary key or compatible **Object** (with language keys support).
   * @param {object} options additional options for translation result.
   * @param {boolean} options.autoPrefix prepend **u_all_** to current `key` if it not already existed. Default setting is `true`.
   * @param {boolean} options.autoLocalized always check if the alternative key for current language is available. For example,
   * **u_all_username** was a common version for the result. But if there **u_jp_username** existed in dictionary.
   * The result will decided to get value from **u_jp_username** instead. Default setting is `true`.
   * @param {boolean} options.keyOnMissing display `key` instead of fallback or empty string. Default setting is `true`.
   * @param {boolean} options.keyWithBrackets when `key` is missing, show brackets along it. Default setting is `true`.
   * @return {string}
   * @example
   * language.listen('username')
   * /// output => Username | fail => [u_all_username]
   *
   * language.listen('u_all_profile_title')
   * /// output => Profile Title | fail => [u_all_profile_title]
   *
   * language.listen('Sorry, your payment has failed.', { keyOnMissing: true, keyWithBrackets: false })
   * /// output => Sorry, your payment has failed.
   *
   * const greeting = { english: 'Hello!', native: 'こんにちは!' }
   * language.listen(greeting) /// output => Hello! (or) こんにちは! (depend on current language).
   *
   * const farewell = { roman: 'Bye Bye', native: 'លា​សិន​ហើយ' }
   * language.listen(farewell) /// output => Bye Bye (or) លា​សិន​ហើយ (depend on current language).
   *
   * const localizedIcon = {
   *      EN: 'https://abc.dfg.com/english_banner.png',
   *      TH: 'https://abc.dfg.com/thailand_banner_v2.png'
   * }
   * language.listen(localizedIcon) /// output => url depend on current language.
   */
  listen(
    key,
    options = {
      keyOnMissing: !isProduction(),
      keyWithBrackets: true,
      autoLocalized: true,
      autoPrefix: true,
      fallbackToEnglish: isProduction(),
    }
  ) {
    // optional options key
    const legitOptions = {
      keyOnMissing:
        options.keyOnMissing === undefined
          ? !isProduction()
          : options.keyOnMissing,
      keyWithBrackets:
        options.keyWithBrackets === undefined ? true : options.keyWithBrackets,
      autoLocalized:
        options.autoLocalized === undefined ? true : options.autoLocalized,
      autoPrefix: options.autoPrefix === undefined ? true : options.autoPrefix,
      fallbackToEnglish:
        options.fallbackToEnglish === undefined
          ? isProduction()
          : options.fallbackToEnglish,
    };

    const {
      keyOnMissing,
      keyWithBrackets,
      autoLocalized,
      autoPrefix,
      fallbackToEnglish,
    } = legitOptions;

    // only start after language has been initialized
    if (this.isInitialized) {
      if (isString(key)) {
        // auto-prefix
        if (autoPrefix) {
          if (startsWith(key, `u_${StoreCountry.Country2()}_`) === false) {
            if (startsWith(key, "u_all_") === false) {
              key = `u_all_${key}`;
              if (mhas(this.dictionary, key) === false) {
                const localKey = key.replace(
                  `u_all_`,
                  `u_${StoreCountry.Country2()}_`
                );
                if (mhas(this.dictionary, localKey)) {
                  key = localKey;
                }
              }
            }
          }
        }
        // missing word control
        const showLabelWhenMissing = keyOnMissing;
        const showBracketAlongLabel = keyWithBrackets;
        // show missing word from English instead of its label
        const isShowMissingWordInEnglish = fallbackToEnglish;
        let fallbackResult = undefined;
        if (isShowMissingWordInEnglish && this.isNative()) {
          // prepared english value if it exists
          if (mhas(this.dictionaries, "EN")) {
            fallbackResult = mget(this.dictionaries.EN, key);
          }
        }

        // display brackets for missing keys
        const bracketLeft = "[",
          bracketRight = "]";
        const tagBracket = (_string, brl, lb, brr) =>
          `${showBracketAlongLabel ? brl : ""}${lb}${
            showBracketAlongLabel ? brr : ""
          }`;
        const missingKey = tagBracket`${bracketLeft}${key}${bracketRight}`;

        if (mhas(this.dictionary, key)) {
          // * Word exists case
          if (devTools.isShowDictionaryLabel) {
            return missingKey;
          } else {
            // auto-localization key (`u_all_key` and `u_xx_key`)
            if (autoLocalized) {
              if (findString(key, "u_all_")) {
                const localKey = key.replace(
                  "u_all_",
                  `u_${StoreCountry.Country2()}_`
                );
                if (mhas(this.dictionary, localKey)) {
                  // return localized result
                  return mget(this.dictionary, localKey);
                }
              }
            }
            // return result
            const result = mget(this.dictionary, key);
            if (excludeOf(result, [null, undefined])) {
              return result;
            } else {
              // missing key alrady added in missing list?
              if (mhas(this.notFound, key) === false) {
                // loge(`Key [${key}] was not translate for (${this.current}).`)
                mset(this.notFound, key, true);
              }
              // show english instead?
              if (
                showLabelWhenMissing === false &&
                isShowMissingWordInEnglish &&
                fallbackResult
              ) {
                return fallbackResult;
              }
              return showLabelWhenMissing ? missingKey : "";
            }
          }
        } else {
          // * Missing word case
          // missing key alrady added in missing list?
          if (mhas(this.notFound, key) === false) {
            // loge(`Key [${key}] was not exists in dictionary.`)
            mset(this.notFound, key, true);
          }
          // show english instead?
          if (
            showLabelWhenMissing === false &&
            isShowMissingWordInEnglish &&
            fallbackResult
          ) {
            return fallbackResult;
          }
          // this key does not exists in dictionary
          return showLabelWhenMissing ? missingKey : "";
        }
      } else {
        // ** Compatibled Objects
        return this.listenObject(key);
      }
    } else {
      return "";
    }
  }

  isEnglish() {
    return this.current === "EN";
  }

  isNative() {
    return this.current !== "EN";
  }

  EoN(resultEN, resultNT) {
    return this.isEnglish() ? resultEN : resultNT;
  }

  NoE(resultNT, resultEN) {
    return this.isEnglish() ? resultNT : resultEN;
  }

  langList() {
    return Object.keys(this.dictionaries);
  }

  /** Check if `key` is a direct or inherited as **key** of `dictionary` object.
   * Also support these API object with specified language keys as below.
   * - `english` and `native`
   * - `roman` and `native`
   * - `EN` combined with other 2-chars language code (`XX`)
   * @param {string|object} key could be **String** dictionary key or compatible **Object** (with language keys support).
   * @param {object} options additional options for translation result.
   * @param {boolean} options.autoPrefix prepend **u_all_** to current `key` if it not already existed. Default setting is `true`.
   * @param {boolean} options.autoLocalized always check if the alternative key for current language is available. For example,
   * **u_all_username** was a common version for the result. But if there **u_jp_username** existed in dictionary.
   * The result will decided to get value from **u_jp_username** instead. Default setting is `true`.
   * @return {boolean}}
   */
  hasKey(
    key,
    options = {
      autoLocalized: true,
      autoPrefix: true,
    }
  ) {
    set(options, "keyOnMissing", true);
    set(options, "keyWithBrackets", false);
    set(options, "fallbackToEnglish", false);
    return isSomething(this.listen(key, options));
  }

  /** Check if `key` is a direct or inherited **value** of `dictionary` object.
   * Also support these API object with specified language keys as below.
   * - `english` and `native`
   * - `roman` and `native`
   * - `EN` combined with other 2-chars language code (`XX`)
   * @param {string|object} key could be **String** dictionary key or compatible **Object** (with language keys support).
   * @param {object} options additional options for translation result.
   * @param {boolean} options.autoPrefix prepend **u_all_** to current `key` if it not already existed. Default setting is `true`.
   * @param {boolean} options.autoLocalized always check if the alternative key for current language is available. For example,
   * **u_all_username** was a common version for the result. But if there **u_jp_username** existed in dictionary.
   * The result will decided to get value from **u_jp_username** instead. Default setting is `true`.
   * @return {boolean}}
   */
  hasValue(
    key,
    options = {
      autoLocalized: true,
      autoPrefix: true,
    }
  ) {
    set(options, "keyOnMissing", false);
    set(options, "keyWithBrackets", false);
    set(options, "fallbackToEnglish", false);
    return isSomething(this.listen(key, options));
  }

  memoFooter() {
    this.footer = this.current === "EN" ? K.English : K.Native;
    this.footerAPI = this.current === "EN" ? K.Roman : K.Native;
  }

  useLang(langs) {
    return this.EoN(langs.english, this.NoE(langs.native, langs.english));
  }

  reset() {
    this.primary = null;
    this.current = null;
    this.native = null;
    this.previous = null;
    this.footer = K.English;
    this.footerAPI = K.Roman;
    this.dictionary = {};
    this.dictionaries = {};
    this.notFound = {};
  }
}

export const language = new StoreLanguage();
