import StoreAuth from 'GlobalStores/User/StoreAuth'
import { observer } from 'mobx-react-lite'
import LoginElem from '../Elements/Login/Login'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import MobileLayoutWrapper from 'Components/Elements/UserAccountMenu/MobileLayoutWrapper'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { reaction } from 'mobx'

const Login = observer(({ nosignup = false }) => {
    const [canSignup, setCanSignup] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        if (['JPN'].includes(storeCountry.Country3())) {
            setCanSignup(false)
        }

        if (StoreAuth.IsAuthorized()) {
            navigate('/')
        }

        reaction(
            () => StoreAuth.IsAuthorized(),
            (n, p, disposer) => {
                if (p === false && n === true) {
                    navigate('/')
                    disposer.dispose()
                }
            }
        )
    }, [])

    useEffect(() => {
        if (nosignup) {
            setCanSignup(false)
        }
    }, [nosignup])

    return (
        <MobileLayoutWrapper>
            <LoginElem canSignup={canSignup} />
        </MobileLayoutWrapper>
    )
})

export default Login
