import { makeAutoObservable, runInAction } from 'mobx'
import get from 'lodash/get'
import feelGreateAPI from 'GlobalStores/FeelGreat/APIs'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import Customer from 'GlobalStores/User/Customer'

class StoreReferral {
    referralData = null
    referralCode = ''
    loadingReferCode = false
    currentReferralData = null
    isOpenLoginModal = false
    instance = null
    referralCheckError = ''
    checkedReferralParam = false
    referralHistory = null
    useReferral = false
    isAcceptPdpa = false
    updatePdpaLoading = true

    provinces = []

    subscriptionConfigData = null

    constructor() {
        makeAutoObservable(this)
    }
    /**
     * @returns {Customer} Customer
     */
    Customer() {
        if (!this.instance) {
            this.instance = new Customer(this.referralData)
        }
        return this.instance
    }

    SetReferralData(userRef, status) {
        runInAction(() => {
            this.referralData = {
                ...this.referralData,
                ...userRef,
                shortStatus: status
            }
            this.referralHistory = {
                ...this.referralData,
                ...userRef,
                shortStatus: status
            }
            sessionStorage.setItem('referralHistory', JSON.stringify(this.referralHistory))
        })
    }

    async GetReferralCode(token) {
        try {
            this.loadingReferCode = true
            let response = await feelGreateAPI.getReferralCodes(token)
            this.referralCode = get(response, 'data.items[0].referralCode', '')
            this.loadingReferCode = false
        } catch (error) {
            console.log('getReferralCode error => ', error)
            this.loadingReferCode = false
        }
    }

    UsePrice() {
        if (StoreAuth.GetId()) {
            return 'after'
        } else if (get(this.referralData, 'id.unicity', '').length > 0) {
            return 'after'
        } else {
            return 'retail'
        }
    }

    GetReferral() {
        return this.referralData
    }

    HasReferral() {
        const id = get(this.referralData, 'baId', '')

        return id && !ObjectHelpers.isEmpty(this.referralData.displayName) ? id : false
    }

    HasReferralHistory() {
        const id = get(this.referralHistory, 'baId', '')

        return id && !ObjectHelpers.isEmpty(this.referralHistory.displayName) ? id : false
    }

    SetReferralCheckError(msg) {
        this.referralCheckError = msg
    }

    GetReferralCheckError() {
        return this.referralCheckError
    }

    reset() {
        this.referralData = null
        this.instance = null
        this.referralCheckError = ''
        this.checkedReferralParam = false
        this.useReferral = false
    }

    DisplayName() {
        return get(this.referralData, 'displayName', null)
    }

    SetCheckedReferralParam(value) {
        this.checkedReferralParam = value
    }

    GetCheckedReferralParam() {
        return this.checkedReferralParam
    }

    SetReferralHistory(value) {
        this.referralHistory = value
    }

    GetReferralHistory() {
        return this.referralHistory
    }

    GetIsAcceptPdpa() {
        return this.isAcceptPdpa
    }

    SetIsAcceptPdpa(value) {
        this.isAcceptPdpa = value
    }

    SetUpdatePdpaLoading(value) {
        this.updatePdpaLoading = value
    }

    UpdatePdpaLoading() {
        return this.updatePdpaLoading
    }

    SetSubscriptionConfigData(subscriptionConfigData) {
        if (subscriptionConfigData) {
            this.subscriptionConfigData = subscriptionConfigData
        }
    }

    GetSubscriptionConfigData(key) {
        if (key) {
            return get(this.subscriptionConfigData, key)
        } else {
            return this.subscriptionConfigData
        }
    }
}
const storeReferral = new StoreReferral()
export default storeReferral
