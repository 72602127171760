import React, { useState } from 'react'
import { Stack, Typography, Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
// import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import { DefaultButton } from 'Components/Elements/Forms'
import ShowError from './ShowError'
import { getOSName } from 'Helpers/Bowser'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const SkipNextDelivery = observer(props => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])

    const order = props.products

    const translate = useTranslate()

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const productItems = order.lines.items.map(data => {
                return {
                    item_code: data.item.id.unicity,
                    qty: data.quantity,
                    price: data.terms.priceEach
                }
            })

            const newData = {
                autoship_href: order.href,
                autoship_number: order.id.unicity.toString(),
                ba_id: order.customer.id.unicity.toString(),
                market: order.market,
                items: JSON.stringify(productItems),
                dateStarts: order.recurrence.dateStarts,
                shipToAddress: order.shipToAddress,
                fullname: order.shipToName.fullName,
                shipToPhone: order.shipToPhone,
                shipToEmail: order.shipToEmail,
                shippingMethod_href: order.shippingMethod.href,
                transactions: order.transactions,
                medium: 'Internet',
                agent: 'uFeelGreat',
                platform: getOSName()
            }
            let res = await FeelGreatAPI.SkipDelivery(newData, StoreAuth.GetToken())

            if (res.data.success) {
                props.handleClose()
                props.handleGetOrder()
                setLoading(false)
            }
        } catch (error) {
            console.error(error)

            setErrorMessage([error.response.data.data.error.message])
        } finally {
            setLoading(false)
        }
    }

    return (
        <Stack gap="30px">
            <Typography variant="title2">
                <T>skip_next_delivery</T>
            </Typography>
            {console.log('props.skipDate', props.skipDate)}
            <Stack>
                <Typography variant="caption4" color="#003B6F">
                    {translate('skip_delivery_content').replace('{{date}}', props.skipDate)}
                </Typography>
            </Stack>

            {errorMessage.length > 0 && <ShowError list={errorMessage} noTranslate={true} />}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                <Box onClick={() => props.handleClose()}>
                    <Typography variant="link" color="#000000" fontSize="16px">
                        <T>cancel</T>
                    </Typography>
                </Box>
                <DefaultButton
                    type="submit"
                    fullWidth={true}
                    titleText={translate('save')}
                    width="220px"
                    onClick={() => handleSubmit()}
                    isloading={loading.toString()}
                    fontSize="20px"
                    fontWeight="400"
                    disabled={loading}
                />
            </Box>
        </Stack>
    )
})

export default SkipNextDelivery
