import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, ThemeProvider, Typography, createTheme, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import DefaultInput from '../DefaultInput'
import { useEffect } from 'react'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import axios from 'axios'
import httpHelper from 'Services/HttpHelper'
import Loader from '../Assets/LoaderForModal.svg'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { ReactComponent as Exclamation } from './../Assets/exclamation.svg'

let DialogWrapper = Dialog

const ModalSearch = observer(props => {
    const [open, setOpen] = useState(false)
    const [address, setAddress] = useState([])
    const [focusAddress, setFocusAddress] = useState(null)
    const [loading, setLoading] = useState(null)
    const [valueNotFound, setValueNotFound] = useState(null)

    const [themeStyle, setThemeStyle] = useState(ModalSearchTheme)
    const translate = useTranslate()

    const onOkThenSearch = () => {
        GetAddressFromZipcode(props.value)
        setValueNotFound(null)
        setOpen(true)
    }

    useEffect(() => {
        // const country = storeCountry.CountryLowerCase()
        DialogWrapper = DefaultDialogWrapper
    }, [])

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden !important'
            document.getElementById('root').style.touchAction = 'none'
            document.getElementsByTagName('body')[0].style.touchAction = 'none'
        } else {
            document.body.style.overflow = ''
            document.getElementById('root').style.touchAction = ''
            document.getElementsByTagName('body')[0].style.touchAction = ''
        }
    }, [open])

    useEffect(() => {
        return () => {
            if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
                let body = document.getElementById('root')
                if (body) {
                    body.style.position = 'relative'
                }
            }
        }
    }, [])

    const GetAddressFromZipcode = async values => {
        if (values.length > 0) {
            setLoading(true)
            setTimeout(() => {}, 1000)
            const country3 = storeCountry.Country3()
            const url = `https://member-calls2.unicity.com/unishop-fn-misc/city/search?country_code=${country3}&keyword=${values}`
            try {
                const { data, status } = await axios.get(httpHelper.Proxy({ originalUrl: url }))
                if (status === 200) {
                    if (data.data.length === 0) {
                        setValueNotFound(values)
                    }
                    setAddress(data.data)
                } else {
                    setAddress([])
                }
            } catch (e) {
                if (e.response.data.errors) {
                    // inputCallback(values)
                }
            } finally {
                setLoading(false)
            }
        }
    }

    const handleSelectAddress = () => {
        props.onSet(focusAddress)
        setOpen(false)
        // console.log('Now we should use this as ADDR => ', focusAddress)
    }

    return (
        <ThemeProvider theme={ModalSearchTheme}>
            <Stack id="dialog-modal">
                <CustomInputWithEndorment
                    type="zipSearch"
                    value={props.value}
                    label={props.label}
                    endAdornmentToggle={status => {
                        setAddress([])
                        // setOpen(status)
                        onOkThenSearch()
                        setValueNotFound(null)
                    }}
                    onChange={props.onChange}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            onOkThenSearch()
                        }
                    }}
                />
                <DialogWrapper disablePortal open={open} onClose={() => setOpen(false)} scroll="paper">
                    <DialogTitle>
                        <Typography sx={{ color: 'rgba(51, 98, 140, 1)' }}>
                            <T>search_address</T>
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        {address.length ? (
                            address.map((addr, addrIndex) => {
                                return (
                                    <ZipDisplay key={`zipSearch` + addrIndex} onClick={() => setFocusAddress(addr)} focused={addr.id === focusAddress?.id}>
                                        <Typography variant="h5">{addr.zip}</Typography>
                                        <Typography variant="h5">
                                            {addr[`search_address_${StoreTranslate.CurrentLanguage() === 'TH' ? 'native' : 'roman'}`]}
                                        </Typography>
                                    </ZipDisplay>
                                )
                            })
                        ) : (
                            <DefaultInput
                                type="tel"
                                label={null}
                                value={props.value}
                                onChange={e => {
                                    props.onChange(e)
                                }}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        onOkThenSearch()
                                    }
                                }}
                            />
                        )}
                    </DialogContent>
                    {valueNotFound !== null && (
                        <DialogContent>
                            <Stack gap={'10px'} alignItems={'center'} flexDirection={'row'}>
                                <Exclamation />
                                <Typography sx={{ color: '#FF6187' }}>
                                    {translate('not_found_address')} {valueNotFound}
                                </Typography>
                            </Stack>
                        </DialogContent>
                    )}
                    <DialogActions>
                        <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
                            {address.length ? (
                                <Button
                                    onClick={() => setAddress([])}
                                    disableRipple
                                    disableTouchRipple
                                    disableFocusRipple
                                    sx={{ textTransform: 'capitalize' }}
                                    variant="text">
                                    <T>back</T>
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => {
                                        setOpen(false)
                                        setValueNotFound(null)
                                    }}
                                    ddisableRipple
                                    disableTouchRipple
                                    disableFocusRipple
                                    sx={{ textTransform: 'capitalize' }}>
                                    <T>cancel</T>
                                </Button>
                            )}
                            <Button
                                disabled={!!!props.value}
                                onClick={address.length ? handleSelectAddress : onOkThenSearch}
                                className="ok-button"
                                disableRipple
                                disableTouchRipple
                                disableFocusRipple
                                sx={{ width: '145px', background: 'rgba(7, 159, 244, 1)', color: 'white', borderRadius: '32px' }}>
                                {loading ? <Spiner src={Loader} alt="Loading" /> : <T>ok</T>}
                            </Button>
                        </Box>
                    </DialogActions>
                </DialogWrapper>
            </Stack>
        </ThemeProvider>
    )
})

const Spiner = styled('img')({
    animation: 'rotation 1s infinite linear',
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    }
})

const ZipDisplay = styled(Stack)(({ theme, focused }) => {
    return {
        border: focused ? '2px solid rgb(153, 188, 223)' : '1px solid rgb(229, 238, 247)',
        borderRadius: '12px',
        cursor: 'pointer',
        padding: '15px',
        margin: '10px 0',
        h5: {
            fontSize: '14px',
            color: focused ? 'rgb(0, 59, 111)' : 'rgb(147, 170, 191)',
            ':first-of-type': {
                fontSize: '18px',
                color: focused ? 'rgb(0, 59, 111)' : 'rgb(51, 98, 140)'
            }
        }
    }
})
const DefaultDialogWrapper = styled(Dialog)(({ theme }) => {
    return {
        '.MuiDialog-paper': {
            '.MuiDialogTitle-root': {
                padding: '15px 20px',
                color: '#22aaec'
                // display: 'none !important'
            }
        }
    }
})

const ModalSearchTheme = createTheme({
    typography: {
        fontFamily: 'Poppins, NotoSansThai',
        option: {
            fontSize: '1.833333em',
            fontFamily: 'Poppins, NotoSansThai',
            padding: '0.5em'
        }
    },
    components: {
        MuiDialog: {
            defaultProps: {
                PaperComponent: 'div'
            },
            styleOverrides: {
                paper: {
                    backgroundColor: '#f7f7f7',
                    width: '345px !important',
                    borderRadius: '12px',
                    margin: 0
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '0px 15px 20px 15px',
                    zIndex: '2'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'center',
                    padding: '0 15px 20px 15px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    with: '100% !important',
                    fontFamily: 'Poppins, NotoSansThai',
                    color: 'rgba(51, 98, 140, 1)',
                    fontSize: '16px',
                    '&:hover': {
                        backgroundColor: 'transparent'
                    },
                    '&.ok-button': {
                        width: '145px',
                        height: '55px',
                        padding: '18px 0',
                        fontWeight: 'bold',
                        background: '#079FF4',
                        color: 'white',
                        borderRadius: '32px',
                        '&:disabled': {
                            background: 'rgba(204, 222, 239, 1)',
                            color: 'white'
                        }
                    }
                }
            }
        }
    }
})

const CustomInputWithEndorment = styled(DefaultInput)`
    .MuiInputAdornment-root {
        position: absolute;
        right: 15px;
    }
`

export default ModalSearch
