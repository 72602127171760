import { makeAutoObservable } from 'mobx'

class StoreSettingsElement {
    cardTitle = {
        titleStep1: '',
        titleStep2: '',
        titleStep3: '',
        titleStep4: ''
    }

    settingCard = {
        title: '',
        titleAlign: '',
        padding: '',
        background: '',
        opacity: ''
    }

    userLayout = {
        className: ''
    }

    ResetCardTitle() {
        this.cardTitle = {
            titleStep1: '',
            titleStep2: ''
        }
    }
    ResetSettingCard() {
        this.settingCard = {
            title: '',
            titleAlign: '',
            padding: ''
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export const storeSettingsElement = new StoreSettingsElement()
