import { observer } from 'mobx-react-lite'
import { Stack, Typography, Card, Box, styled } from '@mui/material'
import DefaultInput from '../../Elements/Forms/DefaultInput'
import DefaultButton from '../../Elements/Forms/DefaultButton'
import { useState } from 'react'
import Loader from '../Login/Assets/Loader.svg'
import { useFormik } from 'formik'
import * as yup from 'yup'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'

// const option = [
//     {
//         title: 'Send Email',
//         value: 'Email'
//     },
//     {
//         title: 'Send SMS',
//         value: 'Sms'
//     }
// ]

const validationEmailSchema = yup.object({
    emailOrId: yup
        .string('Enter your email or UnicityID')
        .required('email_or_unicity_id_are_required')
        .test('test-name', 'Enter Valid Email or UnicityID', value => {
            //eslint-disable-next-line
            const emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')
            const numberRegex = /^()?\d{8,10}$/
            let isValidEmail = emailRegex.test(value)
            let isValidUID = numberRegex.test(value)
            if (!isValidEmail && !isValidUID) return false
            return true
        })
})

const validationAllSchema = yup.object({
    emailOrId: yup
        .string('Enter your email')
        .required('email_is_required')
        .test('test-name', 'Enter Valid Email', value => {
            //eslint-disable-next-line
            const emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')
            let isValidEmail = emailRegex.test(value)
            if (!isValidEmail) return false
            return true
        }),
    customerId: yup
        .string('Enter your Customer ID')
        .required('for_security')
        .test('test-name', 'Enter Valid Customer ID', value => {
            //eslint-disable-next-line
            const numberRegex = /^()?\d{8,10}$/
            let isValidUID = numberRegex.test(value)
            if (!isValidUID) return false
            return true
        })
})

const ResetPasswordForm = observer(() => {
    const [isLoading, setIsLoading] = useState(false)
    const [isShowCustomerId, setIsShowCustomerId] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const navigate = useNavigate()
    const translate = useTranslate()

    const formik = useFormik({
        initialValues: {
            emailOrId: '',
            customerId: '',
            type: 'Email'
        },
        validationSchema: () => {
            if (!isShowCustomerId) {
                return validationEmailSchema
            }
            return validationAllSchema
        },
        onSubmit: async values => {
            try {
                setIsLoading(true)
                const response = await StoreAuth.resetPassword(values)

                if (get(response, 'error_code', '') === '666') {
                    setIsShowCustomerId(true)
                    setIsLoading(false)
                    setShowErrorMessage(false)
                    return
                }

                if (response.code === 400 || response.code === 404) {
                    console.log('error')
                    setIsLoading(false)
                    setShowErrorMessage(true)
                    return
                }

                if (response.code === 500) {
                    console.log('error')
                    setIsLoading(false)
                    navigate('/reset-password-error')
                    return
                }

                setIsLoading(false)
                navigate('/reset-password-success')
            } catch (error) {
                setIsLoading(false)
                setShowErrorMessage(true)
            }
        }
    })

    return (
        <Card
            sx={{
                marginTop: '40px',
                padding: {
                    xs: '40px 24px 50px 24px',
                    sm: '40px 24px 50px 24px',
                    md: '40px 0 50px 0'
                },
                borderRadius: '12px',
                width: {
                    xs: '100%',
                    sm: '600px',
                    md: '773px'
                },
                boxShadow: 'none'
            }}>
            <Stack width="100%" sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                    variant="h3"
                    sx={{
                        marginBottom: '30px',
                        fontSize: {
                            xs: '24px',
                            sm: '32px'
                        },
                        width: '100%',
                        textAlign: {
                            xs: 'center',
                            sm: 'center'
                        }
                    }}>
                    <T>reset_password</T>
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: {
                            xs: '12px',
                            sm: '14px'
                        },
                        fontWeight: 400,
                        textAlign: {
                            xs: 'center',
                            sm: 'center'
                        },
                        color: '#153862',
                        lineHeight: '22px',
                        width: storeElement.dynamicWidth > 600 ? '458px' : 'auto'
                    }}>
                    <T>reset_password_description</T>
                </Typography>
                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                    autoComplete="off"
                    noValidate>
                    <Box
                        noValidate
                        autoComplete="off"
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '370px',
                                md: '370px'
                            },
                            marginTop: {
                                xs: '32px',
                                sm: '40px'
                            }
                        }}>
                        <DefaultInput
                            id="emailOrId"
                            name="emailOrId"
                            type="email"
                            label={isShowCustomerId ? translate('email_address') : translate('email_address_or_unicity_id')}
                            value={formik.values.emailOrId}
                            fullWidth={true}
                            onBlur={formik.handleBlur}
                            error={formik.touched.emailOrId && Boolean(formik.errors.emailOrId)}
                            onChange={formik.handleChange}
                            helpertext={!showErrorMessage && formik.touched.emailOrId ? translate(formik.errors.emailOrId) : ''}
                        />
                    </Box>
                    {isShowCustomerId && (
                        <Box
                            sx={{
                                width: {
                                    xs: '100%',
                                    sm: '370px',
                                    md: '370px'
                                },
                                marginTop: {
                                    xs: '18px',
                                    sm: '20px'
                                }
                            }}>
                            <DefaultInput
                                id="customerId"
                                name="customerId"
                                label={translate('customer_id')}
                                value={formik.values.customerId}
                                fullWidth={true}
                                onBlur={formik.handleBlur}
                                error={formik.touched.customerId && Boolean(formik.errors.customerId)}
                                onChange={formik.handleChange}
                                helpertext={!showErrorMessage && formik.touched.customerId ? translate(formik.errors.customerId) : ''}
                            />
                        </Box>
                    )}

                    {showErrorMessage && (
                        <Box
                            sx={{
                                width: {
                                    xs: '100%',
                                    sm: '370px',
                                    md: '370px'
                                }
                            }}>
                            <div style={{ marginTop: '8px', width: '100%' }}>
                                <label style={{ paddingLeft: 16 }}>
                                    <TextHeleperTypo variant="label">
                                        <T>email_address_or_unicity_id</T>
                                    </TextHeleperTypo>
                                </label>
                            </div>
                        </Box>
                    )}

                    <Box
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '279px'
                            }
                        }}
                        mt="40px">
                        <DefaultButton
                            disabled={!(formik.isValid && formik.dirty)}
                            type="submit"
                            fullWidth={true}
                            titleText={isLoading ? <Spiner src={Loader} alt="Loading" /> : translate('submit')}
                        />
                    </Box>
                </form>
            </Stack>
        </Card>
    )
})

const Spiner = styled('img')({
    animation: 'rotation 1s infinite linear',
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    }
})

const TextHeleperTypo = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins, NotoSansThai',
    fontStyle: 12,
    color: '#D03C3C',
    fontWeight: 500
}))

export default ResetPasswordForm
