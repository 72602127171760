const valid = require('card-validator')

/**
 * Validate credit card
 *
 * Example cardInfo
 * ```js
 * {
 * 	countryCode: "JP",
 * 	creditCard: {
 * 	  creditCardNumber: '345177925488348',
 * 	  payer: 'test',
 *    creditCardExpires: '2030-10',
 * 	  creditCardSecurityCode: '7373'
 * 	}
 * }
 * ```
 * ---
 * if there are no any error it will return
 * ```js
 * { success: true }
 * ```
 * otherwise
 * ```js
 * { success: false, error_messages: ['some error']}
 * ```
 * ---
 * `countryCode`: must be `TH | JP | AU | NZ`\
 * `creditCardNumber`: is number and no white space\
 * `payer`: a-z, A-Z, 0-9, white space\
 * `creditCardExpires`: format must be like this -> '2030-10'\
 * `creditCardSecurityCode`: number length between 3 - 4
 *
 * @param {object} cardInfo
 * @returns {object}
 */
function cardValidate(cardInfo) {
    // mutable object for pass by reference
    const context = {
        cardType: undefined,
        errors: [],
        success: true
    }

    const countryCode = cardInfo.countryCode
    const cardNumber = cardInfo.creditCard.creditCardNumber
    const payer = cardInfo.creditCard.payer
    const expiredDate = cardInfo.creditCard.creditCardExpires
    const cvv = cardInfo.creditCard.creditCardSecurityCode

    validateNumber(context, cardNumber)
    validatePayer(context, payer)
    validateExpirationDate(context, expiredDate)
    validateCountry(context, countryCode)
    validateCVV(context, cvv)
    validateCardType(context, cardNumber)

    if (!context.success) {
        return { success: false, error_messages: context.errors }
    }

    return { success: true }
}

function pushError(context, errorMsg) {
    context.errors.push(errorMsg)
    context.success = false
}

function validateNumber(context, cardNumber) {
    if (!cardNumber) {
        pushError(context, 'creditcard_not_found')
        return
    }

    const numberValidation = valid.number(cardNumber)
    if (
        !numberValidation.isValid ||
        // not match 0-9
        /^[0-9]*$/g.test(cardNumber) === false
    ) {
        pushError(context, 'creditcard_invalid')
    }

    context.cardType = numberValidation?.card?.type
}

function validatePayer(context, payer) {
    if (payer === '') {
        pushError(context, 'cardholder_not_found')
        return
    }

    // not match a-z, A-Z, 0-9, whitespace, "." (dot)
    if (/^[a-zA-Z0-9\s.]*$/g.test(payer) === false) {
        pushError(context, 'cardholder_invalid')
    }
}

function validateExpirationDate(context, expiredDate) {
    if (expiredDate === undefined) {
        pushError(context, 'expired_not_found')
        return
    }

    const { isValid } = valid.expirationDate(expiredDate)
    if (
        !isValid ||
        // not format like this '2020-30'
        /^\d{2}\/\d{2}$/g.test(expiredDate) === false
    ) {
        pushError(context, 'expired_invalid')
    }
}

function validateCountry(context, countryCode) {
    if (countryCode === undefined) {
        pushError(context, 'country_code_not_found')
        return
    }

    const isCountrySupport = ['TH', 'JP', 'AU', 'NZ', 'SA', 'SG', 'AE'].includes(countryCode)
    if (!isCountrySupport) {
        pushError(context, 'country_code_is_not_support')
    }

    if (!context.cardType) {
        return
    }

    // Todo: allow JCB for TH only
    if (['TH', 'AU', 'NZ'].includes(countryCode)) {
        const isAccept = ['visa', 'mastercard', 'jcb'].includes(context.cardType)
        if (!isAccept) {
            pushError(context, 'only_accept_visa_or_mastercard')
        }
    }

    if (['JP', 'AE'].includes(countryCode)) {
        const acceptList = ['visa', 'mastercard', 'american-express', 'jcb']
        const isAccect = acceptList.includes(context.cardType)
        if (!isAccect) {
            pushError(context, `${countryCode.toLowerCase()}_not_accept_${context.cardType}`)
        }
    }
}

function validateCVV(context, cvv) {
    if (!cvv) {
        pushError(context, 'securitycode_not_found')
        return
    }

    const cvvLength = context.cardType === 'american-express' ? 4 : 3
    const { isValid } = valid.cvv(cvv, cvvLength)
    if (!isValid) {
        pushError(context, 'securitycode_invalid')
    }
}

function validateCardType(context, cardNumber) {
    const isAccept = ['visa', 'mastercard', 'american-express'].includes(context.cardType)
    if (!isAccept) {
        pushError(context, 'only_accept_visa_or_mastercard')
    }
}

function checkCardType(cardNumber, full = true) {
    let card = ''
    if (full) {
        // Visa
        let fullVisaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/
        if (fullVisaRegex.test(cardNumber)) {
            card = 'visa'
        }
        // Mastercard
        let fullMastercardRegex = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/
        if (fullMastercardRegex.test(cardNumber)) {
            card = 'mastercard'
        }
        // American Express
        let fullAmericanExpressRegex = /^3[47][0-9]{13}$/
        if (fullAmericanExpressRegex.test(cardNumber)) {
            card = 'american-express'
        }
        return card
    } else {
        // Visa
        let visaRegex = /^4/
        if (visaRegex.test(cardNumber)) {
            card = 'visa'
        }
        // Mastercard
        let mastercardRegex = /^5[1-5]/
        if (mastercardRegex.test(cardNumber)) {
            card = 'mastercard'
        }
        // American Express
        let americanExpressRegex = /^3[47]/
        if (americanExpressRegex.test(cardNumber)) {
            card = 'american-express'
        }
        return card
    }
}

export const CreditCardHelper = { checkCardType, cardValidate }
