import { Button, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import Loader from './Assets/Loader.svg'

const DefaultButton = observer(({ titleText, ...props }) => {
    let isTrueSet = props.isloading === 'true'
    return (
        <DefaultButtonStyled variant="contained" disableRipple disableTouchRipple disableFocusRipple {...props}>
            {isTrueSet ? <Spiner src={Loader} alt="Loading" /> : titleText}
        </DefaultButtonStyled>
    )
})

const DefaultButtonStyled = styled(Button)(({ theme, backgroundcolor, width, height, fontSize, fontWeight }) => ({
    borderRadius: '32px',
    boxShadow: 'none',
    height: height || 48,
    fontSize: fontSize || 20,
    fontWeight: fontWeight || 600,
    textTransform: 'none',
    letterSpacing: '-0.01em',
    backgroundColor: backgroundcolor || theme.palette.button.main,
    width: width || '100%',
    '&:hover': {
        background: backgroundcolor || theme.palette.button.main,
        boxShadow: 'none'
    },
    '&.Mui-disabled': {
        background: theme.palette.button.disabled,
        color: '#fff'
    }
}))

const Spiner = styled('img')({
    animation: 'rotation 1s infinite linear',
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    }
})

export default DefaultButton
