import { useState } from 'react'
import ExpPicker from './ExpPicker'
import { observer } from 'mobx-react-lite'
import { get } from 'lodash'

const SelectExpWheel = observer(props => {
    const [value, setValue] = useState({
        [props.optionName]: props.value
    })

    const [optionGroups] = useState({
        [props.optionName]: props.optionSet || []
    })

    const onChange = (name, value) => {
        setValue({
            [name]: value
        })
        props.onSet(value)
    }

    return (
        <>
            {get(optionGroups, `${props.optionName}`, []).length > 0 && (
                <ExpPicker optionGroups={optionGroups} valueGroups={value} onChange={onChange} itemHeight={36} propsValue={props.value} />
            )}
        </>
    )
})

export default SelectExpWheel
