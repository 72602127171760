import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { get, isEmpty } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import Payment from '../Payment'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import storeCredit from 'GlobalStores/StoreCredit'
import States from 'Components/Elements/Forms/StatesModal/Country/AustraliaStates.json'
import { toCamelCase } from 'Utils/Utils'
class Thailand {
    autoAddressDisplayKeys = ['street', 'locality', 'postal_code']
    autoAddressKeyToForm = {
        sub1: 'district',
        sub2: 'sub_district',
        city: 'province',
        zip: 'zip_code'
    }

    chainAddress = {
        isChainAddress: false,
        groupAddress: []
    }

    uuid = ''

    toFormat = {
        enroll: {
            uuid: this.GetUuid(),
            fullName: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            phone: '',
            password: ''
            // password1: '',
            // password2: ''
        },
        enrollWithReferral: {
            uuid: this.GetUuid(),
            fullName: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            phone: '',
            password1: '',
            password2: ''
        },
        shop: {
            uuid: this.GetUuid(),
            fullname: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            email: ''
        }
    }

    formShop = {
        fullName: '',
        email: '',
        phone: '',
        street_address: '',
        city: '',
        state: '',
        zip_code: ''
    }

    createAccountWithShippingAddress = {
        type: 'enroll',
        referral_id: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        period: ''
    }

    ExcludedFieldsShippingIfCreateAccount = ['']

    ExcludedFieldsShipping = []

    mapFormKeyToFormatKey = {
        street_address: 'address',
        // fullName: 'fullname_th',
        fullNameEn: 'fullname_en',
        fullNameTh: 'fullname',
        fullName: 'fullname',
        zip_code: 'zip',
        firstname: 'firstname',
        lastname: 'lastname'
    }

    ArrangeCreateCheckoutForm = [
        'fullNameTh',
        'fullNameEn',
        'phone',
        'email',
        'password',
        // 'country',
        'zip_code',
        'province',
        'district',
        'sub_district',
        'street',
        'house_number'
    ]

    ArrangeShippingForm = ['fullName', 'email', 'phone', 'zip_code', 'province', 'district', 'sub_district', 'street', 'house_number']

    PaymentStep = {
        payment: 0,
        createOrder: 0,
        autoship: -2,
        updateLog: 0
    }

    breadcrumbOption = [
        {
            caption: 'customise',
            link: '/products',
            paramsRequire: false
        },
        {
            caption: 'signup_shipping',
            link: '/checkout/create-account',
            paramsRequire: true,
            authCaption: 'shipping',
            authLink: '/checkout/shipping'
        },
        {
            caption: 'billing',
            link: '/checkout/payment',
            paramsRequire: true
        },
        {
            caption: 'purchase',
            link: '/checkout/review',
            paramsRequire: true
        }
    ]

    shippingMethods = [
        {
            id: 1,
            api_value: 'pickup',
            title: 'Pickup, Casablanca Office',
            dict_key: 'pickup_casablanca_office',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Credit card',
                    dict_key: 'creditcard',
                    for_status: []
                }
            ]
        },
        {
            id: 2,
            api_value: 'ctm',
            title: 'CTM, Delivery to Centers',
            dict_key: 'ctm_delivery_to_centers',
            for_status: [],
            options: []
        },
        {
            id: 3,
            api_value: 'homedelivery',
            title: 'Home Delivery',
            dict_key: 'home_delivery',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Credit card',
                    dict_key: 'creditcard',
                    for_status: []
                },
                {
                    id: 2,
                    title: 'Cash on Delivery',
                    dict_key: 'cash_on_delivery',
                    descripion: {
                        title: 'Pay when you receive',
                        dict_key: 'pay_when_you_receive'
                    },
                    for_status: ['M']
                }
            ]
        }
    ]

    orderSummaryConfig = {
        subTotal: {
            display: true,
            dict_key: 'subtotal'
        },
        shipping: {
            display: true,
            dict_key: 'shipping'
        },
        productCredit: {
            display: true,
            dict_key: 'product_credit',
            icon: true,
            allowToUse: []
        },
        vat: {
            display: false,
            dict_key: 'vat',
            value: 0.2 // 20%
        }
    }

    landingLayouts = [
        {
            nameComponent: 'banner',
            titleContent: 'mainBanner',
            style: {}
        },
        {
            nameComponent: 'ourApproach',
            titleContent: 'ourApproach',
            style: {
                minHeight: '0px'
            }
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {
                backgroundColor: '#F1F6FB'
            }
        },
        {
            nameComponent: 'thePractice',
            titleContent: 'thePractice',
            style: {}
        },
        {
            nameComponent: 'howItWorks',
            titleContent: 'howItWorks',
            style: {
                backgroundColor: '#FBF7F1'
            }
        },
        {
            nameComponent: 'whatExpertsSay',
            titleContent: 'expertsSay',
            style: {}
        },
        {
            nameComponent: 'realResults',
            titleContent: 'realResults',
            style: {
                backgroundColor: '#F2F9F8'
            }
        }
    ]

    productLayouts = [
        {
            nameComponent: 'productDetailSelection',
            titleContent: 'productDetailSelection',
            style: {}
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {}
        },
        {
            nameComponent: 'frequentlyAskedQuestions',
            titleContent: 'questions',
            style: {
                backgroundColor: '#F6F9FC'
            }
        }
    ]

    // GetKeysByLanguage() {
    //     const keys = `${StoreTranslate.IsEnglish() ? 'roman' : 'native'}`

    //     this.autoAddressData = {
    //         [`${}_${keys}`]: '',
    //     }
    // }

    StatesList() {
        let items = []
        States.map(raw => {
            items.push({
                text: raw.abbreviation,
                value: raw.abbreviation,
                html: `<span>${raw.name}</span>`
            })
        })
        return items
    }

    AddressOptionList(key) {
        let items = []
        States.map(raw => {
            items.push({
                text: raw,
                value: raw,
                html: `<span>${raw}</span>`
            })
        })
        return items
    }
    MapAutoAddressToForm(form, autoAddressData) {
        const swappedValuesAsKeys = ObjectHelpers.swapKeysAndValues(this.autoAddressKeyToForm)

        const swappedValuesAsKeysWithAssignKey = Object.fromEntries(
            Object.entries(swappedValuesAsKeys).map(([key, value]) => (key === 'zip_code' ? [key, value] : [key, ObjectHelpers.TowRomanAndNativeKey(value)]))
        )

        return form.map(item => {
            if (swappedValuesAsKeys[item.key]) {
                if (autoAddressData.data) {
                    item.value = autoAddressData.data[swappedValuesAsKeys[item.key]]
                }

                if (autoAddressData) {
                    item.value = autoAddressData[swappedValuesAsKeysWithAssignKey[item.key]]
                }
            }

            return item
        })
    }

    FormToFormat(type, form) {
        form.map(item => {
            if (this.mapFormKeyToFormatKey[item.key]) {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][this.mapFormKeyToFormatKey[item.key]] = item.value
                    })
                }
            } else {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][item.key] = item.value
                    })
                }
            }

            return item
        })
    }

    SetUuid(value) {
        this.uuid = value
    }

    GetUuid() {
        return this.uuid
    }

    GetFormat(type) {
        return this.toFormat[type]
    }

    GetPaymentStep() {
        return this.PaymentStep
    }

    GetBreadcrumbOption() {
        return this.breadcrumbOption
    }

    GetShippingMethod() {
        return this.shippingMethods
    }

    SetPaymentStep(key, value) {
        this.PaymentStep[key] = value
    }

    GetOrderSummaryConfig() {
        return this.orderSummaryConfig
    }

    GetLandingLayout() {
        return this.landingLayouts
    }

    GetProductLayout() {
        return this.productLayouts
    }

    /**
     *
     * @param {*} uuid
     * @param {object} detailPage { products, detailPage, isSubscribe, isEnroll, type}
     * @param {*} CreditCardEncrypted
     */
    async SubmitPayment(uuid, detailPage, CreditCardEncrypted, products) {
        try {
            const Pay = new Payment(uuid, detailPage)
            const responsePreparePayment = await Pay.PreparePaymentData()

            let preparedData = responsePreparePayment?.data?.data
            // Add payments
            let payments = []
            if (preparedData) {
                // Prepare credit card data
                let creditCardData = {
                    amount: preparedData.ushop.total_price
                }

                if (!isEmpty(CreditCardEncrypted)) {
                    creditCardData['method'] = toCamelCase(CreditCardEncrypted.method)
                    creditCardData['payer'] = CreditCardEncrypted.methodDetails.payer
                    creditCardData['creditCardNumber'] = CreditCardEncrypted.methodDetails.creditCardNumber
                    creditCardData['creditCardSecurityCode'] = CreditCardEncrypted.methodDetails.creditCardSecurityCode
                    creditCardData['creditCardExpires'] = CreditCardEncrypted.methodDetails.creditCardExpires
                }

                payments.push(creditCardData)
                // END Prepare credit card data

                // When customer have E-Credit

                const allowCreditEnable = get(StoreAuth, 'allowCredit.enable', false)

                if (allowCreditEnable && storeCredit.GetCreditData()?.creditUsed > 0) {
                    const productCredit = storeCredit.GetCreditData()
                    const useCredit = { method: 'eCredit', amount: productCredit.creditUsed }
                    payments.push(useCredit)
                    Pay.hydra.transactions = {
                        items: [CreditCardEncrypted, useCredit]
                    }
                } else {
                    Pay.hydra.transactions = {
                        items: [CreditCardEncrypted]
                    }
                }
            }

            let oneTime = await Pay.SubmitOneTimePayment({ payments })

            if (!oneTime) {
                StorePaymentStep.SetPaymentStep('updateLog', -1)
                return { success: false }
            }

            StorePaymentStep.SetPaymentStep('createOrder', 1) // Create Order success when onetime pass

            await Pay.AutoshipTHA() // Just Autoship don't need to order to hydra again

            await Pay.SendEmailTHA()
            // return { success: false }

            return {
                success: true,
                payment_id: oneTime?.data?.invoiceNo || responsePreparePayment.data.data.payment_id,
                order_number: responsePreparePayment.data.data.payment_id
            }
        } catch (error) {
            console.error(error)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })

            return { success: false }
        }
    }

    async CreateAccount(uuid, accForm, shippingForm, referral) {
        try {
            const forms = Object.assign(this.createAccountWithShippingAddress, accForm)
            const returnShippingAddress = {}
            shippingForm.map(item => {
                if (this.ExcludedFieldsShippingIfCreateAccount.includes(item.key)) {
                    return false
                }
                if (this.mapFormKeyToFormatKey[item.key]) {
                    forms[this.mapFormKeyToFormatKey[item.key]] = item.value
                    returnShippingAddress[this.mapFormKeyToFormatKey[item.key]] = item.value
                } else {
                    forms[item.key] = item.value
                    returnShippingAddress[item.key] = item.value
                }
            })

            if (referral) {
                forms.referral_id = referral
            } else {
                delete forms.referral_id
            }

            delete forms.country

            const periodResponse = await FeelGreatAPI.GetPeriod('AU')
            forms.period = periodResponse.data.entryPeriod
            forms.uuid = uuid

            const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/THA/feelGreat/createAccount${
                ServerEnv.ENV === 'DEV' ? '?dev=1' : '?emailDelay=1h'
            }`
            const response = await axios.post(url, forms)

            return {
                ...response.data,
                form: forms
            }
        } catch (e) {
            console.error(e)

            return {
                success: false
            }
        }
    }

    constructor() {
        StorePaymentStep.SetPaymentSteps(this.PaymentStep)
        makeAutoObservable(this)
    }
}

export default Thailand
