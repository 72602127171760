import React, { useEffect, useState } from 'react'
import { ReactComponent as SuccessIcon } from '../Assets/success.svg'
import { observer } from 'mobx-react-lite'
import { Stack, Typography, Box, Link } from '@mui/material'
import { DefaultButton } from '../../Forms'
import { useNavigate } from 'react-router-dom'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { runInAction } from 'mobx'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'

const CancelSuccessMobile = observer(props => {
    const [loadingButton, setLoadingButton] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const translate = useTranslate()
    const navigate = useNavigate()

    useEffect(() => {
        runInAction(() => {
            storeSettingsElement.cardTitle.titleStep1 = <T>subscriptions</T>
            storeSettingsElement.settingCard.padding = '20px'
        })

        return () => {
            runInAction(() => {
                storeSettingsElement.cardTitle.titleStep1 = ''
                storeSettingsElement.settingCard.padding = ''
            })
        }
    }, [])

    const handleFeelGreatHome = () => {
        setLoadingButton(true)
        setDisabled(true)
        setTimeout(() => {
            navigate('/')
            setLoadingButton(false)
            setDisabled(false)
        }, 1500)
    }

    return (
        <Stack alignItems={'center'} justifyContent={'center'} textAlign="center" mt="50px" mb="50px">
            <SuccessIcon />

            <Box mt="40px" mb="40px">
                <Box mb="20px">
                    <Typography variant="title2" color="#023764">
                        <T>subscription_success_title</T>
                    </Typography>
                </Box>

                <Typography variant="caption5" textAlign={'center'} color="#023764">
                    <T>subscription_success_centent</T>{' '}
                    <Box component="span" sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
                        <Link href={`mailto:${translate('feelgreat_email')}`} color="#023764">
                            <T>feelgreat_email</T>.
                        </Link>
                    </Box>
                </Typography>
            </Box>

            {/* <DefaultButton
                type="submit"
                fullWidth={true}
                titleText={translate('feel_great_home')}
                width="100%"
                onClick={() => handleFeelGreatHome()}
                isloading={loadingButton.toString()}
                fontSize="20px"
                fontWeight="400"
                disabled={disabled}
            /> */}
        </Stack>
    )
})

export default CancelSuccessMobile
