import { Box, Grid, ThemeProvider, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite' 
import { customTheme } from './PersonalTheme'
import { ReactComponent as Congrate } from './Assets/Congrat.svg' 
const SuccessPaper = observer(props => { 
    const { title = 'Success', desc = 'Your Unicity account email has been successfully changed.' } = props

    return (
        <ThemeProvider theme={customTheme}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <Congrate height={39} />
                <Typography mt="30px" variant="h3" fontSize={'32px'}>
                    {title}
                </Typography>
                <Grid container mt={'20px'}>
                    <Typography
                        variant="desc"
                        sx={{
                            width: '100%'
                        }}>
                        {desc}
                    </Typography>
                </Grid>
            </Box>
        </ThemeProvider>
    )
})

export default SuccessPaper
