
const CloseIcon = ({ fill = 'none' }) => (

    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill={fill}>
        <path d="M9.70517 0.295062C9.31149 -0.098354 8.67353 -0.098354 8.27998 0.295062L4.97612 3.59879L1.72036 0.342895C1.3268 -0.0505208 0.688848 -0.0505208 0.295163 0.342895C-0.0983876 0.73658 -0.0983876 1.37454 0.295163 1.76809L3.55092 5.02385L0.342996 8.23178C-0.0505544 8.62546 -0.0505544 9.26342 0.342996 9.65697C0.539838 9.85368 0.797682 9.95203 1.05566 9.95203C1.3135 9.95203 1.57148 9.85368 1.76832 9.65697L4.97612 6.44918L8.23187 9.70494C8.42872 9.90165 8.68656 10 8.94454 10C9.20238 10 9.46036 9.90165 9.6572 9.70494C10.0508 9.31125 10.0508 8.6733 9.6572 8.27975L6.40144 5.02398L9.70517 1.72026C10.0987 1.32657 10.0987 0.688613 9.70517 0.295062Z" fill="#003B6F" />
    </svg>

)


export default CloseIcon