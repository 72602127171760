import storeAuth from 'GlobalStores/User/StoreAuth'
import httpHelper from 'Services/HttpHelper'
import { ServerEnv } from 'Configs/ServerEnv'

const GetProducts = ({ status = 'C' }) => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/products-v2/publish/THA?allow=shop&status=${status}&warehouse=Main`

    return httpHelper.Get({
        url: url,
        config: {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    })
}

const getHotFormatV2 = (uuid, countryCode3Alpha, calc) => {
    let url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/format/${countryCode3Alpha}/feelGreatReferral/detail-v2/${uuid}`
    if (calc) {
        url += `?ordercalC=1`
    }

    return httpHelper.Get({
        url: url,
        config: {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    })
}

const putHotFormatV2 = (data, countryCode3Alpha) => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/${countryCode3Alpha}/feelGreatReferral/address`
    console.log('putHotFormatV2')
    return httpHelper.Put({
        url: url,
        data
    })
}

const getPdpaStatus = baId => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/pdpa/data?baId=${baId}`
    return httpHelper.Get({
        url: url,
        config: {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    })
}

const setPdpaStatus = data => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/pdpa/dataCheckbox`
    return httpHelper.Post({
        url: url,
        data,
        config: {
            headers: {
                'Content-Type': 'application/json',
                'authorization-hydra': storeAuth.GetToken()
            }
        }
    })
}

const checkDuplicateOrder = ({ countryCode3Alpha, uuid }) => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/duplicateOrder/${countryCode3Alpha}/feelGreatReferral/${uuid}`

    return httpHelper.Get({
        url: url
    })
}

export const ReferralThailandAPIs = {
    GetProducts,
    getHotFormatV2,
    putHotFormatV2,
    getPdpaStatus,
    setPdpaStatus,
    checkDuplicateOrder
}
