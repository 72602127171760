import { makeAutoObservable, runInAction } from 'mobx'
import get from 'lodash/get'
import feelGreateAPI from 'GlobalStores/FeelGreat/APIs'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import Customer from 'GlobalStores/User/Customer'

class StoreSharingSocial {
    loadingSharing = false

    constructor() {
        makeAutoObservable(this)
    }
    /**
     * @returns {Customer} Customer
     */

}
const storeSharingSocial = new StoreSharingSocial()
export default storeSharingSocial
