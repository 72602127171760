import { MainTemplate } from 'Components/Template/MainTemplate'
import { makeAutoObservable, reaction } from 'mobx'

class StoreElement {
    backdrop = {
        enabled: false,
        backgroundColor: 'rgba(0,0,0, 0.35)',
        zIndex: 10
    }

    dynamicWidth = window.innerWidth

    openHeaderFilter = false
    shouldDisableHeaderFilter = true
    openingHeaderFilter = false
    forceBodyLoading = false

    somethingWrongModal = false
    afterCloseModal = null

    counterClosedAlert = 0
    forceCommissionTopup = false

    hideFooter = false

    reloadProfilePicture = false

    globalBreakPoints = {
        desktop: 1024,
        tablet: 768,
        mobile: 320
    }

    deviceType = 'desktop'

    userLayout = {
        className: ''
    }

    settingCard = {
        title: '',
        titleAlign: ''
    }

    showCardFrontDrop = false

    scrollTo = false

    theme = MainTemplate

    showLoginModal = false

    triggerShowProvinceInModal = false

    useRecapcha = /localhost/.test(window.location.pathname) ? false : true

    constructor() {
        makeAutoObservable(this)
    }

    SetSomethingWrongModal(value, functionAfterClose) {
        this.somethingWrongModal = value
        if (value === true && typeof functionAfterClose === 'function') {
            this.afterCloseModal = functionAfterClose
        }
        if (value === false) {
            if (this.afterCloseModal) {
                this.afterCloseModal()
            }
        }
    }

    IsSomethingWrong() {
        return this.somethingWrongModal
    }

    makeBodyBlur() {
        document.body.classList.add('blur')
        document.body.style.overflowY = 'hidden'
    }

    removeBlur() {
        document.body.classList.remove('blur')
        document.body.style.overflowY = 'auto'
    }

    IsDesktop() {
        return this.deviceType === 'desktop'
    }
}

export const storeElement = new StoreElement()

reaction(
    () => storeElement.forceBodyLoading,
    isLoading => {
        if (isLoading === true) {
            storeElement.makeBodyBlur()
        } else {
            storeElement.removeBlur()
        }
    }
)

reaction(
    () => storeElement.hideFooter,
    isHide => {
        if (isHide) {
            document.body.style.position = 'fixed'
        } else {
            document.body.style.position = 'relative'
        }
    }
)

reaction(
    () => storeElement.dynamicWidth,
    width => {
        if (width < 768) {
            storeElement.deviceType = 'mobile'
        } else if (width >= 768 && width < 1000) {
            storeElement.deviceType = 'tablet-portrait'
        } else if (width >= 1000 && width < 1024) {
            storeElement.deviceType = 'tablet-landscape'
        } else if (width >= 1024 && width < 1440) {
            storeElement.deviceType = 'small-desktop'
        } else if (width >= 1440) {
            storeElement.deviceType = 'desktop'
        }
    }
)
