import { Box, Grid, styled, ThemeProvider, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import { customTheme } from './PersonalTheme'
import { DefaultButton, DefaultTextFields } from '../Forms'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isPhoneNumber } from 'Helpers/StringHelpers'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { ReactComponent as SpinnerIcon } from './Assets/Spinner.svg'
import OtpBox from './OtpBox'
import { isNull } from 'lodash'
import SuccessPaper from './SuccessPaper'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import userAPI from 'GlobalStores/User/APIs'
import { runInAction } from 'mobx'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'

const EditMobileNumber = observer(props => {
    const translate = useTranslate()
    const navigate = useNavigate()
    const { width } = useSizeWindow()
    const [reqOtpLoading, setReqOtpLoading] = useState(false)
    const [resendOtpLoading, setResendOtpLoading] = useState(false)
    const [verifyLoading, setVerifyLoading] = useState(false)
    const [otp, setOTP] = useState(null)
    const [reqOtpRes, setReqOtpRes] = useState(null)
    const [step, setStep] = useState(1) // 1 = checkTelNumber, 2 = requestOTP, 3 = Success wait till redirect
    const [updateError, setUpdateError] = useState(false)
    const [confirmOTPError, setConfirmOTPError] = useState(null)
    const [updateSuccess, setUpdateSuccess] = useState(false)
    const [mobileNumber, setMobileNumber] = useState('')

    const { userData } = props

    const validationPhoneSchema = yup.object({
        mobilePhone: yup.string(translate('enter_your_mobile_phone')).test('test-phone', translate('enter_valid_phone_number'), value => {
            if (!isPhoneNumber(value)) return false
            return true
        })
    })

    const formik = useFormik({
        initialValues: {
            mobilePhone: userData.mobilePhone || ''
        },
        validationSchema: validationPhoneSchema,
        onSubmit: async values => {
            setMobileNumber(values.mobilePhone)
            setUpdateError(null)
            setReqOtpLoading(true)
            setResendOtpLoading(true)
            setConfirmOTPError(null)
            try {
                let res = await userAPI.requestOTP(values.mobilePhone, StoreAuth.country, 'FG')
                setReqOtpRes(res.data)
                setTimeout(() => {
                    setReqOtpLoading(false)
                    setResendOtpLoading(false)
                    setStep(2)
                }, 1000)
            } catch (error) {
                if (!!error.response) {
                    if (error.response.data.message === 'maximum_request_otp') {
                        setUpdateError(error)
                    }
                }
                setReqOtpLoading(false)
                setResendOtpLoading(false)
            }
        }
    })

    useEffect(() => reset(), [])

    useEffect(() => {
        runInAction(() => {
            storeSettingsElement.settingCard.opacity = 'none'
            storeSettingsElement.cardTitle.titleStep2 = <T>settings</T>
        })

        return () => {
            runInAction(() => {
                storeSettingsElement.cardTitle.titleStep2 = ''
            })
        }
    })

    const goBack = () => {
        navigate(-1)
    }

    const handleVerifyOtp = async () => {
        setVerifyLoading(true)
        try {
            await userAPI.confirmOTP(otp, reqOtpRes.ref_id)
            await StoreAuth.updateUserDetails({ mobilePhone: mobileNumber })
            setUpdateSuccess(true)
            setVerifyLoading(false)
        } catch (error) {
            setConfirmOTPError(error)
            setVerifyLoading(false)
        }
    }

    const reset = () => {
        setReqOtpLoading(false)
        setResendOtpLoading(false)
        setVerifyLoading(false)
        setOTP(null)
        setUpdateError(false)
        setUpdateSuccess(false)
        setStep(1)
    }

    return !updateSuccess ? (
        <ThemeProvider theme={customTheme}>
            <div style={width >= 900 ? { margin: '0 60px' } : {}}>
                <Typography variant="h3" fontWeight={600} textAlign="center" fontSize={width <= 830 ? '30px' : '32px'}>
                    <T>change_mobile</T>
                </Typography>
                <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                    <Grid container mt={width <= 830 ? '40px' : '60px'} rowSpacing={'16px'} columnSpacing={'30px'}>
                        <Grid item xs={12} paddingTop={'0px !important'}>
                            <Box noValidate sx={{ maxWidth: '100%' }}>
                                <DefaultTextFields
                                    inputFontSize={width > 834 ? '24px' : '16px'}
                                    disabled
                                    label={translate('current_mobile')}
                                    value={userData.mobilePhone}
                                />
                            </Box>
                            <Box mt={'20px'} noValidate sx={{ maxWidth: '100%' }}>
                                <DefaultTextFields
                                    inputFontSize={width > 834 ? '24px' : '16px'}
                                    label={translate('new_mobile')}
                                    autoComplete="off"
                                    id="mobilePhone"
                                    name="mobilePhone"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.mobilePhone && Boolean(formik.errors.mobilePhone)}
                                    helpertext={formik.touched.mobilePhone && formik.errors.mobilePhone}
                                    verified={step === 2}
                                />
                            </Box>
                            {updateError ? (
                                <Box
                                    mt={'20px'}
                                    sx={{
                                        maxWidth: '100%',
                                        borderRadius: '8px',
                                        backgroundColor: '#FFE7EB',
                                        padding: '15px 27px',
                                        textAlign: 'center'
                                    }}>
                                    <Typography variant="desc" sx={{ width: '100%' }} fontSize="14px" fontWeight={300} color="#FF6187">
                                        <T>phone_number_already_taken</T>
                                    </Typography>
                                </Box>
                            ) : null}

                            {step === 2 ? (
                                <Box mt={'20px'} sx={{ maxWidth: '100%' }}>
                                    <OtpBox resend={resendOtpLoading} codeError={confirmOTPError} fullFilled={setOTP} reqOtpRes={reqOtpRes} />
                                </Box>
                            ) : null}
                        </Grid>
                    </Grid>

                    {step === 1 && (
                        <Grid container mt={'40px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                            <Grid
                                item
                                // xs={6}
                                // sm={6}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    alignItems: 'center',
                                    width: width <= 834 ? '100%' : '344px'
                                }}>
                                <DefaultButton
                                    disabled={!(formik.isValid && formik.dirty && !reqOtpLoading)}
                                    type="submit"
                                    fullWidth={true}
                                    titleText={translate('get_otp')}
                                    isloading={reqOtpLoading.toString()}
                                    backgroundcolor={'#003B6F'}
                                    width={'100%'}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                mt={'20px'}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center'
                                }}>
                                <Typography
                                    variant="action"
                                    fontSize={16}
                                    fontFamily={500}
                                    onClick={() => {
                                        if (!reqOtpLoading) {
                                            goBack()
                                        }
                                    }}
                                    sx={{ cursor: 'pointer' }}>
                                    <T>cancel</T>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    {step === 2 && (
                        <>
                            {reqOtpLoading ? (
                                <Grid container mt={'73px'} justifyContent={'center'}>
                                    <SpinnerIconEffect fontSize={'25px'} />
                                </Grid>
                            ) : (
                                <Grid container mt={'40px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <Grid
                                        item
                                        // xs={6}
                                        // sm={6}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'end',
                                            alignItems: 'center',
                                            width: width <= 834 ? '100%' : '344px'
                                        }}>
                                        <DefaultButton
                                            disabled={confirmOTPError ? true : resendOtpLoading ? true : isNull(otp) ? true : verifyLoading ? true : false}
                                            type="button"
                                            fullWidth={true}
                                            titleText={translate('enroll_verify')}
                                            isloading={verifyLoading.toString()}
                                            backgroundcolor={'#003B6F'}
                                            width={'100%'}
                                            onClick={() => {
                                                handleVerifyOtp()
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        mt={'20px'}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            alignItems: 'center'
                                        }}>
                                        <Typography
                                            variant="action"
                                            fontSize={16}
                                            fontFamily={500}
                                            onClick={() => {
                                                formik.submitForm()
                                            }}
                                            sx={{
                                                textDecoration: 'underline'
                                            }}>
                                            {resendOtpLoading ? <SpinnerIconEffect fontSize={'25px'} /> : <T>resent_otp</T>}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}
                </form>
            </div>
        </ThemeProvider>
    ) : (
        <div style={{ margin: '0 60px' }}>
            <SuccessPaper title={translate('success')} desc={translate('change_mobile_description')} />
        </div>
    )
})

const SpinnerIconEffect = styled(SpinnerIcon)`
    animation: spin 1s infinite;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`
export default EditMobileNumber
