import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Stack, Typography, styled, Box } from '@mui/material'
import { DefaultButton } from '../../Forms'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { ReactComponent as CircleIcon } from '../Assets/circle.svg'
import { ReactComponent as SubtractIcon } from '../Assets/Subtract.svg'
import Dialog from '../Dialog/CustomDialog'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreUser from 'GlobalStores/User/StoreUser'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { runInAction } from 'mobx'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'

const ReasonCancelSubscriptionsMobile = observer(props => {
    const translate = useTranslate()
    const listItem = [
        {
            id: 1,
            text: translate('list_reason_cancel_subscription_1'),
            check: false
        },
        {
            id: 2,
            text: translate('list_reason_cancel_subscription_2'),
            check: false
        },
        {
            id: 3,
            text: translate('list_reason_cancel_subscription_3'),
            check: false
        },
        {
            id: 4,
            text: translate('list_reason_cancel_subscription_4'),
            check: false
        },
        {
            id: 5,
            text: translate('list_reason_cancel_subscription_5'),
            check: false
        },
        {
            id: 6,
            text: translate('list_reason_cancel_subscription_6'),
            check: false
        }
    ]

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState(listItem)
    const [data, setData] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const textDailog = 'cancel_sub_error_checklist'

    useEffect(() => {
        runInAction(() => {
            storeSettingsElement.cardTitle.titleStep1 = <T>subscriptions</T>
            storeSettingsElement.settingCard.padding = '30px'
        })

        return () => {
            runInAction(() => {
                storeSettingsElement.cardTitle.titleStep1 = ''
                storeSettingsElement.settingCard.padding = ''
            })
        }
    }, [])

    const handleSubmit = async () => {
        setLoading(true)
        setDisabled(true)
        try {
            const order = StoreSubScriptions.GetSubscriptionsList()
            const shipmentOptions = 'delivery'
            const shipToAddress = `{"address1":"${order.shipToAddress.address1}","address2":"${order.shipToAddress.address2}","city":"${order.shipToAddress.city}","country":"${order.shipToAddress.country}","state":"${order.shipToAddress.state}","zip":"${order.shipToAddress.zip}"}`
            const productItems = order.lines.items.reduce(
                (obj, element) => ({ ...obj, [element.item.id.unicity]: { qty: element.quantity, price: element.terms.priceEach } }),
                {}
            )
            const profilePicture = StoreUser.CustomerData().userData.profilePicture?.sizes.slice(-1)[0]?.media

            let responseAddress = await FeelGreatAPI.GetAddress(shipmentOptions, shipToAddress)

            if (responseAddress) {
                const newData = {
                    reason: data,
                    autoship_href: order.href,
                    autoship_id: order.id.unicity.toString(),
                    country_code: StoreCountry.Country3(),
                    ba_id: order.customer.id.unicity.toString(),
                    email_data: {
                        ship_to_name: order.shipToName.fullName,
                        ship_to_email: order.shipToEmail,
                        ship_to_address: responseAddress.data.display_address,
                        items: JSON.stringify(productItems),
                        profile_picture: profilePicture
                    }
                }
                const newData2 = {
                    reason: data,
                    autoship_href: order.href,
                    autoship_id: order.id.unicity.toString(),
                    country_code: StoreCountry.Country3(),
                    ba_id: order.customer.id.unicity.toString(),
                    email_data: {
                        ship_to_name: order.shipToName.fullName,
                        ship_to_email: order.shipToEmail,
                        ship_to_address: responseAddress.data.display_address,
                        items: JSON.stringify(productItems)
                    }
                }

                let responseCancel = await FeelGreatAPI.CancelSubscription(profilePicture !== undefined ? newData : newData2, StoreAuth.GetToken())
                if (responseCancel.data.success) {
                    navigate('/user/subscriptions/cancel-subscriptions/cancel-subscriptions-success')
                    setLoading(false)
                    setDisabled(false)
                }
            }
        } catch (error) {
            console.log(error)
            setOpenDialog(true)
            setLoading(false)
            setDisabled(false)
        }
    }

    const handleOnclick = (text, id, check) => {
        setData(prev => (data.includes(text) ? prev.filter(cur => cur !== text) : [...prev, text]))

        const newState = list.map(obj => {
            if (obj.id === id) {
                return { ...obj, check: !check }
            }

            return obj
        })

        setList(newState)
    }

    return (
        <Box position="relative">
            {loading && <CardDisable />}
            <Stack justifyContent="center" flexDirection="row">
                <Typography variant="title3" textAlign="center" fontSize={'24px !important'}>
                    <T>title_reason_cancel_subscription</T>
                </Typography>
            </Stack>
            <Box mt="40px" mb="40px">
                <Grid container spacing={'20px'}>
                    {list.map((i, index) => (
                        <Grid item xs={12} key={index}>
                            <Item check={i.check.toString()} onClick={() => handleOnclick(i.text, i.id, i.check)}>
                                {i.check ? <SubtractIcon /> : <CircleIcon />}

                                <Typography variant="caption5" color="#023764" fontWeight={i.check ? 600 : ''} sx={{ marginLeft: '10px' }}>
                                    {i.text}
                                </Typography>
                            </Item>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Stack alignItems={'center'} justifyContent={'center'} mb="20px">
                <DefaultButton
                    type="submit"
                    fullWidth={true}
                    titleText={translate('cancel_subscription')}
                    width="100%"
                    onClick={() => handleSubmit()}
                    isloading={loading.toString()}
                    fontSize="20px"
                    fontWeight="400"
                    disabled={data.length === 0 || disabled}
                />
            </Stack>
            <Dialog openDialog={openDialog} setOpenDialog={setOpenDialog} text={textDailog} type="error" />
        </Box>
    )
})

const CardDisable = styled(Box)`
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    opacity: 65%;
    border-radius: 12px;
    z-index: 1;
`

const Item = styled(Box)`
    border: ${props => (props.check === 'true' ? '1px solid #E5FBEE' : '1px solid #ccdeef')};
    border-radius: 6px;
    height: 55px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    cursor: pointer;
    background: ${props => (props.check === 'true' ? '#E5FBEE' : 'none')};
`

export default ReasonCancelSubscriptionsMobile
