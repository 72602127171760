import { autorun, makeAutoObservable } from 'mobx'
import * as K from './Cores/Translator/Keywords'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'

class StoreReCaptcha {
    constructor() {
        makeAutoObservable(this)
        observer(this)
    }

    currentLanguage = 'en'

    setCurrentLanguage(country, lang) {
        if (lang !== 'EN' && lang !== undefined) {
            this.currentLanguage = get(ReCaptchaLanguage, `${country}`, 'en')

            return
        }
        this.currentLanguage = 'en'
        return
    }

    getCurrentLanguage() {
        return this.currentLanguage
    }
}
const storeReCaptcha = new StoreReCaptcha()
export default storeReCaptcha

/*
 * language value from https://developers.google.com/recaptcha/docs/language
 **/
const ReCaptchaLanguage = {
    [K.Hongkong]: 'zh-HK',
    [K.Taiwan]: 'zh-TW',
    [K.Morocco]: 'fr',
    [K.Japan]: 'ja',
    [K.Turkiye]: 'tr',
    [K.Thailand]: 'th',
    [K.Jordan]: 'ar',
}

autorun(() => {
    if (storeReCaptcha.currentLanguage) {
        const reCaptchaIframe = window.document.querySelector('iframe[title="reCAPTCHA"]')
        if (reCaptchaIframe) {
            reCaptchaIframe.src = reCaptchaIframe.src.replace(/hl=.*?&/, `hl=${storeReCaptcha.currentLanguage}&`)
        }
    }
})
