import React, { useState } from 'react'
import { Stack, Typography, Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import { DefaultButton } from 'Components/Elements/Forms'
import ShowError from './ShowError'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { ReactComponent as SuccessIcon } from '../Assets/successv2.svg'

const SendNow = observer(props => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const [value, setValue] = useState('1')
    const [isSuccess, setIsSuccess] = useState(false)

    const order = props.products

    const translate = useTranslate()

    const handleSubmit = async () => {
        setLoading(true)
        setErrorMessage([])
        try {
            const newData = {
                isSkipSubscriptions: value === '1' ? false : true,
                autoship_data: {
                    creator: order.creator,
                    currency: order.currency,
                    customer: order.customer,
                    dateCreated: order.dateCreated,
                    id: order.id,
                    isStaged: order.isStaged,
                    lines: order.lines,
                    market: order.market,
                    modificationDate: order.modificationDate,
                    recurrence: order.recurrence,
                    shipToAddress: order.shipToAddress,
                    shipToEmail: order.shipToEmail,
                    shipToName: order.shipToName,
                    shipToPhone: order.shipToPhone,
                    shippingMethod: order.shippingMethod,
                    status: order.status,
                    terms: order.terms,
                    transactions: order.transactions,
                    href: order.href
                }
            }

            const res = await FeelGreatAPI.SendNowSubscriptions(newData, StoreAuth.GetToken())

            if (res.data.success) {
                setIsSuccess(true)
            } else {
                throw new Error(res.data.error.error_message)
            }
        } catch (error) {
            console.error(error)
            if (error.response.status === 402) {
                setErrorMessage([error.response.data.error.error_message])
            } else if (error.response.status === 504) {
                setErrorMessage([error.response.data.message])
            } else { 
                if(error.response.data.error?.error_message){ 
                    setErrorMessage([error.response.data.error?.error_message])
                }else{
                    setErrorMessage([error.response.data.error.message])
                } 
            }
        } finally {
            setLoading(false)
        }
    }

    const handleChange = event => {
        setValue(event.target.value)
    }

    const getDateSuffix = day => {
        if (day >= 11 && day <= 13) {
            return 'th'
        }

        const lastDigit = day % 10

        switch (lastDigit) {
            case 1:
                return 'st'
            case 2:
                return 'nd'
            case 3:
                return 'rd'
            default:
                return 'th'
        }
    }

    const getDate = () => {
        const dayOfMonth = props.dateOfmonth
        const suffix = getDateSuffix(dayOfMonth)
        return dayOfMonth + suffix
    }

    const ShowSuccess = () => {
        return (
            <Stack gap="40px">
                <Typography variant="title2" textAlign="center">
                    <T>fg_confirmed</T>
                </Typography>

                <Stack flexDirection="row" justifyContent="center">
                    <SuccessIcon />
                </Stack>

                <Typography variant="caption5" textAlign="center">
                    {value === '1' ? translate('send_now_option_1').replace('{{date}}', getDate()) : translate('send_now_option_2')}
                </Typography>
            </Stack>
        )
    }

    return (
        <Stack gap="30px">
            {isSuccess ? (
                <ShowSuccess />
            ) : (
                <>
                    <Typography variant="title2">
                        <T>send_now_title</T>
                    </Typography>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                            sx={{
                                gap: '20px'
                            }}>
                            <FormControlLabel
                                value="1"
                                control={
                                    <Radio
                                        sx={{
                                            color: '#CDDDED',
                                            paddingTop: '0px',

                                            '&.Mui-checked': {
                                                color: '#5a8fc3'
                                            },

                                            '.MuiTouchRipple-root': {
                                                top: '-8px'
                                            },

                                            '& .MuiSvgIcon-root': {
                                                fontSize: 18
                                            }
                                        }}
                                        color="secondary"
                                    />
                                }
                                label={
                                    <Typography variant="caption4" color="#153862">
                                        {translate('send_now_option_1').replace('{{date}}', getDate())}
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                value="2"
                                control={
                                    <Radio
                                        sx={{
                                            color: '#CDDDED',
                                            paddingTop: '0px',

                                            '&.Mui-checked': {
                                                color: '#5a8fc3'
                                            },

                                            '.MuiTouchRipple-root': {
                                                top: '-8px'
                                            },

                                            '& .MuiSvgIcon-root': {
                                                fontSize: 18
                                            }
                                        }}
                                        color="secondary"
                                    />
                                }
                                label={
                                    <Typography variant="caption4" color="#153862">
                                        {translate('send_now_option_2')}
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                    </FormControl>

                    {errorMessage.length > 0 && <ShowError list={errorMessage} noTranslate={true} />}

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                        <Box onClick={() => props.handleClose()}>
                            <Typography variant="link" color="#000000" fontSize="16px">
                                <T>cancel</T>
                            </Typography>
                        </Box>
                        <DefaultButton
                            type="submit"
                            fullWidth={true}
                            titleText={translate('save')}
                            width="220px"
                            onClick={() => handleSubmit()}
                            isloading={loading.toString()}
                            fontSize="20px"
                            fontWeight="400"
                            disabled={loading}
                        />
                    </Box>
                </>
            )}
        </Stack>
    )
})

export default SendNow
