import { ThemeProvider } from '@mui/material'
import Subscriptions from 'Components/Elements/Subscriptions/SubscriptionsWrapper'
import { observer } from 'mobx-react-lite'
import UserAccount from '../UserAccount'
import { storeElement } from 'GlobalStores/StoreElement'
import { MainTemplate } from 'Components/Template/MainTemplate'

const SubscriptionsPage = observer(() => {
    return (
        <ThemeProvider theme={storeElement.theme}>
            {storeElement.dynamicWidth <= 834 ? (
                <Subscriptions />
            ) : (
                <UserAccount>
                    <Subscriptions />
                </UserAccount>
            )}
        </ThemeProvider>
    )
})

export default SubscriptionsPage
