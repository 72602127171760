import { ThemeProvider } from '@mui/material'
import Invite from 'Components/Elements/Invite/Invite'
import { storeElement } from 'GlobalStores/StoreElement'
import { observer } from 'mobx-react-lite'
import UserAccount from '../UserAccount'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { InviteWrapperMobileLayout } from 'Components/Elements/Invite/InviteWrapper'
import CustomReferral from 'Components/Elements/Invite/ReferralLinks/CustomReferral/CustomReferral'
import SharingSocial from 'Components/Elements/Invite/ReferralLinks/SharingSocial/SharingSocial'

const InvitePage = observer(() => {
    return (
        <ThemeProvider theme={storeElement.theme}>
            {storeElement.dynamicWidth <= 834 ? (
                <InviteWrapperMobileLayout />
            ) : (
                <UserAccount secondChildren={<CustomReferral />} thirdChildren={<SharingSocial />}>
                    <Invite />
                </UserAccount>
            )}
        </ThemeProvider>
    )
})

export default InvitePage
