import { Box } from '@mui/material'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
            {value === index && <Box sx={{}}>{children}</Box>}
        </div>
    )
}

const TabElement = ({ children }) => {
    return (
        <Box
            sx={{
                width: '100%',
                '.MuiTabs-indicator': {
                    backgroundColor: '#99BCDF'
                },
                '.MuiButtonBase-root ': {
                    color: '#153862',
                    textTransform: 'none',
                    fontWeight: 400,
                    fontFamily: 'Poppins, NotoSansThai',
                    padding: '0 16px',
                    minWidth: '150px',
                    maxWidth: 'fit-content'
                },
                '.Mui-selected': {
                    fontWeight: 600
                },
                '.MuiTabs-flexContainer ': {
                    paddingBottom: '10px'
                },
                '.tab-bar': {
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginBottom: '40px'
                }
            }}>
            {children}
        </Box>
    )
}

TabElement.TabPanel = TabPanel

export default TabElement
