import React, { useEffect, useState } from 'react'
import { ReactComponent as SkipIcon } from '../Assets/skip.svg'
import { observer } from 'mobx-react-lite'
import { Stack, Typography, styled, Box } from '@mui/material'
import { DefaultButton } from '../../Forms'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as SpinnerIcon } from '../Assets/button-icon-spinner.svg'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { runInAction } from 'mobx'
import { getOSName } from 'Helpers/Bowser'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import ShowError from '../Elements/ShowError'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const SkipDeliveryMobile = observer(props => {
    const [loadingButton, setLoadingButton] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])

    const translate = useTranslate()
    const navigate = useNavigate()

    const order = StoreSubScriptions.GetSubscriptionsList()

    useEffect(() => {
        runInAction(() => {
            storeSettingsElement.cardTitle.titleStep1 = <T>subscriptions</T>
            storeSettingsElement.settingCard.padding = '40px 30px'
        })

        return () => {
            runInAction(() => {
                storeSettingsElement.cardTitle.titleStep1 = ''
                storeSettingsElement.settingCard.padding = ''
            })
        }
    }, [])

    const addMonths = (date, months) => {
        const outputDate = new Date(date)
        const d = outputDate.getDate()

        outputDate.setMonth(outputDate.getMonth() + +months)
        if (outputDate.getDate() !== d) {
            outputDate.setDate(0)
        }

        return outputDate
    }

    const handleSubmit = async () => {
        setLoadingButton(true)
        setErrorMessage([])
        try {
            const productItems = order.lines.items.map(data => {
                return {
                    item_code: data.item.id.unicity,
                    qty: data.quantity,
                    price: data.terms.priceEach
                }
            })

            const newData = {
                autoship_href: order.href,
                autoship_number: order.id.unicity.toString(),
                ba_id: order.customer.id.unicity.toString(),
                market: order.market,
                items: JSON.stringify(productItems),
                dateStarts: order.recurrence.dateStarts,
                shipToAddress: order.shipToAddress,
                fullname: order.shipToName.fullName,
                shipToPhone: order.shipToPhone,
                shipToEmail: order.shipToEmail,
                shippingMethod_href: order.shippingMethod.href,
                transactions: order.transactions,
                medium: 'Internet',
                agent: 'uFeelGreat',
                platform: getOSName()
            }
            let res = await FeelGreatAPI.SkipDelivery(newData, StoreAuth.GetToken())

            if (res.data.success) {
                StoreSubScriptions.nextDateDelivery = res.data.create.recurrence.dateStarts
                navigate('/user/subscriptions/skip-delivery/skip-delivery-success')

                setLoadingButton(false)
            } else {
                setErrorMessage([res.data.error.message])
                setLoadingButton(false)
            }
        } catch (error) {
            console.log(error.message)
            setLoadingButton(false)
            setErrorMessage([error.message])
        }
    }

    const handleBack = () => {
        setLoading(true)
        setTimeout(() => {
            navigate('/user/subscriptions')
            setLoading(false)
        }, 1500)
    }

    const dateSkip = () => {
        const date = addMonths(order?.recurrence?.dateStarts, 1)
        const dateFormat = new Date(date).toLocaleDateString(StoreTranslate.CurrentLanguage() === 'EN' ? 'en-EN' : 'th-TH', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        })
        return dateFormat
    }
    return (
        <Box position="relative">
            {(loading || loadingButton) && <CardDisable />}
            <Stack justifyContent="center" flexDirection="row">
                <Typography variant="title3" textAlign="center">
                    <T>skip_next_delivery</T>
                </Typography>
            </Stack>

            <Stack alignItems={'center'} justifyContent={'center'} textAlign="center" mt="60px">
                <SkipIcon />

                <Box mt="40px" mb="40px">
                    <Typography variant="caption5" textAlign={'center'} color="#023764" mb="40px">
                        {translate('skip_delivery_content').replace('{{date}}', dateSkip())}
                    </Typography>
                </Box>

                {errorMessage.length > 0 && (
                    <Box mb="15px">
                        <ShowError list={errorMessage} noTranslate={true} />
                    </Box>
                )}

                <DefaultButton
                    type="submit"
                    fullWidth={true}
                    titleText={translate('confirm_skip_delivery')}
                    width="100%"
                    onClick={() => handleSubmit()}
                    isloading={loadingButton.toString()}
                    fontSize="20px"
                    fontWeight="400"
                />

                <Typography onClick={() => handleBack()} variant="link" color="#003B6F" fontWeight={500} mt="30px" height="30px">
                    {loading ? <ButtonIconSpinner /> : <T>cancel</T>}
                </Typography>
            </Stack>
        </Box>
    )
})

const CardDisable = styled(Box)`
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    opacity: 65%;
    border-radius: 12px;
    z-index: 1;
`

const ButtonIconSpinner = styled(SpinnerIcon)`
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    circle {
        stroke: #079ff4;
    }
    path {
        stroke: #c7ddfb;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`

export default SkipDeliveryMobile
