import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { Stack, Typography, Box, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactComponent as CloseIcon } from './Assets/close-icon.svg'
import { ReactComponent as FaceIcon } from './Assets/face-pink.svg'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'

const CustomDialog = observer(props => {
    const { openDialog, setOpenDialog, header, text, type } = props
    const translate = useTranslate()

    const handleClose = () => {
        setOpenDialog(false)
    }

    return (
        <>
            <WrapperDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                sx={{
                    '& .MuiDialog-container': {
                        '& .MuiPaper-root': {
                            width: '100%',
                            maxWidth: type === 'normal' ? '430px' : '324px',
                            borderRadius: '8px'
                        }
                    }
                }}>
                <TopSection>
                    <IconClose type={type} onClick={handleClose} className="pointer" />
                </TopSection>
                {type === 'normal' && (
                    <DialogContent>
                        <Stack gap="20px">
                            <Typography variant="title2">
                                <T>{header}</T>
                            </Typography>
                            <Typography variant="caption5">
                                <T>{text}</T>
                            </Typography>
                            <Typography variant="link3" onClick={handleClose}>
                                <T>go_back</T>
                            </Typography>
                        </Stack>
                    </DialogContent>
                )}
                {type === 'error' && (
                    <Stack>
                        <DialogContentError>
                            <FaceIcon />
                        </DialogContentError>
                        <Box
                            sx={{
                                padding: '40px 30px',
                                textAlign: 'center'
                            }}>
                            <Typography
                                variant="caption5"
                                dangerouslySetInnerHTML={{
                                    __html: translate(text)
                                }}></Typography>
                        </Box>
                    </Stack>
                )}
            </WrapperDialog>
        </>
    )
})

const TopSection = styled(Box)`
    position: absolute;
    top: 20px;
    right: 20px;

    .pointer {
        cursor: pointer;
    }
`
const WrapperDialog = styled(Dialog)`
    .MuiDialogContent-root {
        padding: 40px 30px;
        border-top: none;
    }
`

const DialogContentError = styled(Box)`
    display: flex;
    justify-content: center;
    background: #ff6187;
    height: 152px;
    align-items: center;
`
const IconClose = styled(CloseIcon)`
    path {
        stroke: ${props => (props.type === 'normal' ? '#003B6F' : '#ffffff')};
    }
`
export default CustomDialog
