import { makeAutoObservable, reaction, runInAction } from 'mobx'
import get from 'lodash/get'
import Customer from './Customer'
import StoreAuth from './StoreAuth'
import { isEqualText } from 'Helpers/StringHelpers'

class StoreUser {
    customerHydraPayload = null
    userData = null
    located = null
    commission = null
    lsb = null
    acl = null
    currentProfileData = null
    baStatus = ''
    password = ''
    baId = ''

    constructor() {
        makeAutoObservable(this)

        reaction(
            () => this.userData,
            (newValue, prevValue, disposer) => {
                if (prevValue === null && newValue) {
                    runInAction(() => {
                        if (get(this.userData, 'id.unicity', null) !== get(this.currentProfileData, 'id.unicity', null)) {
                            this.currentProfileData = new Customer(this.userData)
                            disposer.dispose()
                        }
                    })
                }
            }
        )
    }

   async SetCustomerHydraPayload(data){
        this.customerHydraPayload = data
        return true
    }

    async GetCustomerHydraPayload(){
        return this.customerHydraPayload
    }

    

    /**
     * @returns {Customer} Customer
     */
    CustomerData() {
        if (!this.currentProfileData) {
            this.currentProfileData = new Customer(this.userData)
        }
        return this.currentProfileData
    }

    Acl() {
        return this.userData.acl
    }

    setUserData(value) {
        if (value.profile.id) {
            this.userData = value.profile
            this.commission = value.commission
            this.lsb = value.lsb
            this.located = value.located
            this.currentProfileData = new Customer(value.profile)
        }
    }

    reset() {
        this.userData = null
        this.located = null
        this.commission = null
        this.lsb = null
    }

    isStatusE() {
        return StoreAuth.ShortStatus() === 'E' && this.isStatusL() === false
    }

    isStatusL() {
        return isEqualText(get(this.getUserData(), 'employmentDetails.employerName', ''), 'Performance Labs')
    }

    isEnabledPV() {
        return StoreAuth.IsShowPv()
    }

    isStatusP() {
        return StoreAuth.ShortStatus() === 'P'
    }

    setProfilePicture(response) {
        this.userData.profilePicture = response
    }

    updateUserData({ emailAddress, mobilePhone }) {
        this.userData = { ...this.userData, email: emailAddress, mobilePhone: mobilePhone }
    }

    CustomerType(distType, status) {
        return GetCustomerType(distType, status)
    }

    GetBaStatus() {
        const baStatus = this.baStatus ? this.baStatus : this.userData ? this.CustomerType(this.userData.type, this.userData.status).short : null
        return baStatus
    }
}

function GetCustomerType(distType, status) {
    let type = {
        short: '',
        full: ''
    }
    Object.keys(CustomerType).map(key => {
        if (CustomerType[key].api.type.toLowerCase() === distType.toLowerCase() && CustomerType[key].api.status === status) {
            type.short = key
            type.full = CustomerType[key].api.type
        }
        return key
    })

    return type
}

const CustomerType = {
    A: {
        description: 'Active',
        api: {
            type: 'Associate',
            status: 'Active',
            priceLevel: 'wholesale_price'
        }
    },
    B: {
        description: 'Pending',
        api: {
            type: 'Associate',
            status: 'Pending',
            priceLevel: 'wholesale_price'
        }
    },
    C: {
        description: 'Customer',
        api: {
            type: 'Customer',
            status: 'Active',
            priceLevel: 'retail_price'
        }
    },
    D: {
        description: 'Balance (CHE, DEU), Defective (JP)',
        api: {
            type: 'Associate',
            status: 'Defective',
            priceLevel: null
        }
    },
    E: {
        description: 'Employee',
        api: {
            type: 'Employee',
            status: 'Active',
            priceLevel: 'employee_price'
        }
    },
    F: {
        description: 'Family Pricing',
        api: {
            type: 'Family',
            status: 'Active',
            priceLevel: 'employee_price'
        }
    },
    H: {
        description: 'Wholesale Customer',
        api: {
            type: 'WholesaleCustomer',
            status: 'Active',
            priceLevel: 'wholesale_price'
        }
    },
    L: {
        description: 'Performance Labs',
        api: {
            type: 'Performance Labs',
            status: 'Active',
            priceLevel: 'wholesale_price'
        }
    },
    M: {
        description: 'Member',
        api: {
            type: 'Member',
            status: 'Active',
            priceLevel: 'wholesale_price'
        }
    },
    P: {
        description: 'Preferred Customer',
        api: {
            type: 'PreferredCustomer',
            status: 'Active',
            priceLevel: 'preferred_price'
        }
    },
    R: {
        description: 'Retired',
        api: {
            type: 'Associate',
            status: 'Retired',
            priceLevel: null
        }
    },
    S: {
        description: 'Suspended',
        api: {
            type: 'Associate',
            status: 'Suspended',
            priceLevel: null
        }
    },
    T: {
        description: 'Terminated',
        api: {
            type: 'Associate',
            status: 'Terminated',
            priceLevel: null
        }
    }
}
const storeUser = new StoreUser()
export default storeUser
