import { Box, createTheme, Divider, Stack, styled, ThemeProvider, Typography, Modal, SvgIcon } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import StoreAuth from 'GlobalStores/User/StoreAuth'

import { DefaultButton, DefaultInput } from '../Forms'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import _, { get, isEmpty } from 'lodash'
import { storeElement } from 'GlobalStores/StoreElement'
import Errors from '../Errors/Errors'
import CloseIcon from '@mui/icons-material/Close'
import StoreReferral from 'GlobalStores/Referral/StoreReferral'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import { toJS } from 'mobx'
import storeCredit from 'GlobalStores/StoreCredit'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import ReCaptcha from '../ReCaptcha/ReCaptcha'
import storeReferral from 'Components/Pages/Referral/Thailand/Stores/StoreReferral'
import { NoReferralContact } from 'Components/Pages/Referral/Thailand/Components/ModalNoReferral'
import { Initialize } from 'Services/Cores/Initialize'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import storeAuth from 'GlobalStores/User/StoreAuth'
import ReferralLoginWrapper from 'Components/Pages/Referral/ReferralLoginWrapper'

const { observer } = require('mobx-react-lite')

const Login = observer(props => {
    const { showFooter = true, canSignup = true } = props

    // (_.get(pageConfig, 'signUp.disable'))
    const translate = useTranslate()
    const navigate = useNavigate()
    const [loadingLogin, setLoadingLogin] = useState(false)
    const [showLoginFooter, setShowLoginFooter] = useState(showFooter)
    const [loginFormError, setLoginFormError] = useState([])
    const [reCaptchaVerify, setReCaptchaVerify] = useState(false)
    const [showContactReferral, setShowContactReferral] = useState(false)

    const location = useLocation()

    const validationSchema = yup.object({
        unicityID: yup.string().required(translate('unicity_id_required')),
        password: yup.string().min(4, translate('minimum_four_char_password')).required(translate('password_is_required')),
        keepLoggedIn: yup.boolean()
    })

    useEffect(() => {
        storeFeelGreat.loginFrom = 'referral'
    }, [location.pathname])

    const formik = useFormik({
        initialValues: {
            unicityID: '',
            password: '',
            keepLoggedIn: false
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setErrors }) => {
            try {
                StoreAuth.loadingLogin = true
                const response = await StoreAuth.Login(values)
                await Initialize(true)

                StoreAuth.allowCredit = response.allowCredit
                let isHasCredit = false
                Object.keys(response.allowCredit).map((v, k) => {
                    if (v === 'enable' && response.allowCredit[v] === true) {
                        StoreAuth.allowCredit = response.allowCredit
                        StoreAuth.allowCredit.enable = true
                        isHasCredit = true
                    }
                    return ''
                })

                if (isHasCredit === true) {
                    storeCredit.Init({ id: StoreAuth.GetId(), token: StoreAuth.GetToken() })
                }

                StoreCheckout.SetUseMemberPrice(true)
                StoreReferral.reset()

                if (StoreAuth.allowCredit?.enable) {
                    storeCredit.Init({ id: StoreAuth.GetId(), token: StoreAuth.GetToken() })
                }

                localStorage.removeItem('from-logout')
                if (props.onCheckout && !StoreCheckout.GetIsLoginOnly()) {
                    props.onCheckout()
                } else if (['/checkout/create-account'].includes(location.pathname)) {
                    StoreLoader.SetLoadFormatApi(true)
                    navigate(`/checkout/shipping${location.search}`)
                } else {
                    if (location.pathname !== '/products') {
                        navigate('/user')
                    }
                }
                setTimeout(() => {
                    StoreAuth.loadingLogin = false
                }, 500)
            } catch (e) {
                if (get(e, 'response.data.error.body.error.code') === 401) {
                    setLoginFormError([translate('login_or_password_incorrect')])
                    setErrors({ password: translate('login_or_password_incorrect') })
                } else {
                    if (e.message && e.message === 'user_status_not_allowed') {
                        setLoginFormError([translate(e.message)])
                        setErrors({
                            password: translate(e.message)
                        })
                    } else {
                        setLoginFormError([get(e, 'response.data.displayError.message', '') || get(e, 'response.data.message', 'Server under maintain!')])
                        setErrors({
                            password: get(e, 'response.data.displayError.message', '') || get(e, 'response.data.message', 'Server under maintain!')
                        })
                    }
                }
                setTimeout(() => {
                    StoreAuth.loadingLogin = false
                }, 500)
            }
        }
    })

    useEffect(() => {
        if (showFooter) {
            let pageConfig = toJS(StoreCheckout.CheckOutInstance().GetPageConfig())
            if (_.get(pageConfig, 'signUp.disable')) {
                setShowLoginFooter(false)
            }
        }

        if (storeReferral.GetCheckedReferralParam() === true && !storeReferral.HasReferral()) {
            storeReferral.SetCheckedReferralParam(false)
        }
        if (!storeReferral.HasReferral() && !localStorage.getItem('from-logout')) {
            storeReferral.SetCheckedReferralParam(false)
            setShowContactReferral(true)
        } else {
            setShowContactReferral(false)
        }
    }, [])

    useEffect(() => {
        let error = []
        if (formik.touched.unicityID && Boolean(formik.errors.unicityID)) {
            error.push(formik.errors.unicityID)
        }

        if (formik.touched.password && Boolean(formik.errors.password)) {
            error.push(formik.errors.password)
        }

        setLoginFormError(error)
    }, [formik.errors, formik.touched])

    const handleReCaptCha = value => {
        setReCaptchaVerify(value)
    }

    return (
        <ThemeProvider theme={customTheme}>
            <ReferralLoginWrapper>
                <LoginWrapper
                    style={{
                        marginTop: showLoginFooter === false || !props.canSignup ? '0px' : '10px',
                        height: 'fit-content',
                        boxSizing: 'border-box'
                    }}
                    isloginpage={window.location.pathname.includes('login').toString()}>
                    <LoginStackWrapper
                        sx={
                            showContactReferral
                                ? { padding: '30px 0 40px 0 !important', borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }
                                : {
                                      borderBottomLeftRadius: {
                                          xs: '12px',
                                          sm: showLoginFooter === false || !props.canSignup ? '12px' : '0px',
                                          md: showLoginFooter === false || !props.canSignup ? '12px' : '0px'
                                      },
                                      borderBottomRightRadius: {
                                          xs: '12px',
                                          sm: showLoginFooter === false || !props.canSignup ? '12px' : '0px',
                                          md: showLoginFooter === false || !props.canSignup ? '12px' : '0px'
                                      },
                                      boxSizing: 'border-box'
                                  }
                        }
                        isloginpage={window.location.pathname.includes('login').toString()}>
                        {showFooter === false && (
                            <Box display={'flex'} justifyContent={'flex-end'}>
                                <SvgIcon
                                    component={CloseIcon}
                                    cursor={StoreAuth.loadingLogin ? 'not-allowed' : 'pointer'}
                                    onClick={() => {
                                        if (StoreAuth.loadingLogin) return
                                        props.setOpenLoginModal(false)
                                    }}
                                    opacity={'0.2'}
                                />
                            </Box>
                        )}
                        <Typography variant="h3" align="center">
                            <T>log_in</T>
                        </Typography>
                        <Stack
                            mt="30px"
                            mx="auto"
                            sx={{
                                width: {
                                    xs: '300px',
                                    sm: '370px',
                                    md: '370px'
                                }
                            }}>
                            <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                                <Box noValidate sx={{ maxWidth: '100%' }}>
                                    <DefaultInput
                                        autoComplete="off"
                                        id="unicityID"
                                        name="unicityID"
                                        label={translate('email_or_unicity_id')}
                                        value={formik.values.unicityID}
                                        onChange={formik.handleChange}
                                        type="email"
                                    />
                                </Box>
                                <Box noValidate sx={{ maxWidth: '100%' }} mt="10px">
                                    <DefaultInput
                                        id="password"
                                        name="password"
                                        label={translate('password')}
                                        type="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                    />
                                </Box>

                                <Stack mt="30px" display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ReCaptcha handleReCaptCha={handleReCaptCha} />
                                </Stack>

                                <Box mt="40px" width="100%" sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <DefaultButton
                                        disabled={!(formik.isValid && formik.dirty && !StoreAuth.loadingLogin && reCaptchaVerify)}
                                        type="submit"
                                        fullWidth={true}
                                        titleText={translate('log_in')}
                                        width="275px"
                                        isloading={StoreAuth.loadingLogin.toString()}
                                    />
                                </Box>
                                {loginFormError.length ? (
                                    <Stack mt="30px">
                                        <Errors list={loginFormError} />
                                    </Stack>
                                ) : null}
                            </form>

                            <Box
                                mt={storeElement.dynamicWidth < 700 ? '30px' : '20px'}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: showLoginFooter === false ? '20px' : '0px'
                                }}>
                                <Typography
                                    variant="link"
                                    onClick={() => {
                                        if (StoreAuth.loadingLogin) return
                                        navigate('/reset-password')
                                    }}
                                    sx={{ cursor: StoreAuth.loadingLogin ? 'not-allowed !important' : 'pointer' }}>
                                    <T>forgot_password</T>
                                </Typography>
                            </Box>
                        </Stack>
                    </LoginStackWrapper>
                    {showLoginFooter !== false && props.canSignup && (
                        <LoginStackWrapper
                            sx={{
                                padding: '30px 0px',
                                borderTopLeftRadius: '0px',
                                borderTopRightRadius: '0px',
                                backgroundColor: {
                                    xs: 'transparent',
                                    sm: '#fff',
                                    md: '#fff'
                                },
                                width: '100%'
                            }}
                            isloginpage={window.location.pathname.includes('login').toString()}>
                            <Divider
                                sx={{
                                    display: {
                                        xs: 'none',
                                        sm: 'block',
                                        md: 'block'
                                    },
                                    borderColor: 'rgba(153, 188, 223, 0.25)',
                                    marginBottom: '30px'
                                }}
                            />
                            <Stack
                                mx="auto"
                                sx={{
                                    width: '100%',
                                    display: 'inherit'
                                }}>
                                <Box flexDirection={'column'} display="flex">
                                    <Typography mb={'8px'} variant="caption">
                                        <T>dont_have_an_account</T>
                                    </Typography>
                                    <Typography
                                        variant="link"
                                        onClick={() => {
                                            if (StoreAuth.loadingLogin) return
                                            navigate('/sign-up')
                                        }}
                                        sx={{ cursor: StoreAuth.loadingLogin ? 'not-allowed !important' : 'pointer' }}>
                                        <T>sign_up</T>
                                    </Typography>
                                </Box>
                            </Stack>
                        </LoginStackWrapper>
                    )}
                    {showContactReferral && (
                        <LoginStackWrapper
                            sx={{
                                borderTopLeftRadius: '0px',
                                borderTopRightRadius: '0px',
                                backgroundColor: {
                                    xs: '#FFF1DC'
                                },
                                width: '100%',
                                padding: '0px'
                            }}>
                            <NoReferralContact />
                        </LoginStackWrapper>
                    )}
                </LoginWrapper>
            </ReferralLoginWrapper>
        </ThemeProvider>
    )
})

const LoginWrapper = styled(Stack)`
    @media screen and (min-width: 375px) {
        width: 100%;
    }

    @media screen and (min-width: 900px) {
        padding: ${({ isloginpage }) => (isloginpage ? '0px 10%' : '')};
    }

    @media screen and (min-width: 1200px) {
        padding: ${({ isloginpage }) => (isloginpage ? '0px 20%' : '')};
    }

    @media screen and (min-width: 1600px) {
        padding: ${({ isloginpage }) => (isloginpage ? '0px 30%' : '')};
    }
`

const LoginStackWrapper = styled(Stack)`
    padding: ${({ isloginpage }) => (isloginpage ? '40px 20px 40px 20px' : '20px 20px 20px 20px')};
    background-color: #fff;
    border-radius: 12px;
    height: fit-content;
    width: 100%;

    @media screen and (min-width: 1200px) {
        /* padding: 40px 30px 30px 30px; */
    }
`

const customTheme = createTheme({
    breakpoints: {
        values: {
            xs: 320, // phone
            sm: 700, // tablets
            md: 900 // small laptop
        }
    },
    palette: {
        primary: {
            main: '#1976d2'
        },
        secondary: {
            main: '#FFF'
        },
        button: {
            disabled: '#CDDDED'
        }
    },
    typography: {
        fontFamily: 'Poppins, NotoSansThai',
        fontStyle: 'normal',
        h3: {
            color: '#0B3B61',
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '32px'
        },
        label: {
            color: '#94A2B4',
            fontSize: 16,
            fontWeight: 500
        },
        link: {
            cursor: 'pointer',
            textAlign: 'center',
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            color: '#153862',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
            textDecoration: 'underline'
        },
        caption: {
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '17px',
            color: '#003764'
        }
    }
})

export default Login
