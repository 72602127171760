import StoreAuth from 'GlobalStores/User/StoreAuth'
import { useCallback, useEffect, useState } from 'react'
import { FeelGreatAPI } from './APIs'

export function useOrderHistory() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(null)

    const setOrderHistoryData = useCallback(data => setData(data), [])

    useEffect(() => {
        const fetchData = async() => {
            try {
                setLoading(true)
                if (data.length === 0) {
                    const response = await FeelGreatAPI.GetOrderHistory(StoreAuth.GetId(), StoreAuth.GetToken())
                    let items = []
                    response.data.ushop.orders.items.map(item => {
                        return items.push(item)
                    })
                    setOrderHistoryData(items)
                }
                setLoading(false)
            } catch (e) {
                setLoading(false)
                setErrors(e.response)
            }
        }
        fetchData()
    }, [data.length, setOrderHistoryData])

    return [data, errors, loading]
}
