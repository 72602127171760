import { styled, Typography } from '@mui/material'
import { Box } from '@mui/material'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

const DisplayTime = duration => {
    let m = Math.floor(duration / 60)
    let s = Math.floor(duration % 60)

    if (m.toString().length === 1) {
        m = '0' + m
    }

    if (s.toString().length === 1) {
        s = '0' + s
    }

    return m + ':' + s
}

const OtpBox = observer(props => {
    // const translate = useTranslate()
    const { resend, codeError, fullFilled, reqOtpRes } = props

    const [seconds, setSeconds] = useState(300)
    const [otp, setOtp] = useState('')

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
            } else {
                clearInterval(intervalId)
            }
        }, 1000)
        return () => clearInterval(intervalId)
    }, [seconds])

    useEffect(() => {
        resetOTP()
        setSeconds(300)
    }, [resend])

    const handleInput = e => {
        let hilight = false
        let nextID = 0
        let val = e.target.value
        let id = e.target.id
        let res = id.charAt(id.length - 1)

        if (val.length > 1) {
            val = val.charAt(0)
            e.target.value = val
            nextID = Number(res) + 1
        }

        if (val.length > 0) {
            nextID = Number(res) + 1
        } else {
            nextID = Number(res) - 1
            hilight = true
        }
        if (nextID > 0 && nextID <= 6) {
            let input = document.querySelector(`#digit${nextID}`)
            input.focus()
            if (hilight) input.select()
        }
        combineOTP()
    }

    useEffect(() => {
        if (otp.length === 6) {
            fullFilled(otp)
        } else {
            fullFilled(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otp])

    const combineOTP = () => {
        let newOtp = ''

        for (let index = 1; index <= 6; index++) {
            let elem = document.querySelector(`#digit${index}`)
            newOtp += elem.value
        }
        setOtp(newOtp)
    }

    const resetOTP = () => {
        for (let index = 1; index <= 6; index++) {
            let elem = document.querySelector(`#digit${index}`)
            elem.value = ''
        }
        setOtp('')
    }

    return (
        <OTPWrapper>
            <Typography variant="h6" textAlign={'center'} color={'#003B6F'} fontSize={'16px'} fontWeight={300}>
                <T>please_enter_your_otp_code</T>
                <b
                    className="countdown"
                    style={{
                        color: codeError || seconds === 0 ? '#ff6187' : (seconds <= 30) ? '#FFA001' : '#6FCF97'
                    }} 
                    > {DisplayTime(seconds)}
                </b>
            </Typography>
            <Typography variant="h6" textAlign={'center'} color={'#003B6F'} fontSize={'16px'} fontWeight={300}>
                <T>reference_code</T>: {reqOtpRes?.ref_id || ''}
            </Typography>

            <Box>
                <input
                    id={'digit1'}
                    className={`single-number-input ${codeError ? 'otp-code-error' : null}`}
                    onWheel={e => e.target.blur()}
                    type="number"
                    pattern="[0-9.]+"
                    maxlength="1"
                    onChange={e => handleInput(e)}
                />
                <input
                    id={'digit2'}
                    className={`single-number-input ${codeError ? 'otp-code-error' : null}`}
                    onWheel={e => e.target.blur()}
                    type="number"
                    pattern="[0-9.]+"
                    maxlength="1"
                    onChange={e => handleInput(e)}
                />
                <input
                    id={'digit3'}
                    className={`single-number-input ${codeError ? 'otp-code-error' : null}`}
                    onWheel={e => e.target.blur()}
                    type="number"
                    pattern="[0-9.]+"
                    maxlength="1"
                    onChange={e => handleInput(e)}
                />
                <input
                    id={'digit4'}
                    className={`single-number-input ${codeError ? 'otp-code-error' : null}`}
                    onWheel={e => e.target.blur()}
                    type="number"
                    pattern="[0-9.]+"
                    maxlength="1"
                    onChange={e => handleInput(e)}
                />
                <input
                    id={'digit5'}
                    className={`single-number-input ${codeError ? 'otp-code-error' : null}`}
                    onWheel={e => e.target.blur()}
                    type="number"
                    pattern="[0-9.]+"
                    maxlength="1"
                    onChange={e => handleInput(e)}
                />
                <input
                    id={'digit6'}
                    className={`single-number-input ${codeError ? 'otp-code-error' : null}`}
                    onWheel={e => e.target.blur()}
                    type="number"
                    pattern="[0-9.]+"
                    maxlength="1"
                    onChange={e => handleInput(e)}
                />
            </Box>

            {codeError && (
                <Typography mt={'20px'} variant="h6" textAlign={'center'} color={'#FF6187'} fontSize={'16px'} fontWeight={300}>
                    <T>error_otp_code</T>
                </Typography>
            )}
        </OTPWrapper>
    )
})

const OTPWrapper = styled(Box)`
    padding: 30px 27px 20px 27px;
    border: 1px solid #d1d9e2;
    border-radius: 8px;

    .countdown {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        color: #6fcf97;
    }

    .MuiBox-root {
        margin-top: 40px;
        display: flex;
        justify-content: space-evenly;
        @media screen and (max-width: 829px) {
            justify-content: space-between;
        }
    }

    .single-number-input {
        box-sizing: border-box;
        width: 55px;
        height: 55px;
        background: #ecf2f9;
        border-radius: 6px;
        border: 1px solid #ecf2f9;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #003b6f;
        :focus {
            outline: none;
        }
        /* Chrome, Safari, Edge, Opera */
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        [type='number'] {
            -moz-appearance: textfield;
        }
        @media screen and (max-width: 829px) {
            width: 40px;
            height: 40px;
        }
    }

    .otp-code-error {
        border: 1px solid #ffe7eb;
        background-color: #ffe7eb;
        color: #ff6187;
    }
`
export default OtpBox
