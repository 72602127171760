import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, styled, ThemeProvider, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { MainTemplate } from '../../Template/MainTemplate'
import { ReactComponent as Show } from './Assets/show-collapse.svg'
import { ReactComponent as Hide } from './Assets/hide-collapse.svg'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import CountryItem from './CountryItem'
import { useTranslateStaticComponents } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'

const CollapseSelectCountry = observer(({ value, setValue, handleClickChangeCountry }) => {
    const translateStaticComponents = useTranslateStaticComponents()
    return (
        <Stack>
            <ThemeProvider theme={storeElement.theme}>
                {StoreCountry.RawCountriesData.length > 0 && (
                    <ListWithIconsStyled>
                        {StoreCountry.RawCountriesData.map((item, itemKey) => {
                            return (
                                <AccordionStyled
                                    key={itemKey}
                                    expanded={value === itemKey}
                                    TransitionProps={{ unmountOnExit: true }}
                                    onChange={() => {
                                        if (value === itemKey) {
                                            setValue(null)
                                            return
                                        }
                                        setValue(itemKey)
                                    }}>
                                    <AccordionSummaryStyled aria-controls="panel1a-content" id="panel1a-header" isshow={value === itemKey ? 'true' : 'false'}>
                                        <Box alignItems="center" display="flex">
                                            <Typography variant="h2" key={itemKey} style={{ listStyle: 'none', fontSize: '16px' }}>
                                                {translateStaticComponents(item.continent)}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Hide
                                                style={{
                                                    transform: value === itemKey ? 'rotate(360deg)' : 'rotate(180deg)',
                                                    transition: 'all .1s'
                                                }}
                                            />
                                        </Box>
                                    </AccordionSummaryStyled>
                                    <AccordionDetailsStyled>
                                        <ListWithDescriptionStyled
                                            islast={itemKey !== StoreCountry.RawCountriesData.length - 1 ? 'true' : 'false'}
                                            isshow={value === itemKey ? 'true' : 'false'}
                                            className="list-item">
                                            {item.list.map((country, index) => {
                                                const mm = country.maintenance_mode
                                                if (mm.maintenance_mode) {
                                                    if (/developing/.test(mm.text.english) || /developing/.test(mm.text.native)) {
                                                        if (/fg.unicity.com/.test(window.location.hostname)) {
                                                            return false
                                                        }
                                                    }
                                                }
                                                return <CountryItem country={country} key={`country-${index}`} handleClick={handleClickChangeCountry} />
                                            })}
                                        </ListWithDescriptionStyled>
                                    </AccordionDetailsStyled>
                                </AccordionStyled>
                            )
                        })}
                    </ListWithIconsStyled>
                )}
            </ThemeProvider>
        </Stack>
    )
})

const AccordionStyled = styled(Accordion)`
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 40px;
    &.MuiPaper-root.MuiAccordion-root {
        ::before {
            background-color: transparent;
        }
        &.Mui-expanded {
            margin: 0px;
        }
    }
`

const AccordionSummaryStyled = styled(AccordionSummary)`
    &.MuiButtonBase-root {
        &.MuiAccordionSummary-root {
            padding: 0;
            min-height: auto;
            .MuiAccordionSummary-content {
                gap: 10px;
                margin: 0;
            }
        }
    }
`

const AccordionDetailsStyled = styled(AccordionDetails)`
    margin-bottom: 30px;
    &.MuiAccordionDetails-root {
        padding: 0;
    }
`

const ListWithIconsStyled = styled(Stack)`
    width: 100%;
    align-self: start;
    margin-top: 30px;
    margin-bottom: 40px;

    @media screen and (max-width: 820px) {
        width: 100%;
    }
`

const ListStyled = styled(Stack)`
    cursor: pointer;
    &.list-item {
        ${props =>
            props.isshow === 'true'
                ? `border-bottom: 1px solid #D1D9E2;
        padding-bottom: 20px;`
                : `margin-bottom: 30px;`}
        flex-flow: row;
        gap: 10px;
        align-items: center;
    }
`

const ListWithDescriptionStyled = styled(Box)`
    ${props => (props.isshow === 'true' ? `margin-bottom: 20px;` : ``)}
    &.list-item {
        align-items: center;
        display: grid;
        margin-top: 20px;
        border-top: 1px solid #d1d9e2;
        border-bottom: 1px solid #d1d9e2;
        padding: 20px 0px 20px;
        grid-template-columns: 1fr;
        gap: 20px;

        @media screen and (min-width: 600px) {
            grid-template-columns: 1fr 1fr;
        }
    }
`

export default CollapseSelectCountry
