import { createTheme, Grid, styled, ThemeProvider, Typography } from '@mui/material'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { ReactComponent as SpinnerIcon } from './Assets/Spinner.svg'

const SettingItem = observer(props => {
    const { width } = useSizeWindow()
    const { label, value, callback, isLoading = false } = props

    useEffect(() => {}, [])

    const handleChange = e => {
        e.preventDefault()
        callback()
    }

    return (
        <ThemeProvider theme={customTheme}>
            <Item
                item
                sx={{
                    padding: {
                        xs: '25px 0px',
                        md: '24px 0px'
                    },
                }}>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center'
                    }}>
                    <Grid item className="item-list">
                        <Typography variant="label" fontSize={width < 830 ? '16px' : '16px'}>
                            {label}
                        </Typography>
                        {!!value && (
                            <Typography variant="value" mt={'10px'} fontSize={width < 830 ? '14px' : '14px'}>
                                {value}
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start'
                        }}>
                        {isLoading ? (
                            <SpinnerIcon fontSize={'25px'} className="spinning" />
                        ) : (
                            <Typography
                                variant="link"
                                fontSize={width < 830 ? '14px' : '16px'}
                                fontWeight={500}
                                onClick={e => {
                                    handleChange(e)
                                }}
                                sx={{ cursor: 'pointer' }}>
                                <T>change</T>
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Item>
        </ThemeProvider>
    )
})

const Item = styled(Grid)`
    /* background: #ecf2f9; */
    /* border-radius: 8px; */
    border-bottom: 1px solid #CCDEEF; 
    display: flex;
    /* margin-bottom: 20px; */
    :first-of-type {
        padding: 0px 0px 24px 0px;
    }
    :last-of-type {
        border-bottom: none;
        margin-bottom: 0px;
        padding: 24px 0px 0px 0px;
    }
    /* height: 100px; */
    height: fit-content;
    .item-list {
        display: flex;
        flex-direction: column;
    }
    .spinning {
        animation: spin 1s infinite;
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

export const customTheme = createTheme({
    typography: {
        label: {
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: {
                xs: '16px',
                sm: '16px'
            },
            color: '#003B6F'
        },
        value: {
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: {
                xs: '14px',
                sm: '14px',
                md: '14px'
            },
            color: '#8699AF'
        },
        link: {
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: {
                xs: '14px',
                sm: '14px',
                md: '18px'
            },
            textDecoration: 'underline',
            color: '#5A8FC3',
            cursor: 'pointer, NotoSansThai'
        }
    }
})

export default SettingItem
