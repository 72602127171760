import React, { useState } from 'react'
import { Stack, Typography, Box, styled, Paper, ThemeProvider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'

import { DefaultButton } from 'Components/Elements/Forms'
import ShowError from './ShowError'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import ThemeSelector from 'Components/Elements/Checkout/ThemeSelector'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { getOSName } from 'Helpers/Bowser'
import ProvinceSelect from 'Components/Pages/Referral/Thailand/Components/Forms/ProvinceSelect/ProvinceSelect'
import MobileInput from 'Components/Pages/Referral/Thailand/Components/Forms/MobileInput/MobileInput'
import { storeElement } from 'GlobalStores/StoreElement'
import { FormSelector } from 'Components/Elements/Forms/FormSelector'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const ChangeAddress = observer(props => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const [isTranstate, setIsTranstate] = useState(false)

    const translate = useTranslate()

    const order = props.products

    const handleSubmit = async () => {
        setLoading(true)
        setErrorMessage([])
        try {
            const productItems = order.lines.items.map(data => {
                return {
                    item_code: data.item.id.unicity,
                    qty: data.quantity,
                    price: data.terms.priceEach
                }
            })

            let recurrence = order.recurrence
            if (order.recurrence.dateStarts) {
                recurrence.schedule.dateStarts = order.recurrence.dateStarts
                delete recurrence.dateStarts
            }

            const newData = {
                target: "feelGreatReferral",
                autoship_href: order.href,
                autoship_number: order.id.unicity.toString(),
                ba_id: order.customer.id.unicity.toString(),
                market: order.market,
                items: JSON.stringify(productItems),
                shippingMethod_href: order.shippingMethod.href,

                // firstname_en: GetShippingFormsValue('first_name'),
                // lastname_en: GetShippingFormsValue('last_name'),
                // address: GetShippingFormsValue('street_address'),
                // city: GetShippingFormsValue('city'),
                country: StoreCountry.Country2(),
                // state: GetShippingFormsValue('state'),
                // zip: GetShippingFormsValue('zip_code'),
                shipToPhone: GetShippingFormsValue('Mobile Number'),
                shipToEmail: GetShippingFormsValue('email_address'),

                shipment_options: 'delivery',
                recurrence: recurrence,
                transactions: order.transactions,
                medium: 'Internet',
                agent: 'uFeelGreat',
                platform: getOSName()
            }
            const formatType = 'shop'
            StoreCheckout.CheckOutInstance().FormToFormat(formatType, StoreCheckout.GetShippingForm())
            const formData = StoreCheckout.InitializeFormShippingData(formatType)

            Object.assign(newData, formData)

            let res = await FeelGreatAPI.EditSubscriptions(newData, StoreAuth.GetToken())

            if (res.data.success) {
                props.handleClose()
                props.handleGetOrder()
                setLoading(false)
            } else {
                const errorMessage = typeof res.data.Message_v2 === 'object' ? res.data.Message_v2 : res.data.Message_v2

                setErrorMessage(errorMessage)
                setIsTranstate(false)
                setTimeout(() => {
                    setLoading(false)
                    const objDiv = document.getElementById('change-address')
                    objDiv.scrollTop = objDiv.scrollHeight // set the scroll position to the bottom
                }, 500) // wait for 500 milliseconds before scrolling
            }
        } catch (error) {
            console.error(error)
            setIsTranstate(true)
            if (error.response.status > 500) {
                setErrorMessage([error.response.data.message])
            } else {
                setErrorMessage([error.response.data.message.error.message])
            }

            setTimeout(() => {
                setLoading(false)
                const objDiv = document.getElementById('change-address')
                objDiv.scrollTop = objDiv.scrollHeight // set the scroll position to the bottom
            }, 500) // wait for 500 milliseconds before scrolling
        }
    }

    const GetShippingFormsValue = key => {
        const card = StoreCheckout.shippingForms.find(item => item.label === key)
        if (card) {
            return card.value
        }
        return ''
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            <ShippingAddressWrapper gap="20px" language={StoreTranslate.CurrentLanguage()}>
                <Typography variant="title2">
                    <T>change_shipping_address</T>
                </Typography>

                <Wrapper id="change-address">
                    <Stack>
                        <ThemeSelector countryCapitalize={StoreCountry.CountryCapitalize()}>
                            <PaperStyled className={`shipping-address--dynamic-form-change-address ${StoreAuth.IsAuthorized() ? 'shipping-address' : ''}`}>
                                {!ObjectHelpers.isEmpty(StoreCheckout.GetShippingForm()) &&
                                    StoreCheckout.GetShippingForm().map(item => {
                                        if (StoreAuth.GetId()) {
                                            if (item.key === 'password' || item.key === 'confirm_password') {
                                                return false
                                            }
                                        }

                                        item.value = GetShippingFormsValue(item.label)

                                        if (item.key === 'province') {
                                            return (
                                                <Box boxSizing={'border-box'} className="Input" key={item.key}>
                                                    <ProvinceSelect label={translate(item.key)} value={item.value.toUpperCase()} />
                                                </Box>
                                            )
                                        } else if (item.key === 'phone') {
                                            return (
                                                <Box boxSizing={'border-box'} className="Input" key={item.key}>
                                                    <MobileInput
                                                        style={{ fontSize: '14px' }}
                                                        currentValue={item.value}
                                                        label={translate(item.key)}
                                                        onChange={value => {
                                                            StoreCheckout.SetCheckoutFormValue(item.key, value)
                                                        }}
                                                    />
                                                </Box>
                                            )
                                        }

                                        return (
                                            <Box boxSizing={'border-box'} mb={1} className="Input" key={item.key}>
                                                {FormSelector(translate, item)}
                                            </Box>
                                        )
                                    })}
                            </PaperStyled>
                        </ThemeSelector>
                    </Stack>
                    {errorMessage.length > 0 && <ShowError list={errorMessage} noTranslate={isTranstate} />}
                </Wrapper>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                    <Box onClick={() => props.handleClose()}>
                        <Typography variant="link" color="#000000" fontSize="16px">
                            <T>cancel</T>
                        </Typography>
                    </Box>
                    <DefaultButton
                        type="submit"
                        fullWidth={true}
                        titleText={translate('save')}
                        width="220px"
                        onClick={() => handleSubmit()}
                        isloading={loading.toString()}
                        fontSize="20px"
                        fontWeight="400"
                        disabled={loading}
                    />
                </Box>
            </ShippingAddressWrapper>
        </ThemeProvider>
    )
})

const ShippingAddressWrapper = styled(Stack)`
    .MuiTypography-root {
        font-family: ${props => (props.language === 'EN' ? 'Poppins' : 'NotoSansThai')} !important;
        &.MuiTypography-label {
            font-size: 14px !important;
            font-weight: 400;
        }
    }

    .MuiInputBase-root {
        > input {
            font-family: ${props => (props.language === 'EN' ? 'Poppins' : 'NotoSansThai')} !important;
            font-size: 16px !important;
            color: #000000;
        }
        > input:not(:disabled) {
            border: 1px solid #ccdeef;
        }
    }

    .country-box {
        border: 1px solid #ccdeef !important;
    }
`

const PaperStyled = styled(Paper)`
    justify-content: space-between;
    border-radius: 0 !important;
    box-shadow: none !important;
`

const Wrapper = styled(Box)`
    overflow: scroll;
    height: 550px;
    scroll-behavior: smooth;
    @media (max-width: 428px) {
        height: 550px;
    }

    @media (max-width: 390px) {
        height: 400px;
    }

    @media (min-width: 1620px) {
        height: 600px;
    }
`

export default ChangeAddress
