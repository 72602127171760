import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const TriggerAlwaysToTop = () => {
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    return <div></div>
}

export default TriggerAlwaysToTop