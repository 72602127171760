import { Checkbox } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactComponent as CheckeBoxOutlineIcon } from './Assets/pdpa-blank.svg'
import { ReactComponent as CheckedBoxIcon } from './Assets/pdpa-checked.svg'
import { ReactComponent as CorrectIcon } from './Assets/correct.svg'

const CustomCheckedBox = props => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '22px',
                height: '22px'
            }}>
            <CheckedBoxIcon style={{ position: 'absolute' }} />
            <CorrectIcon style={{ position: 'absolute' }} />
        </div>
    )
}
const CustomCheckboxOutLine = props => {
    return (
        <div style={{ position: 'relative', width: '22px', height: '22px' }}>
            <CheckeBoxOutlineIcon style={{ position: 'absolute' }} />
        </div>
    )
}

const PdpaCheckboxSVG = observer(props => {
    return <Checkbox {...props} checkedIcon={<CustomCheckedBox />} icon={<CustomCheckboxOutLine />} />
})

export default PdpaCheckboxSVG
