import { observer } from 'mobx-react-lite'

import SettingCard from '../UserAccountMenu/SettingCard'
import { Stack } from '@mui/material'
import Breadcrumb from '../UserAccountMenu/Breadcrumb'
import Invite from './Invite'
import MobileLayoutWrapper from '../UserAccountMenu/MobileLayoutWrapper'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'
import CustomReferral from './ReferralLinks/CustomReferral/CustomReferral'
import SharingSocial from './ReferralLinks/SharingSocial/SharingSocial'

const OrderHistoryWrapper = observer(props => {
    const { width } = useSizeWindow()

    return (
        <>
            {width > 834 ? (
                <SettingCard
                    title={storeSettingsElement.settingCard.title || ''}
                    titleAlign={storeSettingsElement.settingCard.titleAlign || 'center'}
                    display="block">
                    <Invite />
                </SettingCard>
            ) : (
                <InviteWrapperMobileLayout />
            )}
        </>
    )
})

export const InviteWrapperMobileLayout = () => {
    return (
        <MobileLayoutWrapper>
            <Stack width={'100%'}>
                <Breadcrumb />
                <SettingCard
                    title={storeSettingsElement.settingCard.title || ''}
                    titleAlign={storeSettingsElement.settingCard.titleAlign || 'left'}
                    display="block">
                    <Invite />
                </SettingCard>
                <SettingCard
                    margin={'16px 0 0 0'} title={storeSettingsElement.settingCard.title || ''}
                    titleAlign={storeSettingsElement.settingCard.titleAlign || 'left'}
                    display="block">
                    <CustomReferral />
                </SettingCard>
                <SettingCard
                    margin={'16px 0 0 0'} title={storeSettingsElement.settingCard.title || ''}
                    titleAlign={storeSettingsElement.settingCard.titleAlign || 'left'}
                    display="block">
                    <SharingSocial />
                </SettingCard>
            </Stack>
        </MobileLayoutWrapper>
    )
}

export default OrderHistoryWrapper
