const { styled, Dialog } = require("@mui/material")

export const MoroccoDialogWrapper = styled(Dialog)(({ theme }) => {
    return {
        ".MuiDialog-paper": {
            boxSizing: "border-box", 
            width: '345px !important',
            ".MuiDialogTitle-root": {
                textAlign: "center", 
            },
            ".MuiDialogContent-root": {
                margin: "0 20px !important",
                borderTop: "1px solid #E5EEF7",
                ".picker-container": {
                    padding: "0px !important",
                },
                ".picker-highlight": {
                    border: "0px !important",
                    '&::before': {
                        backgroundColor: "transparent",
                    },
                    '&::after': {
                        backgroundColor: "transparent",
                    }
                }
            }
        } 
    }
})