
import { Radio } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReactComponent as CheckBoxCircleIcon } from './CheckBox-Circle.svg'
import { ReactComponent as CheckBoxCircleCheckedIcon } from './CheckBox-Circle-Checked.svg'

const RadioButton = observer((props) => {

    return <Radio {...props} icon={<CheckBoxCircleIcon />} checkedIcon={<CheckBoxCircleCheckedIcon />} />
})


export default RadioButton;