import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PickerColumn from './PickerColumn'
import styled from '@emotion/styled'
import { Stack } from '@mui/material'

const Picker = ({ optionGroups, valueGroups, onChange, itemHeight = 36, height = 216, propsValue }) => {
    const renderInner = () => {
        const highlightStyle = {
            height: itemHeight,
            marginTop: 0
        }
        let index = 1000
        const columnNodes = []
        for (let name in optionGroups) {
            columnNodes.push(
                <PickerColumn
                    tabIndex={index++}
                    key={name}
                    name={name}
                    options={optionGroups[name]}
                    value={valueGroups[name]}
                    itemHeight={itemHeight}
                    columnHeight={height}
                    onChange={onChange}
                    propsValue={propsValue}
                />
            )
        }
        return (
            <div className="picker-inner">
                {columnNodes}
                <div className="picker-highlight" style={highlightStyle}></div>
            </div>
        )
    }

    const style = {
        height: height
    }

    return (
        <PickerContainer className="picker-container" style={style}>
            {renderInner()}
        </PickerContainer>
    )
}

Picker.propTypes = {
    optionGroups: PropTypes.object.isRequired,
    valueGroups: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    itemHeight: PropTypes.number,
    height: PropTypes.number
}

const PickerContainer = styled.div`
    &.picker-container {
        touch-action: none;
        z-index: 10001;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;

        &,
        *,
        *:before,
        *:after {
            box-sizing: border-box;
        }

        .picker-inner {
            background: transparent;
            position: relative;
            display: flex;
            justify-content: center;
            height: 100%;
            padding: 0 20px;
            font-size: 1.2em;
            /* -webkit-mask-box-image: linear-gradient(to top, transparent, transparent 5%, white 20%, white 80%, transparent 95%, transparent); */
        }

        .picker-column {
            flex: 1 1;

            position: relative;

            max-height: 100%;

            overflow: hidden;
            text-align: center;
            cursor: ${props => (window.innerWidth < 768 ? 'default' : 'pointer')};

            .picker-scroller {
                transition: 300ms;
                transition-timing-function: ease-out;
                background: transparent !important;
            }

            .picker-item {
                font-family: 'NotoSansThai', Poppins;
                font-size: 1.2em;
                position: relative;
                background: transparent !important;
                padding: 0 10px;

                white-space: nowrap;
                color: #999999;
                overflow: hidden;
                text-overflow: ellipsis;
                user-select: none;
                &.picker-item-selected {
                    color: #000;
                    font-weight: bold;
                }
            }
        }

        .picker-highlight {
            background: transparent !important;
            position: absolute;
            top: 50%;
            left: 0;
            border-top: 1px solid #d9d9d9;
            border-bottom: 1px solid #d9d9d9;
            width: 100%;

            pointer-events: none;

            &:before,
            &:after {
                content: ' ';
                position: absolute;
                left: 0;
                right: auto;

                display: block;
                width: 100%;
                height: 1px;

                background-color: #d9d9d9;
                transform: scaleY(0.5);
            }

            &:before {
                top: 0;
                bottom: auto;
            }

            &:after {
                bottom: 0;
                top: auto;
            }
        }
    }
`

export default Picker
