import loadable from '@loadable/component'
import { Stack, ThemeProvider } from '@mui/material'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { storeElement } from 'GlobalStores/StoreElement'

const ReferralCheckoutPage = () => {
    return (
        <ThemeProvider theme={storeElement.theme}>
            <Stack minHeight={'calc(100vh - 137px)'} bgcolor={'#d2d9e1'}>
                <DynamicCountry />
            </Stack>
        </ThemeProvider>
    )
}

const DynamicCountry = loadable(() => import(`./${storeCountry.CountryCapitalize()}/CheckoutPage`))

export default ReferralCheckoutPage
