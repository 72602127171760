import { observer } from 'mobx-react-lite'
import { Box, Stack } from '@mui/material'
import { ReactComponent as UnicityIcon } from '../Assets/unicity.svg'
import { omit } from 'lodash'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const Social = observer(({ data }) => {
    return (
        <Stack>
            <Box
                sx={{
                    marginBottom: {
                        xs: '20px',
                        sm: '41px',
                    },
                    display: {
                        xs: 'flex',
                        lg: 'block'
                    },
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <UnicityIcon />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: '32px',
                    alignItems: 'center',
                    justifyContent: {
                        xs: 'center',
                        lg: 'start'
                    }
                }}>
                {Object.keys(omit(data, ['isEnable'])).map((key, index) => {
                    if (data[key] === null) return false
                    return (
                        <Box
                            sx={{
                                cursor: 'pointer'
                            }}
                            key={`social-footer-${index}`}>
                            <a href={StoreTranslate.UseLanguage(data[key])} target="_blank" rel="noreferrer">
                                <img src={require(`../Assets/${key}.svg`)} key={`footer-social-${index}`} alt="Social icon" />
                            </a>
                        </Box>
                    )
                })}
            </Box>
        </Stack>
    )
})

export default Social
