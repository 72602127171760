import { ThemeProvider } from '@mui/material'
import { useState } from 'react'
import { useLayoutEffect } from 'react'

const ThemeSelector = ({ countryCapitalize, children }) => {
    const [theme, setTheme] = useState()

    useLayoutEffect(() => {
        if (!theme) {
            const themeName = `${countryCapitalize}FormTheme`
            import(`../Forms/Theme/${themeName}`)
                .then(module => {
                    setTheme(module.FormTheme)
                })
                .catch(() => {})
        }
    }, [countryCapitalize, theme])

    return <>{theme ? <ThemeProvider theme={theme}>{children}</ThemeProvider> : null}</>
}
export default ThemeSelector
