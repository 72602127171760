import { Box, IconButton, InputAdornment, styled, TextField, ThemeProvider, Typography } from '@mui/material'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { observer } from 'mobx-react-lite'
import { DefaultLabelTypo, DefaultInputStyled } from './InputsStyled'
import { useState } from 'react'
import { ReactComponent as PasswordOffIcon } from './Assets/password-off.svg'
import { ReactComponent as PasswordOnIcon } from './Assets/password-on.svg'
import { ReactComponent as SearchIcon } from './Assets/SearchIcon.svg'
import { ReactComponent as Dropdown } from './Assets/dropdown.svg'
import { isSomething } from 'Utils/Utils'
import { storeElement } from 'GlobalStores/StoreElement'
import ModalPassword from './ModalPassword/ModalPassword'

const DefaultInput = observer(props => {
    const { width } = useSizeWindow()
    const [showPassword, setShowPassword] = useState(false)
    const {
        //filled-error-helper-text  id should be unique
        label = '',
        value = '',
        fullWidth = true,
        onChange,
        onBlur = () => {},
        type = 'text',
        error = true,
        helpertext = '',
        paddingleft = 16,
        paddingright = 10,
        helpertextcardtype,
        typekey,
        onKeyUp,
        placeholder = '',
        endAdornmentToggle = () => {},
        sx = {},
        endAdornment = null,
        showAdornmentLabel = null,
        item = {}
    } = props

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const handleToggleZipSearch = () => {
        endAdornmentToggle(true)
    }
    // SearchIcon

    const getEndAdornment = (type = null, endAdornmentCustomIcon) => {
        let endAdornment = <></>
        switch (type) {
            case 'password':
                endAdornment = (
                    <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                            {!showPassword ? <PasswordOffIcon /> : <PasswordOnIcon />}
                        </IconButton>
                    </InputAdornment>
                )
                break
            case 'zipSearch':
                endAdornment = (
                    <InputAdornment position="end">
                        <IconButton onClick={handleToggleZipSearch} edge="end">
                            <SearchIcon width={'18px'} height={'18px'} />
                        </IconButton>
                    </InputAdornment>
                )
                break
            case 'province':
                endAdornment = (
                    <InputAdornment position="end">
                        <IconButton edge="end">
                            <Dropdown width={'18px'} height={'18px'} />
                        </IconButton>
                    </InputAdornment>
                )
                break
            case 'custom':
                endAdornment = (
                    <InputAdornment position="end">
                        <IconButton edge="end">{endAdornmentCustomIcon}</IconButton>
                    </InputAdornment>
                )
                break
            default:
                endAdornment = null
                break
        }

        return endAdornment
    }

    const getRealType = (type = 'text') => {
        let realType = type
        switch (type) {
            case 'password':
                if (showPassword) {
                    realType = 'text'
                }
                break
            case 'zipSearch':
                realType = 'tel'
                break
            default:
                realType = type
                break
        }
        return realType
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Box width={fullWidth ? '100%' : 'auto'}>
                {isSomething(label) && (
                    <div style={{ 
                            display: showAdornmentLabel && 'flex', 
                            justifyContent: showAdornmentLabel && storeElement.dynamicWidth > 768 && 'space-between', 
                            marginBottom: '8px', 
                            paddingLeft: paddingleft, 
                            paddingRight: paddingright,
                        }}
                    >
                        <label>
                            <DefaultLabelTypo variant="label" style={{ color: 'rgba(21, 56, 98, 0.5)', fontFamily: 'Poppins, NotoSansThai', fontWeight: 300, paddingRight: showAdornmentLabel && storeElement.dynamicWidth < 768 && '10px' }}>
                                {label}
                            </DefaultLabelTypo>
                            {typekey === 'card_number' && helpertextcardtype && <br style={{ display: width < 883 ? 'block' : 'none' }} />}
                            <Typography
                                variant="label"
                                sx={{ color: '#D03C3C', marginLeft: width < 883 ? '' : '15px', display: width < 600 ? 'none' : 'inline' }}>
                                {helpertextcardtype}
                            </Typography>
                        </label>
                        {showAdornmentLabel && <ModalPassword paragraph={item.info} />}
                    </div>
                )}
                {props.InputProps ? (
                    <TextField
                        {...props}
                        color="#000000"
                        inputProps={{
                            'aria-autocomplete': 'none',
                            autoComplete: 'new-password',
                            spellCheck: 'false',
                            ...props.inputProps
                        }}
                    />
                ) : (
                    <DefaultInputStyled
                        {...props}
                        type={getRealType(type)}
                        error={error}
                        label={
                            <Typography variant="label" sx={{ color: 'rgba(21, 56, 98, 0.5)' }}>
                                {label}
                            </Typography>
                        }
                        value={value}
                        helpertext={helpertext}
                        variant="outlined"
                        fullWidth={fullWidth}
                        onKeyUp={onKeyUp}
                        onChange={onChange}
                        onBlur={onBlur}
                        inputlabelprops={{
                            shrink: true
                        }}
                        inputProps={{
                            'aria-autocomplete': 'none',
                            autoComplete: 'new-password',
                            spellCheck: 'false',
                            ...props.inputProps
                        }}
                        placeholder={placeholder}
                        sx={{
                            ...(['password', 'zipSearch', 'province'].includes(type) && {
                                '.MuiInputAdornment-root': {
                                    position: 'absolute',
                                    right: '15px',
                                    '.MuiSvgIcon-root': {
                                        width: '18px',
                                        height: '18px'
                                    }
                                },
                                '.MuiInputBase-input': {
                                    '::-ms-reveal': {
                                        display: 'none'
                                    }
                                }
                            }),
                            ...sx
                        }}
                        endAdornment={getEndAdornment(type) || (endAdornment ? getEndAdornment('custom', endAdornment) : null)}
                    />
                )}
                {helpertext && (
                    <div style={{ marginTop: '8px', paddingLeft: paddingleft }}>
                        <label>
                            <TextHeleperTypo variant="label">{helpertext}</TextHeleperTypo>
                        </label>
                    </div>
                )}
                <div style={{ marginTop: '8px', paddingLeft: paddingleft, display: width < 600 ? 'block' : 'none' }}>
                    <Typography variant="label" sx={{ color: '#D03C3C' }}>
                        {helpertextcardtype}
                    </Typography>
                </div>
            </Box>
        </ThemeProvider>
    )
})

// sx={
//     !!helpertext && {
//         '& .MuiInputBase-input': {
//             border: '1px solid #D03C3C;'
//         }
//     }
// }l

const TextHeleperTypo = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins, NotoSansThai',
    fontStyle: 12,
    color: '#D03C3C',
    fontWeight: 500
}))

export default DefaultInput
