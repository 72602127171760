import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { get, isEmpty, camelCase, delay } from 'lodash'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import Payment from './Checkout/Payment'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import StoreCredit from 'GlobalStores/StoreCredit'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { getOSName } from 'Helpers/Bowser'
import storeReferral from './StoreReferral'
import storeUser from 'GlobalStores/User/StoreUser'
import StoreReferralCart from './StoreReferralCart'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { PaymentAPIs } from './Checkout/PaymentServices/PaymentAPI'
import { ReferralThailandAPIs } from '../Services/ReferralThailandAPIs'
import storeCredit from './StoreCredit'
import storeAuth from 'GlobalStores/User/StoreAuth'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import storeFormat from 'GlobalStores/Format/StoreFormat'
import storeFormAddress from './StoreFormAddress'

class StoreCheckout {
    shippingMethod = 'delivery'

    translate = useTranslate()

    uuid = ''

    paymentMethod = ''

    creditCard = []

    isReadyToPlaceOrder = false

    paymentLoading = false

    paymentErrors = []

    PaymentStep = {
        payment: 0,
        createOrder: 0,
        autoship: -2,
        updateLog: 0
    }

    shippingMethods = [
        {
            id: 1,
            api_value: 'delivery',
            title: 'Delivery',
            dict_key: 'delivery',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Credit card',
                    value: 'creditcard',
                    dict_key: 'ref_creditcard',
                    for_status: [],
                    icon: 'creditcard-icon'
                },
                {
                    id: 2,
                    title: 'QR payment',
                    value: 'qr_payment',
                    dict_key: 'qr_payment',
                    descripion: {
                        title: '',
                        dict_key: 'qr_payment'
                    },
                    for_status: [],
                    icon: 'pp-icon'
                }
            ]
        }
    ]

    orderSummaryConfig = {
        subTotal: {
            display: true,
            dict_key: 'subtotal'
        },
        shipping: {
            display: true,
            dict_key: 'shipping'
        },
        productCredit: {
            display: true,
            dict_key: 'product_credit',
            icon: true,
            allowToUse: []
        },
        vat: {
            display: false,
            dict_key: 'vat',
            value: 0.2 // 20%
        }
    }

    requestTaxInvoice = false
    invoiceGovernmentId = ''
    invoiceTaxId = ''
    invoiceType = 'individual'
    invoiceForms = []
    invoiceFormsErrors = []
    invoiceInformation = {}
    invoiceIndividualForms = []
    invoiceCorporateForms = []
    showTaxInvoiceEdit = true

    GetInvoiceIndividualForms() {
        return this.invoiceIndividualForms
    }

    GetInvoiceCorporateForms() {
        return this.invoiceCorporateForms
    }

    GetInvoiceValue(key) {
        if (this.invoiceType === 'individual') {
            const item = this.invoiceIndividualForms.find(item => item.key === key)
            if (item) {
                return item.value
            }
        } else {
            const item = this.invoiceCorporateForms.find(item => item.key === key)
            if (item) {
                return item.value
            }
        }
    }

    SetInvoiceValue(key, value) {
        this.invoiceForms.map(item => {
            if (item.key === key) {
                item.value = value
            }
            return item
        })

        if (this.invoiceType === 'individual') {
            this.invoiceIndividualForms.map(item => {
                if (item.key === key) {
                    item.value = value
                }
                return item
            })
        } else {
            this.invoiceCorporateForms.map(item => {
                if (item.key === key) {
                    item.value = value
                }
                return item
            })
        }
    }

    GetTaxInvoiceValues() {
        let values = {}
        if (this.invoiceType === 'individual') {
            this.invoiceIndividualForms.map(item => {
                values[item.key] = item.value
                return item
            })
        } else {
            this.invoiceCorporateForms.map(item => {
                values[item.key] = item.value
                return item
            })
        }

        return values
    }
    //
    checkoutLoading = false
    checkoutPreparing = true
    dataReady = false

    SetUuid(value) {
        this.uuid = value
    }

    GetUuid() {
        return this.uuid
    }

    GetPaymentStep() {
        return this.PaymentStep
    }

    GetShippingMethod() {
        return this.shippingMethods.find(item => item.api_value === this.shippingMethod)
    }

    GetShippingMethods() {
        return this.shippingMethods
    }

    SetPaymentStep(key, value) {
        this.PaymentStep[key] = value
    }

    GetOrderSummaryConfig() {
        return this.orderSummaryConfig
    }

    SetPaymentMethod(value) {
        // if (!value) {
        //     this.GetCreditCardForm().map(item => {
        //         console.log('item', item)
        //         return this.SetCreditCard(item, '')
        //     })
        // }
        this.paymentMethod = value
    }

    GetPaymentMethod() {
        return this.paymentMethod
    }

    SetCreditCard(itemToSet, value) {
        this.creditCard.map(item => {
            if (item.key === itemToSet.key) {
                if (value.length === 0) {
                    itemToSet.value = ''
                } else {
                    if (item.args) {
                        const regx = get(itemToSet, 'args.regx', null)
                        let regexPass = false
                        if (regx) {
                            const regex = new RegExp(regx, 'i')
                            if (regex.test(value)) {
                                regexPass = true
                            }
                        } else {
                            regexPass = true
                        }

                        const maxLength = get(itemToSet, 'args.maxLength', null)
                        let maxLengthPass = false
                        if (maxLength) {
                            if (value.length <= maxLength) {
                                maxLengthPass = true
                            }
                        } else {
                            maxLengthPass = true
                        }

                        if (regexPass && maxLengthPass) {
                            item.value = value
                        }
                    } else if (itemToSet.key === 'exp_date') {
                        // item.value = moment(value).format('MM/YY')
                        item.value = [value.month, value.year.slice(2, value.year.length)].join('/')
                    } else {
                        item.value = value
                    }
                }
            }

            return item
        })
        this.IsReadyToPlaceOrder()
    }

    GetCreditCardForm() {
        return this.creditCard
    }

    CreditCardEncrypted = CreditCard => {
        let rearrangeExpDate = CreditCard.creditCardExpires.split('/')
        rearrangeExpDate = `${parseInt(rearrangeExpDate[1]) + 2000}-${rearrangeExpDate[0]}`

        return {
            type: 'AuthorizeAndCapture',
            method: 'CreditCard',
            amount: 'this.terms.total',
            methodDetails: {
                payer: CreditCard.payer,
                creditCardNumber: CreditCard.creditCardNumber,
                creditCardExpires: rearrangeExpDate,
                creditCardSecurityCode: CreditCard.creditCardSecurityCode
            },
            billToAddress: {
                country: storeCountry.Country2()
            }
        }
    }

    IsReadyToPlaceOrder() {
        let allFilled = []
        this.creditCard.map(item => {
            if (item.value.length > 0) {
                allFilled.push(true)
            } else {
                allFilled.push(false)
            }
            return item
        })

        this.isReadyToPlaceOrder = !ObjectHelpers.hasFalseElements(allFilled)
    }

    SetErrors(value) {
        runInAction(() => {
            this.paymentErrors = value
        })
    }

    /**
     *
     * @param {*} uuid
     * @param {object} detailPage { products, detailPage, isSubscribe, isEnroll, type}
     * @param {*} CreditCardEncrypted
     */
    async SubmitPayment({ uuid = '', detailPage, CreditCardEncrypted = {}, products, type = '' }) {
        try {
            if (StoreAuth.IsAuthorized()) {
                runInAction(() => {
                    StoreLoader.orderApiProgress.login = -1
                    StoreLoader.orderApiProgress.hydraCreateAccount = -1
                })
            }

            if (/onetime/.test(detailPage.shipment_packs)) {
                runInAction(() => {
                    StoreLoader.orderApiProgress.createAutoship = -1
                })
            }

            const Pay = new Payment(uuid, detailPage)
            storeCheckout.uuid = uuid

            // New prepareData ===            // Init what missing after improve
            Pay.hydra = {
                transactions: {}
            }

            const payments = await this.getPaymentData({
                type,
                CreditCardEncrypted,
                Pay
            })

            const customerType = get(storeUser.CustomerData(), 'userData.type', '')

            //////////////////////////////////////////////////////////////
            if (
                storeAuth.IsAuthorized() &&
                !get(storeUser.CustomerData(), 'userData.taxTerms.taxId', '').length &&
                storeReferralCheckout.requestTaxInvoice &&
                customerType === 'Member'
            ) {
                // New
                const formatTaxInvoice = get(storeFormat, 'data.ushop.u23.formatTaxInvoice', {})

                PaymentAPIs.CustomerInformationUpdate({
                    token: storeAuth.GetToken(),
                    customer_href: storeUser.CustomerData().userData.href,
                    customer_information: formatTaxInvoice
                })
            }
            //////////////////////////////////////////////////////////////

            const allowCreditEnable = StoreAuth.IsAllowCredit()

            if (allowCreditEnable && storeCredit.GetCreditData()?.creditUsed > 0) {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted, storeCredit.ProductCreditUsed()]
                }
            } else {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted]
                }
            }
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            runInAction(() => {
                setTimeout(() => {
                    StoreLoader.orderApiReady = true
                }, 1000)
            })

            runInAction(() => {
                StoreLoader.orderApiProgress.submitPayment = 1
            })

            let oneTime = await Pay.SubmitOneTimePayment({ payments, type }) // Main
            if (!oneTime) {
                return { success: false }
            }
            runInAction(() => {
                StoreLoader.orderApiProgress.submitPayment = 2
            })
            runInAction(() => {
                StoreLoader.orderApiProgress.createOrder = 1
            })
            await Pay.PreparePostData(oneTime) // New way assign data

            // Add payload to prepare

            // New PART
            const formatTaxInvoiceHydra = get(storeFormat, 'data.ushop.u23.formatTaxInvoiceHydra', {})
            const formDataHydra = get(oneTime, 'formatData.hydra', {})

            let getCreateCustomerForm = formDataHydra.customer
            if (!StoreAuth.isAuthorized && storeReferralCheckout.requestTaxInvoice) {
                delete getCreateCustomerForm['humanName']
                delete getCreateCustomerForm['mainAddress']

                getCreateCustomerForm = {
                    ...getCreateCustomerForm,
                    ...formatTaxInvoiceHydra,
                    shipToName: formDataHydra.shipToName,
                    shipToAddress: formDataHydra.shipToAddress
                }
            }

            await storeUser.SetCustomerHydraPayload(getCreateCustomerForm)

            if (type !== 'qr' && !StoreAuth.isAuthorized && oneTime.hasOwnProperty('is_processing_payment') && oneTime.is_processing_payment) {
                const formCheckout = storeCheckout.InitializeFormShippingData('enroll')

                // Get regis form to Hydra
                // when we add debug assume you want only hydra regis form

                // create customer by FE
                runInAction(() => {
                    StoreLoader.orderApiProgress.hydraCreateAccount = 1
                })
                const createCustomerRes = await PaymentAPIs.CreateCustomer(getCreateCustomerForm)
                runInAction(() => {
                    StoreLoader.orderApiProgress.hydraCreateAccount = 2
                })

                if (createCustomerRes.status === 201) {
                    const newBaID = createCustomerRes.data.id.unicity

                    runInAction(() => {
                        StoreLoader.orderApiProgress.login = 1
                    })
                    const loginResponse = await storeAuth.Login({ unicityID: newBaID, password: formCheckout.password })
                    runInAction(() => {
                        StoreLoader.orderApiProgress.login = 2
                    })

                    if (!uuid) {
                        uuid = new URLSearchParams(window.location.search).get('uuid')
                    }
                    // Patch UUID
                    const patchUUID = {
                        type: 'shop',
                        uuid: uuid,
                        ba_status: getCreateCustomerForm.type.charAt(0),
                        login_id: `${newBaID}`,
                        login_name: storeUser.CustomerData().FullName(),
                        login_name_native: storeUser.CustomerData().NativeName(),
                        token: loginResponse.loginData.token,
                        send_email: 'feelgreatWelcome',
                        is_allow_contact_by_unicity_or_sponsor: storeReferral.GetIsAcceptPdpa() ? 1 : 0
                    }

                    await PaymentAPIs.UpdateUUid(patchUUID, storeCountry.Country3())

                    // Patch UUID

                    const readyToCreateData = {
                        referenceNo: oneTime.referenceNo,
                        status: 'waiting',
                        type: 'feelGreatReferral',
                        token: `Bearer ${loginResponse.loginData.token}`,
                        ba_id: StoreAuth.GetId()
                    }

                    await PaymentAPIs.UpdateReadyToCreateOrder(readyToCreateData)
                } else {
                    // May be we need to retry to check it work
                }
                // Add create customer step using FE
            }

            runInAction(() => {
                StoreLoader.orderApiProgress.createOrder = 2
            })

            if (!oneTime) {
                return { success: false }
            }

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            if (Pay.autoship && type !== 'qr') {
                runInAction(() => {
                    StoreLoader.orderApiProgress.createAutoship = 1
                })
                await Pay.AutoshipTHA() // Just Autoship don't need to order to hydra again
                const patchUUID2 = {
                    token: StoreAuth.GetToken(),
                    send_email: 'feelGreatReferral',
                    autoship_number: Pay.autoshipNumber,
                    uuid: uuid,
                    selectAddressHistory: storeFormAddress.typeFormAddress,
                    products_email: get(Pay.email, 'products_email', '')
                }

                await PaymentAPIs.UpdateUUid(patchUUID2, storeCountry.Country3(), true)
                runInAction(() => {
                    StoreLoader.orderApiProgress.createAutoship = 2
                })
            }

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //TODO: Rechect to send autoship only
            // if (type !== 'qr') {
            //     runInAction(() => {
            //         StoreLoader.orderApiProgress.sendEmail = 1
            //     })
            // await Pay.SendEmailTHA()
            // }
            // runInAction(() => {
            //     StoreLoader.orderApiProgress.sendEmail = 2
            // })
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            let responseData = {
                success: true,
                payment_id: oneTime?.gateway_ref_id,
                order_number: oneTime.payment_id,
                payment_ref: oneTime?.paymentRef
            }

            if (detailPage.type === 'enroll') {
                responseData = {
                    ...responseData,
                    order_type: detailPage.type
                }
            }

            return responseData
        } catch (error) {
            console.error(error)
            await Promise.allSettled([FeelGreatAPI.sendEmailErrorToCs(uuid)])
            return { success: false }
        }
    }

    async getPaymentData({ type, CreditCardEncrypted, Pay }) {
        const totalPrice = get(storeFormat, 'data.ushop.u23.formatSummary.order_total', 0)
        if (totalPrice === 0) return []

        let data = []

        const allowCreditEnable = get(StoreAuth, 'allowCredit.enable', false)

        if (type === 'qr') {
            const qrPayment = {
                method: 'qrPayment',
                amount: Number(totalPrice)
            }
            data.push(qrPayment)

            if (allowCreditEnable && StoreCredit.GetCreditData()?.creditUsed > 0) {
                const productCredit = StoreCredit.GetCreditData()
                const useCredit = { method: 'eCredit', amount: productCredit.creditUsed }
                data.push(useCredit)
            }
        } else {
            // Prepare credit card data
            let creditCardData = {
                amount: Number(totalPrice)
            }

            if (!isEmpty(CreditCardEncrypted)) {
                creditCardData['method'] = camelCase(CreditCardEncrypted.method)
                creditCardData['payer'] = CreditCardEncrypted.methodDetails.payer
                creditCardData['creditCardNumber'] = CreditCardEncrypted.methodDetails.creditCardNumber
                creditCardData['creditCardSecurityCode'] = CreditCardEncrypted.methodDetails.creditCardSecurityCode
                creditCardData['creditCardExpires'] = CreditCardEncrypted.methodDetails.creditCardExpires
            }

            data.push(creditCardData)
            // END Prepare credit card data

            // When customer have E-Credit

            if (allowCreditEnable && StoreCredit.GetCreditData()?.creditUsed > 0) {
                const productCredit = StoreCredit.GetCreditData()
                const useCredit = { method: 'eCredit', amount: productCredit.creditUsed }
                data.push(useCredit)
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted, useCredit]
                }
            } else {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted]
                }
            }
        }

        return data
    }

    GetPaymentErrors() {
        const filteredError = this.paymentErrors.reduce((acc, current) => {
            return [...acc, this.translate(current)]
        }, [])
        return filteredError
    }

    async CreateUUID(moreStep = false) {
        try {
            runInAction(() => (this.dataReady = false))
            const periodResponse = await FeelGreatAPI.GetPeriod(storeCountry.Country2())

            const productItems = StoreReferralCart.getCartItems()

            const formatType = StoreAuth.IsAuthorized() ? 'shop' : 'enroll'
            const data = {
                type: formatType,
                items: JSON.stringify(productItems),
                period: periodResponse.data.entryPeriod,
                medium: 'Internet',
                agent: 'uFeelgreat',
                platform: getOSName()
            }

            if (storeCountry.CountryLowerCase() === 'singapore') {
                data['shipment_options'] = 'delivery'
            }

            if (storeReferral.HasReferral()) {
                data.referral_id = storeReferral.Customer().CustomerId.toString()
            }

            if (StoreAuth.IsAuthorized()) {
                data.ba_status = StoreAuth.userStatus
                data.login_id = StoreAuth.GetId().toString()
                data.login_name = storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'fullName')
                data.login_name_native = storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'nativeName')
                data.token = StoreAuth.GetToken()
            }

            const response = await PaymentAPIs.CreateUuid(data, storeCountry.Country3(), true)

            return {
                uuid: response.data.uuid,
                period: data.entryPeriod
            }
        } catch (e) {
            runInAction(() => {
                this.dataReady = true
            })
            console.error(e)
        }
    }

    async ContinueToPayment(uuid, form) {
        const response = {
            success: false
        }
        try {
            if (StoreAuth.IsAuthorized()) {
                const formatType = 'shop'
                storeCheckout.CheckOutInstance().FormToFormat(formatType, storeCheckout.GetShippingForm())

                const data = storeCheckout.InitializeFormShippingData(formatType)
                if (uuid) {
                    data.uuid = uuid
                }

                delete data.period
                data.type = 'shop'

                if (this.shippingMethod) {
                    data.shipment_options = this.shippingMethod.api_value
                }

                if (StoreAuth.IsAuthorized()) {
                    data['ba_status'] = StoreAuth.userStatus
                    data['login_id'] = StoreAuth.GetId().toString()
                    data['login_name'] = storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'fullName')
                    data['login_name_native'] = storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'nativeName')
                    data['token'] = StoreAuth.GetToken()
                    if (data.confirm_password) {
                        delete data.confirm_password
                    }
                    if (data['country']) {
                        delete data['country']
                    }

                    delete data.fullname
                    delete data.address
                    delete data.city
                    delete data.state
                    delete data.token
                }

                const updateResponse = await ReferralThailandAPIs.putHotFormatV2(data, storeCountry.Country3())
                if (updateResponse.data.success === false) {
                    response.success = false
                    runInAction(() => {
                        // this.checkoutErrors = updateResponse.data.Message
                        this.checkoutErrors = updateResponse.data.Message_v2
                    })
                } else {
                    this.checkoutErrors = []
                    response.success = true
                }
            }
        } catch (e) {
            if (StoreAuth.IsAuthorized()) {
                if (!StoreAuth.GetId()) {
                    window.location.reload()
                }
            } else {
                response.success = false
                runInAction(() => (this.dataReady = true))
                console.log(e)
            }
        }
        return response
    }

    async UpdatePDPAAllowContact(value) {
        try {
            const data = {
                ba_id: storeAuth.GetId(),
                ba_status: storeAuth.userStatus,
                is_allow_contact_by_unicity_or_sponsor: value
            }

            await ReferralThailandAPIs.setPdpaStatus(data)
        } catch (e) {
            console.error(e)
        }
    }

    constructor() {
        StorePaymentStep.SetPaymentSteps(this.PaymentStep)
        makeAutoObservable(this)
    }
}

const storeReferralCheckout = new StoreCheckout()

export default storeReferralCheckout
