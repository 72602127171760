import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import HttpHelper from 'Services/HttpHelper'

const CreatePaymentData = (uuid, customerHref = undefined) => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/hydra-value/feelGreatReferral/${StoreCountry.Country3()}${customerHref ? '?updateCustomerInfo=1' : ''}`

    const data = {
        uuid: uuid,
        source_path: window.location.href,
        customerHref
    }

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const CreatePaymentLambdaData = (uuid, countryCode3alpha) => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/format/${StoreCountry.Country3()}/feelGreat/detail-hydra-v2/${uuid}`

    return axios.get(HttpHelper.Proxy({ originalUrl: url }), {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const CreateMemberAndOrder = hydraData => {
    const url = `${ServerEnv.Hydra()}/orders`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), hydraData, {
        headers: {
            'Content-Type': 'application/json',
            'X-APPLICATION': 'asia.ble.th'
        }
    })
}

const CreateOrder = (hydraData, token, Language, c) => {
    const url = `${ServerEnv.Hydra()}/customers/me/orders`
    return axios.post(HttpHelper.Proxy({ originalUrl: url }), hydraData, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'X-APPLICATION': 'asia.ble.th'
        }
    })
}

const CreateLambdaOrder = (lambdaData, token, Language, c) => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/proxy/insert/${StoreCountry.Country3()}`
    return axios.post(HttpHelper.Proxy({ originalUrl: url }), lambdaData, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'X-APPLICATION': 'asia.ble.th'
        }
    })
}

const CreateWorldPayOrder = (hydraData, token) => {
    const url = `${ServerEnv.Hydra()}/customers/me/orders`
    // test Zone
    // const url = `https://hydraqa.unicity.net/v5a-test/customers/me/orders`

    // hydraData['lines'] = {
    //     items: [
    //         {
    //             item: {
    //                 href: 'https://hydraqa.unicity.net/v5a-test/items?id.unicity=35571'
    //             },
    //             quantity: 1
    //         }
    //     ]
    // }
    // test Zone

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), hydraData, {
        headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer `
            Authorization: `Bearer ${token}`,
            'X-APPLICATION': 'asia.ble.th'
        }
    })
}

const CreateAutoship = (autoOrder, token) => {
    const url = `${ServerEnv.Hydra()}/customers/me/autoorders`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), autoOrder, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'X-APPLICATION': 'asia.ble.th'
        }
    })
}

const PostSms = dataSms => {
    const smsApi = `https://${ServerEnv.MemberCalls2()}.unicity.com/sms-api/sms`

    axios
        .post(HttpHelper.Proxy({ originalUrl: smsApi }), dataSms, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .catch(error => console.error(error))
}

const UpdateLog = data => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/hydra-log`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const PostEmail = dataEmail => {
    const emailApi = `https://${ServerEnv.MemberCalls2()}.unicity.com/email-api/message`

    axios
        .post(HttpHelper.Proxy({ originalUrl: emailApi }), dataEmail, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .catch(error => console.error(error))
}

const CustomerLogin = (username, password, timeout = 0) => {
    let data = {
        type: 'base64',
        value: btoa(`${username}:${password}`),
        namespace: `${ServerEnv.Hydra()}/customers`
    }

    const url = `${ServerEnv.Hydra()}/loginTokens`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json',
            'X-APPLICATION': 'asia.ble.th'
        }
    })
}

const GetPaymentDetail = (paymentId, baId) => {
    const data = {
        payment_id: paymentId,
        type: baId ? 'shop' : 'enroll',
        ba_id: baId || ''
    }
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/detail/hydra`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data)
}

const GetPaymentProxyDetail = gateway_ref_id => {
    const data = {
        gateway_ref_id: gateway_ref_id
    }
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/detail/proxy`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data)
}

const UpdateLogByOrder = data => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/hydra-log-by-order`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const GetOrderDetailByOrderNumber = orderNumber => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/order/data?order_number=${orderNumber}`

    return axios.get(HttpHelper.Proxy({ originalUrl: url }))
}

const RequestWorldPayDDC = bin => {
    const url = `${ServerEnv.Hydra()}/ddc`
    // const url = `https://hydraqa.unicity.net/v5a-test/ddc`
    return axios.post(
        HttpHelper.Proxy({ originalUrl: url }),
        {
            bin
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'X-APPLICATION': 'asia.ble.th'
            }
        }
    )
}

const GetHydraOrderDetail = async (orderNunber, token) => {
    const url = `${ServerEnv.Hydra()}/orders?id.unicity=${orderNunber}`
    // const url = `https://hydraqa.unicity.net/v5a-test/orders?id.unicity=${orderNunber}`
    return axios.get(HttpHelper.Proxy({ originalUrl: url }), {
        headers: {
            // Authorization: `Bearer `
            Authorization: `Bearer ${token}`
        }
    })
}

const UpdateLambdaAutoshipLog = data => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/proxy-log-autoship
    `

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const CreatePaymentDataForHostedGateway = data => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/proxy/insert/THA`

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const GetQRDetail = id => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/2c2p/qrPaymentStatus/${id}`

    return axios.get(HttpHelper.Proxy({ originalUrl: url }))
}

const CreateUuid = (data, countryCode3Alpha, skipValidateTaxInvoice = null) => {
    let url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/${countryCode3Alpha}/feelGreatReferral/address`
    if (skipValidateTaxInvoice) {
        url += '?skipValidateTaxInvoice=1'
    }
    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data)
}

const UpdateUUid = (data, countryCode3Alpha, isSkipValidateAddress) => {
    let url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/${countryCode3Alpha}/feelGreatReferral/address${isSkipValidateAddress ? '?isSkipValidate=1' : ''}`

    return axios.put(HttpHelper.Proxy({ originalUrl: url }), data)
}

const CreateAccountAndUuid = (data, countryCode3Alpha) => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/${countryCode3Alpha}/feelGreatReferral/createAccount?emailDelay=1h`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data)
}

const CreateAccountAndUpdateUuid = (data, countryCode3Alpha, debug) => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/${countryCode3Alpha}/feelGreatReferral/createAccount?t?emailDelay=1h&isSkipValidateAddress=1&isValaidateUUID=1${debug ? '&debug=1' : ''}`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data)
}

const UpdateReadyToCreateOrder = data => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/proxy/update/status`

    return axios.patch(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const CreateCustomer = (data) => {
    const url = `${ServerEnv.Hydra()}/customers`
    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json',
            'X-APPLICATION': 'asia.ble.th'
        }
    })
}

const CustomerInformationUpdate = ({
    token,
    customer_href,
    customer_information
}) => {
    let data = {
        token,
        customer_href,
        customer_information
    }
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/customer/information`
    return axios.patch(HttpHelper.Proxy({ originalUrl: url }), data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const PaymentAPIs = {
    CreatePaymentData,
    CreatePaymentLambdaData,
    CreateMemberAndOrder,
    CreateOrder,
    CreateLambdaOrder,
    PostSms,
    UpdateLog,
    PostEmail,
    CustomerLogin,
    GetPaymentDetail,
    GetPaymentProxyDetail,
    CreateAutoship,
    UpdateLogByOrder,
    GetOrderDetailByOrderNumber,
    CreateWorldPayOrder,
    RequestWorldPayDDC,
    GetHydraOrderDetail,
    UpdateLambdaAutoshipLog,
    CreatePaymentDataForHostedGateway,
    GetQRDetail,
    CreateUuid,
    CreateAccountAndUuid,
    CreateAccountAndUpdateUuid,
    UpdateReadyToCreateOrder,
    CustomerInformationUpdate,
    CreateCustomer,
    UpdateUUid
}
