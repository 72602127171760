import { useState } from 'react'
import Picker from './Picker'
import { observer } from 'mobx-react-lite'

const SelectWheel = observer(props => {
    const [value, setValue] = useState({
        title: props.value
    })

    const [optionGroups] = useState({
        title: props.optionSet || []
    })

    const onChange = (name, value) => {
        setValue({
            [name]: value
        })
        props.onSet(value)
    }

    return (
        <>
            {optionGroups.title.length > 0 && (
                <Picker optionGroups={optionGroups} valueGroups={value} onChange={onChange} itemHeight={36} propsValue={props.value} />
            )}
        </>
    )
})

export default SelectWheel
