import { get } from 'lodash'

function CountryFromPathname() {
    const pathnames = window.location.pathname.split('/')
    let country = get(pathnames, 1, 'australia')
    if (/^\/feature/.test(window.location.pathname)) {
        country = get(pathnames, 4, 'australia')
    } else if (/^\/epic/.test(window.location.pathname)) {
        country = get(pathnames, 6, 'australia')
    }

    return country
}

function BuildBasename(country) {
    const pathnames = window.location.pathname.split('/')

    if (/^\/feature/.test(window.location.pathname)) {
        pathnames[4] = country
    } else if (/^\/epic/.test(window.location.pathname)) {
        pathnames[6] = country
    } else {
        pathnames[1] = country
    }

    const basename = pathnames.join('/')
    let arrayBasename = basename.split('/')
    if (/^\/feature/.test(window.location.pathname)) {
        arrayBasename = pathnames.slice(0, 5)
    } else if (/^\/epic/.test(window.location.pathname)) {
        arrayBasename = pathnames.slice(0, 7)
    } else {
        arrayBasename = pathnames.slice(0, 2)
    }

    return arrayBasename.join('/')
}

function Segments() {
    return window.location.pathname.split('/')
}

function Segment(segmentIndex) {
    const pathnames = window.location.pathname.split('/')
    if (IsEpic()) {
        return pathnames[segmentIndex + 5]
    } else if (IsFeature()) {
        return pathnames[segmentIndex + 3]
    } else {
        return pathnames[segmentIndex]
    }
}

function IsEpic() {
    return /^\/epic/.test(window.location.pathname)
}

function IsFeature() {
    return /^\/feature/.test(window.location.pathname)
}

export const PathHelper = {
    CountryFromPathname,
    BuildBasename,
    Segments,
    Segment,
    IsEpic,
    IsFeature
}
