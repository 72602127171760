import storeUser from "GlobalStores/User/StoreUser"
import httpHelper from "Services/HttpHelper"
import axios from "axios"
import { get } from "lodash"


export const editReferralCode = (token, customWordReferral) => {
    const customerData = get(storeUser, 'userData.href', '')
    const url = `${httpHelper.Proxy({ 
        originalUrl: `${customerData}/referralcodes`
    })}`

    const data = {
        referralCode: customWordReferral
    }

    return axios.post(url, data, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}