import React from 'react'
import { observer } from 'mobx-react-lite'
import { ReactComponent as VisaIcon } from './Assets/visa.svg'
import { ReactComponent as MasterCardIcon } from './Assets/masterCard.svg'

const LogoPayment = observer(props => {
    return (
        <>
            {props.type === 'visa' && <VisaIcon />}
            {props.type === 'mastercard' && <MasterCardIcon />}
        </>
    )
})
export default LogoPayment
