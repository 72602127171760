import { Box, Stack, ThemeProvider, Typography, styled } from '@mui/material'
import { default as Success } from './Assets/success.svg'
import { storeElement } from 'GlobalStores/StoreElement'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { runInAction } from 'mobx'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'
import SessionAuth from 'GlobalStores/User/SessionAuth'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { PathHelper } from 'GlobalStores/Cores/Helpers/PathHelper'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'

const DeleteAccountSuccess = observer(() => {

    const handleLogOut = async () => {
        await FeelGreatAPI.deleteCache(StoreAuth.GetId())

        setTimeout(() => {
            logoutProcess()
        }, 3000)
    }

    const logoutProcess = async () => {
        StoreAuth.logout()
        SessionAuth.RemoveSession(true)
        localStorage.setItem('from-logout', '1')
        let pathRedirect = ''
        if (/localhost/.test(window.location.hostname)) {
            pathRedirect = `//${window.location.hostname}:3000${PathHelper.BuildBasename(
                StoreCountry.CountryLowerCase()
            )}?lang=${StoreTranslate.CurrentLanguage()}&logout`
        } else {
            pathRedirect = `//${window.location.hostname}${PathHelper.BuildBasename(
                StoreCountry.CountryLowerCase()
            )}?lang=${StoreTranslate.CurrentLanguage()}&logout`
        }
        window.location.href = pathRedirect
    }

    useEffect(() => {
        runInAction(() => {
            storeSettingsElement.cardTitle.titleStep1 = <T>settings</T>
        })
        handleLogOut()
        return () => {
            runInAction(() => {
                storeSettingsElement.cardTitle.titleStep1 = ''
            })
        }
    }, [])

    return (
        <ThemeProvider theme={storeElement.theme}>

            <CustomBox>
                <Stack pt="30px" pb="40px" alignItems="center">
                    <img src={Success} alt="success" width="72px" height={"72px"} />
                </Stack>
                <Stack alignItems="center" pb="15px">
                    <Typography variant="h3" sx={{ color: "#003B6F", fontSize: "18px", fontWeight: "700" }}>
                        <T>delete_success_message_1</T>
                    </Typography>
                </Stack>
                <Stack alignItems="center">
                    <Typography variant="caption" sx={{ color: "#003B6F", fontSize: "16px", fontWeight: "400" }}>
                        <T>delete_success_message_2</T>
                    </Typography>
                </Stack>
            </CustomBox>

        </ThemeProvider>
    )
})


export default DeleteAccountSuccess

const CustomBox = styled(Box)`

    padding-right: 32px;
    padding-left: 32px;
    min-height: calc(80vh - 380px);
    max-height: 450px;

    @media screen and (min-width: 768px) {
        padding-right: 0px;
        padding-left: 0px;

    }
    text-align: center;

    
`